import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FaKeyboard } from "react-icons/fa";

class CardDataEntry extends Component {

    render() {

        return (

            <div class="card border-white">
              <div class="card-body">
                <h6 class="card-title">
                    <FaKeyboard />&nbsp;Manufacturing Index Data Entry
                </h6>
                <div class="border-top my-2"></div>
                <p class="card-text">
                    Enter the manufacturing index data to multiple factories 
                    at once or enter the data to factories 
                    individually via the web user interface.
                </p>
                <button class="btn  btn-outline-primary btn-sm" role="button">
                  <Link to={this.props.link} style={{ textDecoration: "none"}}>{this.props.action}</Link>
                </button>
                <br />
              </div>
              
            </div>
            
        );
    };
};

export default CardDataEntry;