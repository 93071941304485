import React, { Component } from "react";
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css"
import Config from "../Config";
import { TiInfoOutline } from "react-icons/ti";

class FactoryList extends Component {

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      factoryList: [],
      selectedFactories: [],
      status: "Loading"
    };
  }

  getFactoryList(){
    fetch(Config.FactoryListUrl + "?section=" + this.props.section).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      this.setState({ factoryList: data });
      this.setState({ status: "Success" });
    })
    .catch((error) => {
      this.setState( {status: "Failure"} )
  })};

  componentDidMount() {
    this.getFactoryList();
  };

  onChange(selectedFactories) {
    this.setState({ selectedFactories });
    this.props.sendSelectedFactories(selectedFactories);
  }
  render() {
    const { factoryList, selectedFactories } = this.state;
    return (
      <div>

        {
              this.state.status === "Loading" ?
                  <div class="alert alert-info" role="alert">
                      Loading...
                  </div>
              : this.state.status === "Failure" ?
                  <div class="alert alert-danger" role="alert">
                      Something went wrong while loading the page! Please try refreshing the page.
                  </div>
              : null
        }

                {
                  this.props.section !== "ExcludeFactory" && this.props.section !== "IncludeFactory" ?
                  <small class="text-muted">
                      <TiInfoOutline />&nbsp;Manufacturing Index data for an already entered 
                      factory is disabled in the below selection list. Go to the Data Review 
                      section to edit the Manufacturing Index data for an already entered factory.
                  </small>
                  : <span>&nbsp;</span>
                }

                <MultiSelect
                  items={factoryList}
                  selectedItems={selectedFactories}
                  onChange={this.onChange} />

      </div>
      
    );
  }
}

export default FactoryList;