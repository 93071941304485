import React, { Component } from "react";
import Config from "../Config";
import Pagination from "react-js-pagination";
import { GoSearch } from "react-icons/go";
import { TiInfoOutline } from "react-icons/ti";
import ModalCostingForm from "../components/ModalCostingForm";
import ModalDeliveryForm from "../components/ModalDeliveryForm";
import ModalQualityForm from "../components/ModalQualityForm";
import ModalSustainabilityForm from "../components/ModalSustainabilityForm";

class DataReviewList extends Component {

  state = {
    dataReviewList: [],
    selectedFactoryId: "",
    selectedFactoryCRCode: "",
    searchFor: "",
    activePage: 1,
    totalPages: 0,
    totalItemsCount: 0,
    itemsCountPerPage: 20,
    status : "Loading"
  };

  getDataReviewList(){
    fetch(Config.DataReviewListUrl + "?section=" + this.props.section + "&searchFor=" + this.state.searchFor + "&page=" + this.state.activePage).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      if(data.length === 0) {
        this.setState({ status: "No Records" });
      } else {
        this.setState({ dataReviewList: data }, () => this.configPagination(data[0].TotalItemsCount));
        this.setState({ status: "Success" });
      }
    })
    .catch((error) => {
      this.setState( {status: "Failure"} )
  })};

  configPagination(totalItemsCount){
    this.setState({ totalItemsCount: totalItemsCount });
    this.setState({ totalPages: Math.ceil(totalItemsCount / this.state.itemsCountPerPage)});
  }

  componentDidMount() {
    this.getDataReviewList();
  };

  onSearchForChange = (evt) => {
    this.setState({ searchFor: evt.target.value });
  };

  onSearch = () => {
    this.onPageChange(1);
  };

  onPageChange(pageNumber) {
    this.setState({activePage: pageNumber}, () => this.getDataReviewList());
  }

  onCRCodeClick = (evt) => {
    this.setState({selectedFactoryId: evt.target.id});
    this.setState({selectedFactoryCRCode: evt.target.getAttribute("href").replace("#","-")});
  };

  render() {

    return (

      <div>
        
          {
              this.state.status === "Loading" ?
                  <div class="alert alert-info" role="alert">
                      Loading...
                  </div>
              : this.state.status === "Failure" ?
                  <div class="alert alert-danger" role="alert">
                      Something went wrong while loading the page! Please try refreshing the page.
                  </div>
              : this.state.status === "Success" ?

              <div>

              <div class="row justify-content-sm-center">

                <div class="col col-sm-4 text-left">
                    <button class="btn btn-light" type="button" data-toggle="collapse" data-target="#collapseSearch" aria-expanded="false" aria-controls="collapseSearch">
                        <GoSearch />
                    </button>
                    <div class="collapse multi-collapse" id="collapseSearch">
                        <div class="card card-body bg-light border-white">
                            <form class="form-inline">
                                <label class="sr-only" for="searchFor">Factory</label>
                                <input type="text" class="form-control mb-2 mr-sm-2" id="searchFor" value={this.state.searchFor} onChange={this.onSearchForChange} placeholder="Wildcard search" />
                                <button type="button" class="btn btn-primary mb-2" onClick={this.onSearch}>Search</button>
                            </form>
                            <small class="text-muted"><TiInfoOutline />&nbsp;Enter first few characters of group name or CR or PO code.</small>
                        </div>
                    </div>
                </div>

                <div class="col-sm-auto">
                    <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemsCountPerPage}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={this.onPageChange.bind(this)}
                        itemClass="page-item"
                        linkClass="page-link"
                    />
                </div>

                <div class="col col-sm-4 text-right">
                    &nbsp;
                </div>

              </div>

              <div class="row text-muted">
                  <div class="col-sm">
                      <small>
                          <TiInfoOutline />&nbsp;Click CR Code to view and edit the manufacturing index data.
                      </small>
                  </div>
                  <div class="col-sm text-right">
                      <small>
                          Page {this.state.activePage} of {this.state.totalPages}
                      </small>
                  </div>
              </div>

            <table class="table table-striped table-sm">
                <thead>
                    <tr>
                        <th scope="col">Group Name</th>
                        <th scope="col">CR Code</th>
                        <th scope="col">PO Code</th>
                        <th scope="col">Brand</th>
                        <th scope="col">Product Engine</th>
                        <th scope="col">Country</th>
                        <th scope="col">MI Score</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.dataReviewList.map(item => (
                            <tr key={item.Id}>
                                <td style={{width: "20%"}}>{item.FactoryGroupName}</td>
                                <td style={{width: "13%"}}>
                                  <a href={"#" + item.FactoryCRCode} id={item.Id} onClick={this.onCRCodeClick} style={{ textDecoration: "none"}} data-toggle="modal" data-target={"#" + this.props.section}>
                                    {item.FactoryCRCode}
                                  </a>
                                </td>
                                <td style={{width: "10%"}}>{item.FactoryPOCode}</td>
                                <td style={{width: "10%"}}>{item.Brand}</td>
                                <td style={{width: "15%"}}>{item.ProductEngine}</td>
                                <td style={{width: "22%"}}>{item.Country}</td>
                                <td style={{width: "10%"}}>{item.MIScore}</td>
                            </tr>
                    ))}
                </tbody>
            </table>

          </div>
          : 
          <div class="alert alert-info" role="alert">
            No data to review at this time. Please come back later.
          </div>

        }

      <ModalCostingForm selectedFactories = {[{id: this.state.selectedFactoryId, label: this.state.selectedFactoryCRCode}]} action = "Data Review" />
      <ModalDeliveryForm selectedFactories = {[{id: this.state.selectedFactoryId, label: this.state.selectedFactoryCRCode}]} action = "Data Review" />
      <ModalQualityForm selectedFactories = {[{id: this.state.selectedFactoryId, label: this.state.selectedFactoryCRCode}]} action = "Data Review" />
      <ModalSustainabilityForm selectedFactories = {[{id: this.state.selectedFactoryId, label: this.state.selectedFactoryCRCode}]} action = "Data Review" />

      </div>
      
    );
  }
}

export default DataReviewList;