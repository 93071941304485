import React, { Component } from "react";
import { AiOutlineBars } from "react-icons/ai";
import Config from "../Config";

class CardFactoryStatus extends Component {

    state = {
        factoryStatusList : [],
        status: "Loading",
      };

    getFactoryStatusList(){
        fetch(Config.FactoryStatusListUrl).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ factoryStatusList: data });
          this.setState({ status: "Success" });
        })
        .catch((error) => {
          this.setState( {status: "Failure"} )
      })};

      componentDidMount() {
        this.getFactoryStatusList();
      };

    render() {

        return (

            <div class="card border-white">
              <div class="card-body">
                <h6 class="card-title">
                  <AiOutlineBars />&nbsp;Factory Status
                </h6>
                <div class="border-top my-2"></div>
                <ul class="list-group factoryStatusList-group-flush">  
                {
                    this.state.status === "Loading" ?
                        <div class="alert alert-info" role="alert">
                            Loading...
                        </div>
                    : this.state.status === "Success" ?
                        this.state.factoryStatusList.map(item => (
                        <li class="list-group-item border-white d-flex justify-content-between align-items-center" key={item.Order}>
                            {item.Key}
                            {
                              item.Key === "Included" ?
                              <span class="badge" style={{backgroundColor: "#009452", color: "#FFFFFF"}}><h6><small>&nbsp;{item.Value}&nbsp;</small></h6></span>
                              :<span class="badge" style={{backgroundColor: "#CC0001", color: "#FFFFFF"}}><h6><small>&nbsp;{item.Value}&nbsp;</small></h6></span>
                            }
                        </li>
                        ))
                    : this.state.status === "Failure" ?
                        <div class="alert alert-danger" role="alert">
                            Something went wrong while loading the factory status! Please try refreshing the page.
                        </div>
                    : null

                }

                </ul>
              </div>
            </div>
            
        );
    };
};

export default CardFactoryStatus;