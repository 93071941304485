import React, { Component } from "react";
import { IoIosSettings } from "react-icons/io";
import { GoRepoPull } from "react-icons/go";
import { GoRepoPush } from "react-icons/go";
import { FiLock } from "react-icons/fi";
import { FiUnlock } from "react-icons/fi";
import { GoHistory } from "react-icons/go";
import { AiOutlineFileSearch } from "react-icons/ai";
import { MdBlock } from "react-icons/md";
import { AiOutlineFileAdd } from "react-icons/ai";
import { FaRegFileImage } from "react-icons/fa";
import ModalReportingPeriod from "./ModalReportingPeriod";
import ModalConfirm from "./ModalConfirm";
import ModalExport from "./ModalExport";
import { Link } from "react-router-dom";
import ModalExclude from "./ModalExclude";
import ModalInclude from "./ModalInclude";


class Card extends Component {

    render() {

        return (

            <div class="card border-white">
              <div class="card-body">
                <h6 class="card-title">
                  {
                    {
                      "Set Active Reporting Period": <IoIosSettings />,
                      "Import Factory Data": <GoRepoPull />,
                      "Exclude Factory": <MdBlock />,
                      "Include Factory": <AiOutlineFileAdd />,
                      "Import Delivery Data": <GoRepoPull />,
                      "Import Sustainability Data": <GoRepoPull />,
                      "Review Manufacturing Index Data": <AiOutlineFileSearch />,
                      "Export Manufacturing Index Data": <GoRepoPush />,
                      "View Historical Manufacturing Index Data": <GoHistory />,
                      "Report": <FaRegFileImage />,
                      "Lock Reporting Period": <FiLock />,
                      "Unlock Reporting Period": <FiUnlock />
                    }[this.props.title]
                  }
                &nbsp;{this.props.title}
                </h6>
                <div class="border-top my-2"></div>
                <p class="card-text">{this.props.body}</p>
                {
                    {
                      "Set Active Reporting Period": 
                      <button class="btn  btn-outline-primary btn-sm" role="button" data-toggle="modal" data-target="#reportingperiod">
                        {this.props.action}
                      </button>,
                      "Import Factory Data": 
                      <button class="btn  btn-outline-primary btn-sm" role="button" data-toggle="modal" data-target="#factory">
                        {this.props.action}
                      </button>,
                      "Exclude Factory": 
                      <button class="btn  btn-outline-primary btn-sm" role="button" data-toggle="modal" data-target="#exclude">
                        {this.props.action}
                      </button>,
                      "Include Factory": 
                      <button class="btn  btn-outline-primary btn-sm" role="button" data-toggle="modal" data-target="#include">
                        {this.props.action}
                      </button>,
                      "Import Delivery Data": 
                      <button class="btn  btn-outline-primary btn-sm" role="button" data-toggle="modal" data-target="#delivery">
                        {this.props.action}
                      </button>,
                      "Import Sustainability Data": 
                      <button class="btn  btn-outline-primary btn-sm" role="button" data-toggle="modal" data-target="#sustainability">
                        {this.props.action}
                      </button>,
                      "Review Manufacturing Index Data": 
                      <button class="btn  btn-outline-primary btn-sm" role="button">
                         <Link to={this.props.link} style={{ textDecoration: "none"}}>{this.props.action}</Link>
                      </button>,
                      "Export Manufacturing Index Data": 
                      <button class="btn  btn-outline-primary btn-sm" role="button" data-toggle="modal" data-target="#export">
                        {this.props.action}
                      </button>,
                       "View Historical Manufacturing Index Data":                 
                       <button class="btn  btn-outline-primary btn-sm" role="button">
                          <Link to={this.props.link} style={{ textDecoration: "none"}}>{this.props.action}</Link>
                       </button>,
                      "Report":                 
                      <button class="btn  btn-outline-primary btn-sm" role="button">
                         <Link to={this.props.link} style={{ textDecoration: "none"}}>{this.props.action}</Link>
                      </button>,
                      "Lock Reporting Period": 
                      <button class="btn  btn-outline-primary btn-sm" role="button" data-toggle="modal" data-target="#lock">
                        {this.props.action}
                      </button>,
                       "Unlock Reporting Period": 
                       <button class="btn  btn-outline-primary btn-sm" role="button" data-toggle="modal" data-target="#unlock">
                         {this.props.action}
                       </button>
                    }[this.props.title]
                }
               
                <br />
                <small class="text-muted">
                  {this.props.date}
                </small>
              </div>
              {
                    {
                      "Set Active Reporting Period": <ModalReportingPeriod />,
                      "Import Factory Data": <ModalConfirm modalId="factory" action="importing factory data" message="Are you sure you want to import the factory data?" />,
                      "Exclude Factory": <ModalExclude />,
                      "Include Factory": <ModalInclude />,
                      "Import Delivery Data": <ModalConfirm modalId="delivery" action="importing delivery data" message="Are you sure you want to import the delivery data?" />,
                      "Import Sustainability Data": <ModalConfirm modalId="sustainability" action="importing sustainability data" message="Are you sure you want to import the sustainability data?" />,
                      "Export Manufacturing Index Data": <ModalExport />,
                      "Lock Reporting Period": <ModalConfirm modalId="lock" action="locking reporting period" message="Are you sure you want to lock the reporting period?" />,
                      "Unlock Reporting Period": <ModalConfirm modalId="unlock" action="unlocking reporting period" message="Are you sure you want to unlock the reporting period?" />
                    }[this.props.title]
                }
              
            </div>
            
        );
    };
};

export default Card;