import React, { Component } from "react";
import Config from "../Config";
import FactoryList from "../components/FactoryList";
import { AiOutlineFileAdd } from "react-icons/ai";

class ModalInclude extends Component {

    state = {
        selectedFactories: [],
        includeFactories: [],
        status: null
    };
 
    setSelectedFactories = (val) => {
        this.setState({ selectedFactories: val });
    }

    includeFactory(){
        fetch(Config.IncludeFactoryUrl,  {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "factories": this.state.includeFactories })
          }).then((response) => {
          if (response.ok) {
            return response.json();
          } 
          else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ status: "Success" });
          window.location.reload(false);
        })
        .catch((error) => {
          this.setState( {status: "Failure"} )
      })};

    onInclude = () => {
    
        this.setState({ includeFactories: [] });
        this.state.selectedFactories.forEach((factory) => {
            this.state.includeFactories.push(
                {
                    "Id": factory.id
                }
            ) 
        });

        this.setState({ status: null });
        if(this.state.includeFactories !== null) {
            this.setState( {status: "Including"});
            this.includeFactory();
        };

      };

    

    render() {

        return (

        <div class="modal fade" id="include" tabindex="-1" role="dialog" aria-labelledby="includeModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered  modal-dialog-scrollable modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                       
                            <h6 class="modal-title" id="includeModalTitle"><AiOutlineFileAdd />&nbsp;Include Factory</h6>
                            
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                     
                        <FactoryList 
                            sendSelectedFactories = {this.setSelectedFactories} 
                            section = "IncludeFactory" />
                        
                        <br />

                            {
                                this.state.status === "Including" ?

                                <div class="alert alert-info" role="alert">
                                    Including factories...please wait
                                </div>

                                : this.state.status === "Success" ?

                                <div class="alert alert-success" role="alert">
                                    Completed including factories.
                                </div>

                                : this.state.status === "Failure" ?
                                
                                <div class="alert alert-danger" role="alert">
                                    Something went wrong while including the factory! Please try again later.
                                </div>

                                : null
                            }

                    </div>
                    <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
                        {
                            this.state.selectedFactories.length === 0 ?
                            <button class="btn  btn-primary btn-sm" role="button"  disabled>
                                Include
                            </button>
                            :
                            <button class="btn  btn-primary btn-sm" role="button" onClick={this.onInclude}>
                                Include
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>

        );
    };
};

export default ModalInclude;