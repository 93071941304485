import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Header from "./Header";
import DeliveryHome from "./DeliveryHome";
import DeliveryDataEntry from "./DeliveryDataEntry";
import DeliveryDataReview from "./DeliveryDataReview";


const DeliveryRouter = (props) => {
  return (
      <div>
        <Header user={props.user} />
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={DeliveryHome} />
            <Route path="/delivery" component={DeliveryHome} />
            <Route path="/deliverydataentry" component={DeliveryDataEntry} />
            <Route path="/deliverydatareview" component={DeliveryDataReview} />
          </Switch>
        </BrowserRouter>
      </div>
  );
};

export default DeliveryRouter;
