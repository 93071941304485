import React, { Component } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { SecureRoute, Security, LoginCallback } from "@okta/okta-react";
import Login from "./Login";
import Config from "./Config";

class AuthService extends Component {
  render() {
    return (
      <Router>
        <Security issuer={Config.OktaAuthorizeUrl}
                  clientId={Config.ClientId}
                  pkce={true}
                  redirectUri={window.location.origin + "/implicit/callback"} >
            {/* 
              Use switch to  stop looking for additional 
              path matches after the first matching route found
            */}
            <Switch>
              <Route path="/implicit/callback" component={LoginCallback} />
              <SecureRoute path="/" component={Login} />
            </Switch>
        </Security>
      </Router>
    );
  }
}

export default AuthService;