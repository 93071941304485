import React, { Component } from "react";
 
class Unauthorized extends Component {
 
    render() {
        return (
        
            <div class="container-fluid">
                
                <br />

                <div class="alert alert-danger" role="alert">
                    You are not authorized to access Manufacturing Index site.
                    If you need access please contact technical support at&nbsp;
                    <a class="text-decoration-none" href="mailto:Lst-SM%26S.SMSOnline.Support@nike.com?subject= Manufacturing Index Web Site Access">
                        Lst-SM&amp;S.SMSOnline.Support@nike.com
                    </a>
                </div>

            </div>
        );
    }
}

export default Unauthorized;