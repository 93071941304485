
const Dev = {
    ClientId: "nike.gsm.mi",
    OktaAuthorizeUrl: "https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7",
    OktaTokenUrl: "https://api.aegis.nikecloud.com/v1/qa/token",
    AuditListUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/auditlist",
    ContactListUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/contactlist",
    CostingReadUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/costingread",
    CostingSaveUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/costingsave", 
    CostingTemplateUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/costingtemplate",
    DataReviewListUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/datareviewlist",
    DataReviewStatusUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/datareviewstatus",
    DeliveryReadUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/deliveryread",
    DeliverySaveUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/deliverysave",
    DeliveryTemplateUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/deliverytemplate",
    ExcludeFactoryUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/excludefactory",
    ExportUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/export",
    FactoryListUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/factorylist",
    FactoryStatisticsListUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/factorystatisticslist",
    FactoryStatusListUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/factorystatuslist",
    HistoricalDataListUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/historicaldatalist",
    ImportDeliveryDataUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/importdeliverydata",
    ImportFactoryDataUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/importfactorydata",
    ImportSustainabilityDataUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/importsustainabilitydata",
    IncludeFactoryUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/includefactory",
    LockReportingPeriodUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/lockreportingperiod",
    QualityReadUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/qualityread",
    QualitySaveUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/qualitysave",
    QualityTemplateUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/qualitytemplate",
    ReportingPeriodListUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/reportingperiodlist",
    ReportingStatusListUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/reportingstatuslist",
    SetActiveReportingPeriodUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/setactivereportingperiod",
    SustainabilityReadUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/sustainabilityread",
    SustainabilitySaveUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/sustainabilitysave",
    SustainabilityTemplateUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/sustainabilitytemplate",
    TemplateUploadUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/templateupload",
    UnlockReportingPeriodUrl : "https://1uylw8s9xd.execute-api.us-west-2.amazonaws.com/dev/unlockreportingperiod"
    
};


const Prod = {
    ClientId: "nike.gsm.mi",
    OktaAuthorizeUrl: "https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7",
    OktaTokenUrl: "https://api.aegis.nikecloud.com/v1/prod/token",
    AuditListUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/auditlist",
    ContactListUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/contactlist",
    CostingReadUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/costingread",
    CostingSaveUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/costingsave", 
    CostingTemplateUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/costingtemplate",
    DataReviewListUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/datareviewlist",
    DataReviewStatusUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/datareviewstatus",
    DeliveryReadUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/deliveryread",
    DeliverySaveUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/deliverysave",
    DeliveryTemplateUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/deliverytemplate",
    ExcludeFactoryUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/excludefactory",
    ExportUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/export",
    FactoryListUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/factorylist",
    FactoryStatisticsListUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/factorystatisticslist",
    FactoryStatusListUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/factorystatuslist",
    HistoricalDataListUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/historicaldatalist",
    ImportDeliveryDataUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/importdeliverydata",
    ImportFactoryDataUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/importfactorydata",
    ImportSustainabilityDataUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/importsustainabilitydata",
    IncludeFactoryUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/includefactory",
    LockReportingPeriodUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/lockreportingperiod",
    QualityReadUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/qualityread",
    QualitySaveUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/qualitysave",
    QualityTemplateUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/qualitytemplate",
    ReportingPeriodListUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/reportingperiodlist",
    ReportingStatusListUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/reportingstatuslist",
    SetActiveReportingPeriodUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/setactivereportingperiod",
    SustainabilityReadUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/sustainabilityread",
    SustainabilitySaveUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/sustainabilitysave",
    SustainabilityTemplateUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/sustainabilitytemplate",
    TemplateUploadUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/templateupload",
    UnlockReportingPeriodUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/unlockreportingperiod"
    
};

//Change this configuration based on the environment the site is deployed.
//The configuration values are Dev and Prod
const Config = Prod;

module.exports = Config;
