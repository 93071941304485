import React, { Component } from "react";
import Config from "../Config";
import { TiInfoOutline } from "react-icons/ti";

class ModalQualityForm extends Component {

    state = {
        qualityList : [],
        postStatus : null,
        getStatus : null,
        MiQualityScore : "",
        ProductSafetyYes : "",
        ProductSafetyReason : "",
        DefectiveRateRawScore : "",
        DefectiveRatePts : "",
        MiQualityScoreAdjust : "",
        FootwearCopqRawScore : "",
        FootwearCopqPts : "",
        FootwearBondTestRawScore : "",
        FootwearBondTestPts : "",
        FootwearConverseFlexTestRawScore : "",
        FootwearConverseFlexTestPts : "",
        FootwearConverseMqaaRawScore : "",
        FootwearConverseMqaaPts : "",
        ApparelIssuesNbrRawScore : "",
        ApparelIssuesNbrPts : "",
        ApparelIssuesValueRawScore : "",
        ApparelIssuesValuePts : "",
        EquipmentIssuesNbrRawScore : "",
        EquipmentIssuesNbrPts : "",
        EquipmentIssuesValueRawScore : "",
        EquipmentIssuesValuePts : "",
        QualityNonCompliantReason : "",
        MiQualityScoreValid : true,
        DefectiveRateRawScoreValid : true,
        DefectiveRatePtsValid : true,
        MiQualityScoreAdjustValid : true,
        FootwearCopqRawScoreValid : true,
        FootwearCopqPtsValid : true,
        FootwearBondTestRawScoreValid : true,
        FootwearBondTestPtsValid : true,
        FootwearConverseFlexTestRawScoreValid : true,
        FootwearConverseFlexTestPtsValid : true,
        FootwearConverseMqaaRawScoreValid : true,
        FootwearConverseMqaaPtsValid : true,
        ApparelIssuesNbrRawScoreValid : true,
        ApparelIssuesNbrPtsValid : true,
        ApparelIssuesValueRawScoreValid : true,
        ApparelIssuesValuePtsValid : true,
        EquipmentIssuesNbrRawScoreValid : true,
        EquipmentIssuesNbrPtsValid : true,
        EquipmentIssuesValueRawScoreValid : true,
        EquipmentIssuesValuePtsValid : true
    };

    readQuality(){
        fetch(Config.QualityReadUrl + "?factoryid=" + this.props.selectedFactories[0].id).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.bind(data);
          this.setState({ Status: "Success" });
        })
        .catch((error) => {
          this.setState( {Status: "Failure"} )
    })};

    bind(data) {
      
        this.setState({ MiQualityScore: data[0].MiQualityScore});
        this.setState({ ProductSafetyYes: data[0].ProductSafetyYes});
        this.setState({ ProductSafetyReason: data[0].ProductSafetyReason});
        this.setState({ DefectiveRateRawScore: data[0].DefectiveRateRawScore});
        this.setState({ DefectiveRatePts: data[0].DefectiveRatePts});
        this.setState({ MiQualityScoreAdjust: data[0].MiQualityScoreAdjust});
        this.setState({ FootwearCopqRawScore: data[0].FootwearCopqRawScore});
        this.setState({ FootwearCopqPts: data[0].FootwearCopqPts});
        this.setState({ FootwearBondTestRawScore: data[0].FootwearBondTestRawScore});
        this.setState({ FootwearBondTestPts: data[0].FootwearBondTestPts});
        this.setState({ FootwearConverseFlexTestRawScore: data[0].FootwearConverseFlexTestRawScore});
        this.setState({ FootwearConverseFlexTestPts: data[0].FootwearConverseFlexTestPts});
        this.setState({ FootwearConverseMqaaRawScore: data[0].FootwearConverseMqaaRawScore});
        this.setState({ FootwearConverseMqaaPts: data[0].FootwearConverseMqaaPts});
        this.setState({ ApparelIssuesNbrRawScore: data[0].ApparelIssuesNbrRawScore});
        this.setState({ ApparelIssuesNbrPts: data[0].ApparelIssuesNbrPts});
        this.setState({ ApparelIssuesValueRawScore: data[0].ApparelIssuesValueRawScore});
        this.setState({ ApparelIssuesValuePts: data[0].ApparelIssuesValuePts});
        this.setState({ EquipmentIssuesNbrRawScore: data[0].EquipmentIssuesNbrRawScore});
        this.setState({ EquipmentIssuesNbrPts: data[0].EquipmentIssuesNbrPts});
        this.setState({ EquipmentIssuesValueRawScore: data[0].EquipmentIssuesValueRawScore});
        this.setState({ EquipmentIssuesValuePts: data[0].EquipmentIssuesValuePts});
        this.setState({ QualityNonCompliantReason: data[0].QualityNonCompliantReason});

    }

    unbind() {
        this.setState({ MiQualityScore: ""});
        this.setState({ ProductSafetyYes: ""});
        this.setState({ ProductSafetyReason: ""});
        this.setState({ DefectiveRateRawScore: ""});
        this.setState({ DefectiveRatePts: ""});
        this.setState({ MiQualityScoreAdjust: ""});
        this.setState({ FootwearCopqRawScore: ""});
        this.setState({ FootwearCopqPts: ""});
        this.setState({ FootwearBondTestRawScore: ""});
        this.setState({ FootwearBondTestPts: ""});
        this.setState({ FootwearConverseFlexTestRawScore: ""});
        this.setState({ FootwearConverseFlexTestPts: ""});
        this.setState({ FootwearConverseMqaaRawScore: ""});
        this.setState({ FootwearConverseMqaaPts: ""});
        this.setState({ ApparelIssuesNbrRawScore: ""});
        this.setState({ ApparelIssuesNbrPts: ""});
        this.setState({ ApparelIssuesValueRawScore: ""});
        this.setState({ ApparelIssuesValuePts: ""});
        this.setState({ EquipmentIssuesNbrRawScore: ""});
        this.setState({ EquipmentIssuesNbrPts: ""});
        this.setState({ EquipmentIssuesValueRawScore: ""});
        this.setState({ EquipmentIssuesValuePts: ""});
        this.setState({ QualityNonCompliantReason: ""});        
    }

    componentDidUpdate(prevProps) {
        if(this.props.action === "Data Review") {
            if (this.props.selectedFactories !== prevProps.selectedFactories) {
                this.unbind();
                this.readQuality();
            }
        }
    };

    setValue = (field, event) => {
        var object = {};
        object[field] = event.target.value;

        if(field === "ProductSafetyReason" ||
            field === "ProductSafetyYes" ||
            field === "QualityNonCompliantReason") {
                this.setState(object);
        }
        else {
            this.setState(object, this.validateField(field, event.target.value));
        }
    }

    validateField = (field, value) => {
        if (value.match(/^\d*((\d\.)|(\.\d))?\d{0,2}$/) === null) {
          switch(field) {
            case "MiQualityScore":
                this.setState({ MiQualityScoreValid: false } );
               break;
               case "DefectiveRateRawScore":
                this.setState({ DefectiveRateRawScoreValid: false } );
               break;
               case "DefectiveRatePts":
                this.setState({ DefectiveRatePtsValid: false } );
               break;
               case "MiQualityScoreAdjust":
                this.setState({ MiQualityScoreAdjustValid: false } );
               break;
               case "FootwearCopqRawScore":
                this.setState({ FootwearCopqRawScoreValid: false } );
               break;
               case "FootwearCopqPts":
                this.setState({ FootwearCopqPtsValid: false } );
               break;
               case "FootwearBondTestRawScore":
                this.setState({ FootwearBondTestRawScoreValid: false } );
               break;
               case "FootwearConverseFlexTestRawScore":
                this.setState({ FootwearConverseFlexTestRawScoreValid: false } );
               break;
               case "FootwearConverseFlexTestPts":
                this.setState({ FootwearConverseFlexTestPtsValid: false } );
               break;
               case "FootwearConverseMqaaRawScore":
                this.setState({ FootwearConverseMqaaRawScoreValid: false } );
               break;
               case "FootwearConverseMqaaPts":
                this.setState({ FootwearConverseMqaaPtsValid: false } );
               break;
               case "ApparelIssuesNbrRawScore":
                this.setState({ ApparelIssuesNbrRawScoreValid: false } );
               break;
               case "ApparelIssuesNbrPts":
                this.setState({ ApparelIssuesNbrPtsValid: false } );
               break;
               case "ApparelIssuesValueRawScore":
                this.setState({ ApparelIssuesValueRawScoreValid: false } );
               break;
               case "ApparelIssuesValuePts":
                this.setState({ ApparelIssuesValuePtsValid: false } );
               break;
               case "EquipmentIssuesNbrRawScore":
                this.setState({ EquipmentIssuesNbrRawScoreValid: false } );
               break;
               case "EquipmentIssuesNbrPts":
                this.setState({ EquipmentIssuesNbrPtsValid: false } );
               break;
               case "EquipmentIssuesValueRawScore":
                this.setState({ EquipmentIssuesValueRawScoreValid: false } );
               break;
               case "EquipmentIssuesValuePts":
                this.setState({ EquipmentIssuesValuePtsValid: false } );
               break;
               default:
              // code block
          }
        }
        else {
            switch(field) {
                case "MiQualityScore":
                this.setState({ MiQualityScoreValid: true } );
                break;
                case "DefectiveRateRawScore":
                this.setState({ DefectiveRateRawScoreValid: true } );
                break;
                case "DefectiveRatePts":
                this.setState({ DefectiveRatePtsValid: true } );
                break;
                case "MiQualityScoreAdjust":
                this.setState({ MiQualityScoreAdjustValid: true } );
                break;
                case "FootwearCopqRawScore":
                this.setState({ FootwearCopqRawScoreValid: true } );
                break;
                case "FootwearCopqPts":
                this.setState({ FootwearCopqPtsValid: true } );
                break;
                case "FootwearBondTestRawScore":
                this.setState({ FootwearBondTestRawScoreValid: true } );
                break;
                case "FootwearBondTestPts":
                this.setState({ FootwearBondTestPtsValid: true } );
                break;
                case "FootwearConverseFlexTestRawScore":
                this.setState({ FootwearConverseFlexTestRawScoreValid: true } );
                break;
                case "FootwearConverseFlexTestPts":
                this.setState({ FootwearConverseFlexTestPtsValid: true } );
                break;
                case "FootwearConverseMqaaRawScore":
                this.setState({ FootwearConverseMqaaRawScoreValid: true } );
                break;
                case "FootwearConverseMqaaPts":
                this.setState({ FootwearConverseMqaaPtsValid: true } );
                break;
                case "ApparelIssuesNbrRawScore":
                this.setState({ ApparelIssuesNbrRawScoreValid: true } );
                break;
                case "ApparelIssuesNbrPts":
                this.setState({ ApparelIssuesNbrPtsValid: true } );
                break;
                case "ApparelIssuesValueRawScore":
                this.setState({ ApparelIssuesValueRawScoreValid: true } );
                break;
                case "ApparelIssuesValuePts":
                this.setState({ ApparelIssuesValuePtsValid: true } );
                break;
                case "EquipmentIssuesNbrRawScore":
                this.setState({ EquipmentIssuesNbrRawScoreValid: true } );
                break;
                case "EquipmentIssuesNbrPts":
                this.setState({ EquipmentIssuesNbrPtsValid: true } );
                break;
                case "EquipmentIssuesValueRawScore":
                this.setState({ EquipmentIssuesValueRawScoreValid: true } );
                break;
                case "EquipmentIssuesValuePts":
                this.setState({ EquipmentIssuesValuePtsValid: true } );
                break;
                default:
                  // code block
              }
        }

    }

    validateForm = () => {
        if(this.state.MiQualityScoreValid === true &&
            this.state.DefectiveRateRawScoreValid === true &&
            this.state.DefectiveRatePtsValid === true &&
            this.state.MiQualityScoreAdjustValid === true &&
            this.state.FootwearCopqRawScoreValid === true &&
            this.state.FootwearCopqPtsValid === true &&
            this.state.FootwearBondTestRawScoreValid === true &&
            this.state.FootwearBondTestPtsValid === true &&
            this.state.FootwearConverseFlexTestRawScoreValid === true &&
            this.state.FootwearConverseFlexTestPtsValid === true &&
            this.state.FootwearConverseMqaaRawScoreValid === true &&
            this.state.FootwearConverseMqaaPtsValid === true &&
            this.state.ApparelIssuesNbrRawScoreValid === true &&
            this.state.ApparelIssuesNbrPtsValid === true &&
            this.state.ApparelIssuesValueRawScoreValid === true &&
            this.state.ApparelIssuesValuePtsValid === true &&
            this.state.EquipmentIssuesNbrRawScoreValid === true &&
            this.state.EquipmentIssuesNbrPtsValid === true &&
            this.state.EquipmentIssuesValueRawScoreValid === true &&
            this.state.EquipmentIssuesValuePtsValid === true
            ) 
            return true;
        else 
            return false;
    }

    onSave = () => {
    
        this.setState({ qualityList: [] });
        this.props.selectedFactories.forEach((factory) => {
            this.state.qualityList.push(
                {
                    "FactoryId": factory.id,
                    "MiQualityScore": this.state.MiQualityScore,
                    "ProductSafetyYes": this.state.ProductSafetyYes,
                    "ProductSafetyReason": this.state.ProductSafetyReason,
                    "DefectiveRateRawScore": this.state.DefectiveRateRawScore,
                    "DefectiveRatePts": this.state.DefectiveRatePts,
                    "MiQualityScoreAdjust": this.state.MiQualityScoreAdjust,
                    "FootwearCopqRawScore": this.state.FootwearCopqRawScore,
                    "FootwearCopqPts": this.state.FootwearCopqPts,
                    "FootwearBondTestRawScore": this.state.FootwearBondTestRawScore,
                    "FootwearBondTestPts": this.state.FootwearBondTestPts,
                    "FootwearConverseFlexTestRawScore": this.state.FootwearConverseFlexTestRawScore,
                    "FootwearConverseFlexTestPts": this.state.FootwearConverseFlexTestPts,
                    "FootwearConverseMqaaRawScore": this.state.FootwearConverseMqaaRawScore,
                    "FootwearConverseMqaaPts": this.state.FootwearConverseMqaaPts,
                    "ApparelIssuesNbrRawScore": this.state.ApparelIssuesNbrRawScore,
                    "ApparelIssuesNbrPts": this.state.ApparelIssuesNbrPts,
                    "ApparelIssuesValueRawScore": this.state.ApparelIssuesValueRawScore,
                    "ApparelIssuesValuePts": this.state.ApparelIssuesValuePts,
                    "EquipmentIssuesNbrRawScore": this.state.EquipmentIssuesNbrRawScore,
                    "EquipmentIssuesNbrPts": this.state.EquipmentIssuesNbrPts,
                    "EquipmentIssuesValueRawScore": this.state.EquipmentIssuesValueRawScore,
                    "EquipmentIssuesValuePts": this.state.EquipmentIssuesValuePts,
                    "QualityNonCompliantReason": this.state.QualityNonCompliantReason

                }
            ) 
        });

        this.setState({ postStatus: null });
        if(this.state.qualityList !== null) {
            this.setState( {postStatus: "Posting"});
            this.saveQuality();
        };

    };

    saveQuality(){
        fetch(Config.QualitySaveUrl,  {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "quality": this.state.qualityList })
          }).then((response) => {
          if (response.ok) {
            return response.json();
          } 
          else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ postStatus: "Success" });
          window.location.reload(false);
        })
        .catch((error) => {
          this.setState( {postStatus: "Failure"} )
    })};


    render() {

        return (

        <div class="modal fade" id="Quality" tabindex="-1" role="dialog" aria-labelledby="QualityModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered  modal-dialog-scrollable modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        {
                            this.props.selectedFactories.length === 1 ?
                            <h6 class="modal-title" id="QualityModalTitle">Enter Manufacturing Index Data - {this.props.selectedFactories[0].label.split("-")[1]}</h6>
                            :
                            <h6 class="modal-title" id="QualityModalTitle">Enter Manufacturing Index Data - {this.props.selectedFactories.length} Selected Factories</h6>
                        }
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p><small><TiInfoOutline />&nbsp;Use the TAB key to move from field to field.</small></p>
                        <form>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="MiQualityScore">MiQualityScore&nbsp;<span class="text-danger">{ this.state.MiQualityScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="MiQualityScore" value ={this.state.MiQualityScore} onChange={this.setValue.bind(this, "MiQualityScore")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="ProductSafetyYes">ProductSafetyYes</label>
                                <select class="form-control" id="ProductSafetyYes" value={this.state.ProductSafetyYes} onChange={this.setValue.bind(this, "ProductSafetyYes")}>
                                        <option></option>
                                        <option>Yes</option>
                                        <option>No</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="ProductSafetyReason">ProductSafetyReason</label>
                                <input type = "text" class="form-control" id ="ProductSafetyReason" value ={this.state.ProductSafetyReason} onChange={this.setValue.bind(this, "ProductSafetyReason")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="DefectiveRateRawScore">DefectiveRateRawScore&nbsp;<span class="text-danger">{ this.state.DefectiveRateRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="DefectiveRateRawScore"value ={this.state.DefectiveRateRawScore} onChange={this.setValue.bind(this, "DefectiveRateRawScore")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="DefectiveRatePts">DefectiveRatePts&nbsp;<span class="text-danger">{ this.state.DefectiveRatePtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="DefectiveRatePts" value ={this.state.DefectiveRatePts} onChange={this.setValue.bind(this, "DefectiveRatePts")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="MiQualityScoreAdjust">MiQualityScoreAdjust&nbsp;<span class="text-danger">{ this.state.MiQualityScoreAdjustValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="MiQualityScoreAdjust"value ={this.state.MiQualityScoreAdjust} onChange={this.setValue.bind(this, "MiQualityScoreAdjust")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="FootwearCopqRawScore">FootwearCopqRawScore&nbsp;<span class="text-danger">{ this.state.FootwearCopqRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="FootwearCopqRawScore" value ={this.state.FootwearCopqRawScore} onChange={this.setValue.bind(this, "FootwearCopqRawScore")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="FootwearCopqPts">FootwearCopqPts&nbsp;<span class="text-danger">{ this.state.FootwearCopqPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="FootwearCopqPts"value ={this.state.FootwearCopqPts} onChange={this.setValue.bind(this, "FootwearCopqPts")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="FootwearBondTestRawScore">FootwearBondTestRawScore&nbsp;<span class="text-danger">{ this.state.FootwearBondTestRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="FootwearBondTestRawScore" value ={this.state.FootwearBondTestRawScore} onChange={this.setValue.bind(this, "FootwearBondTestRawScore")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="FootwearBondTestPts">FootwearBondTestPts&nbsp;<span class="text-danger">{ this.state.FootwearBondTestPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="FootwearBondTestPts"value ={this.state.FootwearBondTestPts} onChange={this.setValue.bind(this, "FootwearBondTestPts")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="FootwearConverseFlexTestRawScore">FootwearConverseFlexTestRawScore&nbsp;<span class="text-danger">{ this.state.FootwearConverseFlexTestRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="FootwearConverseFlexTestRawScore" value ={this.state.FootwearConverseFlexTestRawScore} onChange={this.setValue.bind(this, "FootwearConverseFlexTestRawScore")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="FootwearConverseFlexTestPts">FootwearConverseFlexTestPts&nbsp;<span class="text-danger">{ this.state.FootwearConverseFlexTestPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="FootwearConverseFlexTestPts"value ={this.state.FootwearConverseFlexTestPts} onChange={this.setValue.bind(this, "FootwearConverseFlexTestPts")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="FootwearConverseMqaaRawScore">FootwearConverseMqaaRawScore&nbsp;<span class="text-danger">{ this.state.FootwearConverseMqaaRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="FootwearConverseMqaaRawScore" value ={this.state.FootwearConverseMqaaRawScore} onChange={this.setValue.bind(this, "FootwearConverseMqaaRawScore")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="FootwearConverseMqaaPts">FootwearConverseMqaaPts&nbsp;<span class="text-danger">{ this.state.FootwearConverseMqaaPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="FootwearConverseMqaaPts"value ={this.state.FootwearConverseMqaaPts} onChange={this.setValue.bind(this, "FootwearConverseMqaaPts")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="ApparelIssuesNbrRawScore">ApparelIssuesNbrRawScore&nbsp;<span class="text-danger">{ this.state.ApparelIssuesNbrRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="ApparelIssuesNbrRawScore" value ={this.state.ApparelIssuesNbrRawScore} onChange={this.setValue.bind(this, "ApparelIssuesNbrRawScore")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="ApparelIssuesNbrPts">ApparelIssuesNbrPts&nbsp;<span class="text-danger">{ this.state.ApparelIssuesNbrPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="ApparelIssuesNbrPts"value ={this.state.ApparelIssuesNbrPts} onChange={this.setValue.bind(this, "ApparelIssuesNbrPts")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="ApparelIssuesValueRawScore">ApparelIssuesValueRawScore&nbsp;<span class="text-danger">{ this.state.ApparelIssuesValueRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="ApparelIssuesValueRawScore" value ={this.state.ApparelIssuesValueRawScore} onChange={this.setValue.bind(this, "ApparelIssuesValueRawScore")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="ApparelIssuesValuePts">ApparelIssuesValuePts&nbsp;<span class="text-danger">{ this.state.ApparelIssuesValuePtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="ApparelIssuesValuePts"value ={this.state.ApparelIssuesValuePts} onChange={this.setValue.bind(this, "ApparelIssuesValuePts")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="EquipmentIssuesNbrRawScore">EquipmentIssuesNbrRawScore&nbsp;<span class="text-danger">{ this.state.EquipmentIssuesNbrRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="EquipmentIssuesNbrRawScore" value ={this.state.EquipmentIssuesNbrRawScore} onChange={this.setValue.bind(this, "EquipmentIssuesNbrRawScore")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="EquipmentIssuesNbrPts">EquipmentIssuesNbrPts&nbsp;<span class="text-danger">{ this.state.EquipmentIssuesNbrPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="EquipmentIssuesNbrPts"value ={this.state.EquipmentIssuesNbrPts} onChange={this.setValue.bind(this, "EquipmentIssuesNbrPts")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="EquipmentIssuesValueRawScore">EquipmentIssuesValueRawScore&nbsp;<span class="text-danger">{ this.state.EquipmentIssuesValueRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="EquipmentIssuesValueRawScore" value ={this.state.EquipmentIssuesValueRawScore} onChange={this.setValue.bind(this, "EquipmentIssuesValueRawScore")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="EquipmentIssuesValuePts">EquipmentIssuesValuePts&nbsp;<span class="text-danger">{ this.state.EquipmentIssuesValuePtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="EquipmentIssuesValuePts"value ={this.state.EquipmentIssuesValuePts} onChange={this.setValue.bind(this, "EquipmentIssuesValuePts")} />
                            </div>
                        </div>
                         <div class="form-row">
                                <div class="form-group col-sm-12">
                                    <label for="QualityNonCompliantReason">QualityNonCompliantReason</label>
                                    <textarea class="form-control" id="QualityNonCompliantReason" rows="3" value={this.state.QualityNonCompliantReason} onChange={this.setValue.bind(this, "QualityNonCompliantReason")}></textarea>
                                </div>
                        </div>
                        </form>

                        {
                                this.state.postStatus === "Posting" ?

                                <div class="alert alert-info" role="alert">
                                    Saving quality data...please wait
                                </div>

                                : this.state.postStatus === "Success" ?

                                <div class="alert alert-success" role="alert">
                                    Completed saving quality data.
                                </div>

                                : this.state.postStatus === "Failure" ?
                                
                                <div class="alert alert-danger" role="alert">
                                    Something went wrong while saving the quality data! Please try again later.
                                </div>

                                : null
                        }

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-sm btn-primary" onClick={this.onSave} disabled={!this.validateForm()}>Save</button>
                    </div>
                </div>
            </div>
        </div>

        );
    };
};

export default ModalQualityForm;