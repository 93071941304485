import React, { Component } from "react";
import { GiFactory } from "react-icons/gi";
import { FaUserCircle } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import { MdContactMail } from "react-icons/md";
import { MdError } from "react-icons/md";
import { FiLoader } from "react-icons/fi";
import ModalOverview from "../components/ModalOverview";
import ModalContact from "../components/ModalContact";
import Config from "../Config";

class Header extends Component {

  state = {
    ActiveReportingPeriod : null,
    Status : "Loading"
  };

  getReportingPeriodList(){
    fetch(Config.ReportingPeriodListUrl).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      this.setState({ ActiveReportingPeriod: data.filter((item) => item.Active === true) });
      this.setState({ Status: "Success" });
    })
    .catch((error) => {
      this.setState( {Status: "Failure"} )
  })};

  componentDidMount() {
    this.getReportingPeriodList();
  };

  render() {

    return (
        <div>
            <nav class="navbar navbar-expand-lg" style={{backgroundColor: "#563d7c", color: "#ffffff"}}>
                <GiFactory />&nbsp;Manufacturing Index
                <span class="navbar-nav mr-auto"></span>
                <a href="#" class="text-decoration-none" style={{ color: "#cbbde2" }} data-toggle="modal" data-target="#ModalContact"><MdContactMail /></a>
                &nbsp;&nbsp;&nbsp;
                <button class="btn  btn-outline-warning btn-sm" role="button" data-toggle="modal" data-target="#ModalOverview">
                  Overview
                </button>&nbsp;
            </nav>
            <div class="container-fluid">
              <div class="row border border-light bg-light">
                <div class="col-md-6">
      
                  {
                    this.state.Status === "Loading" ? 
                      <small class="text-info"><FiLoader /></small>
                    : this.state.Status === "Success" ? 
                      <small class="font-weight-bold text-muted"><FaCalendarAlt />&nbsp;{this.state.ActiveReportingPeriod[0].FiscalQuarter}</small>
                    : <small class="text-danger"><MdError /></small>
                  }
                  
                </div>
                <div class="col-md-4 offset-md-2 text-right">
                  <small class="font-weight-bold text-muted"><FaUserCircle />&nbsp;
                  {
                    this.props.user
                  }
                  </small>
                </div>
              </div>
            </div>
            <ModalOverview />
            <ModalContact />
        </div>
    );

  };

};

export default Header;
