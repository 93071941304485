import React, { Component } from "react";
import { IoIosSettings } from "react-icons/io";
import Config from "../Config";

class ModalReportingPeriod extends Component {

    state = {
        reportingPeriodList : null,
        getStatus : "Loading",
        reportingPeriodId : "0",
        postStatus : null
      };
    
      getReportingPeriodList(){
        fetch(Config.ReportingPeriodListUrl).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ reportingPeriodList: data });
          this.setState({ getStatus: "Success" });
        })
        .catch((error) => {
          this.setState( {getStatus: "Failure"} )
      })};
    
      componentDidMount() {
        this.getReportingPeriodList();
      };

      setActiveReportingPeriod(){
        fetch(Config.SetActiveReportingPeriodUrl,  {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "id": this.state.reportingPeriodId })
          }).then((response) => {
          if (response.ok) {
            return response.json();
          } 
          else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ postStatus: "Success" });
          window.location.reload(false);
        })
        .catch((error) => {
          this.setState( {postStatus: "Failure"} )
      })};

      onReportingPeriodChange = (evt) => {
        this.setState({ reportingPeriodId: evt.target.value });
      };

      onSet = () => {
        this.setState({ postStatus: null });
        if(this.state.reportingPeriodId !== null && this.state.reportingPeriodId !== "0") {
          this.setState( {postStatus: "Posting"});
          this.setActiveReportingPeriod();
        };
      };

    render() {

        return (

            <div class="modal fade" id="reportingperiod" tabindex="-1" role="dialog" aria-labelledby="ModalReportingPeriodTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h6 class="modal-title" id="ModalReportingPeriodTitle"><IoIosSettings />&nbsp;Set Active Reporting Period</h6>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div class="form-group">
                                    <label for="reportingPeriod">Select Reporting Period</label>
                                    <select class="form-control" id="reportingPeriod"
                                    value={this.state.reportingPeriodId} onChange={this.onReportingPeriodChange}>
                                    <option value="0" selected>Choose...</option>
                                    {
                                            this.state.getStatus === "Success" ? 
                                            this.state.reportingPeriodList.map((item) => 
                                            (<option value={item.Id} key={item.Id}>{item.FiscalQuarter}</option>))
                                            : null
                                    }
                                    </select>
                                    <small class="text-muted">The above-selected reporting period is set as active reporting period.</small>
                                </div>
                            </form>
                            {
                                this.state.getStatus === "Failure" ? 
                                <div class="alert alert-danger" role="alert">
                                    Something went wrong while loading the reporting period list! Please try again later.
                                </div>
                                : null
                            }
                            {
                                this.state.postStatus === "Posting" ?

                                <div class="alert alert-info" role="alert">
                                    Setting active reporting period...please wait
                                </div>

                                : this.state.postStatus === "Success" ?

                                <div class="alert alert-success" role="alert">
                                    Completed setting active reporting period.
                                </div>

                                : this.state.postStatus === "Failure" ?
                                
                                <div class="alert alert-danger" role="alert">
                                    Something went wrong while setting the active reporting period! Please try again later.
                                </div>

                                : null
                            }
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
                            {
                                this.state.reportingPeriodId !== "0" ? 
                                <button type="button" class="btn btn-sm btn-primary" onClick={this.onSet}>Set</button>
                                : <button type="button" class="btn btn-sm btn-primary" disabled>Set</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            
        );
    };
};

export default ModalReportingPeriod;