import ExcelJS from "exceljs/dist/es5/exceljs.browser.js";
import { saveAs } from "file-saver";
import { protect, fill, border, fileName } from "./UtilExcel";

export const save = async (brand, productEngine, country, SustainabilityList) => {

    const wb = new ExcelJS.Workbook();

    const ws = wb.addWorksheet(fileName("Sustainability", brand, productEngine, country), {views:[{state: "frozen", xSplit: 7, ySplit:1}]})

    ws.protect("Sustainability", protect());

    ws.columns = [
        { header: "Id", key: "Id", width: 10, hidden: true },
        { header: "FactoryGroupName", key: "FactoryGroupName", width: 20 },
        { header: "FactoryCRCode", key: "FactoryCRCode", width: 15 },
        { header: "FactoryPOCode", key: "FactoryPOCode", width: 15 },
        { header: "Brand", key: "Brand", width: 10 },
        { header: "ProductEngine", key: "ProductEngine", width: 15 },
        { header: "Country", key: "Country", width: 20 },
        { header: "MiSmsiScore", key: "MiSmsiScore", width: 25 },
        { header: "MiSmsiScoreAdjust", key: "MiSmsiScoreAdjust", width: 25 },
        { header: "LaborPts", key: "LaborPts", width: 25 },
        { header: "HsePts", key: "HsePts", width: 25 },
        { header: "EsatPts", key: "EsatPts", width: 25 },
        { header: "EcatPts", key: "EcatPts", width: 25 },
        { header: "LatPts", key: "LatPts", width: 25 },
        { header: "HsatPts", key: "HsatPts", width: 25 },
        { header: "HrmatPts", key: "HrmatPts", width: 25 },
        { header: "UnauthSubconYes", key: "UnauthSubconYes", width: 25 },
        { header: "ZeroToleranceYes", key: "ZeroToleranceYes", width: 25 },
        { header: "ZeroToleranceReason", key: "ZeroToleranceReason", width: 25 },
        { header: "SmsiNonCompliantReason", key: "SmsiNonCompliantReason", width: 25 }
       
      ];

      SustainabilityList.map((factory) =>
        ws.addRow([ factory.Id,
                    factory.FactoryGroupName, 
                    factory.FactoryCRCode,
                    factory.FactoryPOCode, 
                    factory.Brand,
                    factory.ProductEngine,
                    factory.Country,
                    factory.MiSmsiScore,
                    factory.MiSmsiScoreAdjust,
                    factory.LaborPts,
                    factory.HsePts,
                    factory.EsatPts,
                    factory.EcatPts,
                    factory.LatPts,
                    factory.HsatPts,
                    factory.HrmatPts,
                    factory.UnauthSubconYes,
                    factory.ZeroToleranceYes,
                    factory.ZeroToleranceReason,
                    factory.SmsiNonCompliantReason                
                ]) 
    )
    
    //Format and Lock Header
    const firstRow = ws.getRow(1);
    firstRow.font = { bold: true, size: 12 };

    //Unlock All Cells
    ws.eachRow((row) => {
      row.eachCell({ includeEmpty: true },(cell) => {
          cell.protection = { locked: false };
      });
    });

    //Lock Id Cells
    const idCol = ws.getColumn("Id");
    idCol.eachCell((cell) => {
      cell.protection = { locked: true };
    });
    
    //Format and Lock Factory Group Name Cells
    const factoryNameCol = ws.getColumn("FactoryGroupName");
    factoryNameCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Factory CR Code Cells
    const factoryCRCodeCol = ws.getColumn("FactoryCRCode");
    factoryCRCodeCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Factory PO Code Cells
    const factoryPOCodeCol = ws.getColumn("FactoryPOCode");
    factoryPOCodeCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Brand Cells
    const brandCol = ws.getColumn("Brand");
    brandCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Product Engine Cells
    const productEngineCol = ws.getColumn("ProductEngine");
    productEngineCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Country Cells
    const countryCol = ws.getColumn("Country");
    countryCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    const buf = await wb.xlsx.writeBuffer();

    saveAs(new Blob([buf]), fileName("Sustainability", brand, productEngine, country) + ".xlsx");
}