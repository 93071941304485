import React, { Component } from "react";
import { IoIosStats } from "react-icons/io";
import { TiInfoOutline } from "react-icons/ti";
import Config from "../Config";

class CardFactoryStatistics extends Component {

    state = {
        factoryStatisticsList : [],
        status: "Loading",
      };

    getFactoryStatisticsList(){
        fetch(Config.FactoryStatisticsListUrl).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ factoryStatisticsList: data });
          this.setState({ status: "Success" });
        })
        .catch((error) => {
          this.setState( {status: "Failure"} )
      })};

      componentDidMount() {
        this.getFactoryStatisticsList();
      };

    render() {

        return (

            <div class="card border-white">
              <div class="card-body">
                <h6 class="card-title">
                  <IoIosStats />&nbsp;Factory Statistics
                </h6>
                <div class="border-top my-2"></div>
                <ul class="list-group factoryStatisticsList-group-flush">  
                {
                    this.state.status === "Loading" ?
                        <div class="alert alert-info" role="alert">
                            Loading...
                        </div>
                    : this.state.status === "Success" ?
                        this.state.factoryStatisticsList.map(item => (
                        <li class="list-group-item border-white d-flex justify-content-between align-items-center" key={item.Order}>
                            {item.Key}
                            {
                              item.Group === "Total" ?
                              <span class="badge" style={{backgroundColor: "#220186", color: "#FFFFFF"}}><h6><small>&nbsp;{item.Value}&nbsp;</small></h6></span>
                              : item.Group === "ProductEngine" ?
                              <span class="badge" style={{backgroundColor: "#A20065", color: "#FFFFFF"}}><h6><small>&nbsp;{item.Value}&nbsp;</small></h6></span>
                              : item.Group === "Region" ?
                              <span class="badge" style={{backgroundColor: "#00809E", color: "#FFFFFF"}}><h6><small>&nbsp;{item.Value}&nbsp;</small></h6></span>
                              :<span class="badge" style={{backgroundColor: "#8E0090", color: "#FFFFFF"}}><h6><small>&nbsp;{item.Value}&nbsp;</small></h6></span>
                            }
                        </li>
                        ))
                    : this.state.status === "Failure" ?
                        <div class="alert alert-danger" role="alert">
                            Something went wrong while loading the factory statistics! Please try refreshing the page.
                        </div>
                    : null

                }
                </ul>
                <small class="text-muted">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<TiInfoOutline />&nbsp;Few factories produces both Nike and Converse items.
                </small>
              </div>
            </div>
            
        );
    };
};

export default CardFactoryStatistics;