import React, { Component } from "react";
import CardTemplateDownload from "../components/CardTemplateDownload"
import CardDataEntry from "../components/CardDataEntry";
import CardTemplateUpload from "../components/CardTemplateUpload";
import CardDataReview from "../components/CardDataReview";
import CardDataStatus from "../components/CardDataStatus";
import CardFactoryStatistics from "../components/CardFactoryStatistics";
import Config from "../Config";
import Or from "../assets/or.gif";
import { GiThreeLeaves } from "react-icons/gi";
 
class SustainabilityHome extends Component {

    state = {
        IsLocked : false,
        Status : "Loading"
      };
    
      getReportingPeriodList(){
        fetch(Config.ReportingPeriodListUrl).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ IsLocked: data.filter(data => data.Active === true)[0].Locked });
          this.setState({ Status: "Success" });
        })
        .catch((error) => {
          this.setState( {Status: "Failure"} )
      })};
    
      componentDidMount() {
        this.getReportingPeriodList();
      };
 
    render() {
        return (
        
            <div class="container-fluid">
                {
                    this.state.Status === "Loading" ?
                    <div class="alert alert-info" role="alert">
                        Loading...
                    </div>
                    : (this.state.Status === "Success" && this.state.IsLocked === true) ?
                         <div class="alert alert-warning" role="alert">
                            The Program Manager has locked the Sustainability page temporarily. 
                            To regain access to the page, please contact the Program Manager.
                        </div>
                    : (this.state.Status === "Success" && this.state.IsLocked === false) ?

                        <div class="row bg-light">

                            <div class="col-8">

                                <div class="card-deck">

                                    <CardTemplateDownload 
                                        template="#sustainability"/>

                                </div>

                                <br />

                                <div class="card-deck">

                                    <CardDataEntry 
                                        link="sustainabilitydataentry"
                                        action="Data Entry"/>
                                    
                                    <img alt="" src={Or} height={200} />

                                    <CardTemplateUpload 
                                        template="Sustainability" />

                                </div>

                                <br />

                                <div class="card-deck">

                                    <CardDataReview
                                        link="sustainabilitydatareview"
                                        action="Data Review"/>

                                </div>

                                <br />

                                <div class="card-deck">

                                    <CardDataStatus 
                                        section="Sustainability" />

                                </div>

                            </div>
                            <div class="col-4">

                                <div class="card-deck">
                                    <CardFactoryStatistics /> <br />
                                </div>

                                <span>&nbsp;</span>

                                <div class="text-right">
                                    <h6><GiThreeLeaves />&nbsp;SUSTAINABILITY</h6>
                                    <hr class="my-2" />
                                </div>

                            </div>

                            <span>&nbsp;</span>

                        </div>
                    :
                        <div class="alert alert-danger" role="alert">
                            Something went wrong while loading the Sustainability home page! Please try refreshing the page.
                        </div>
                    }

            </div>
        );
    }
}

export default SustainabilityHome;