import React, { Component } from "react";
import { GiFactory } from "react-icons/gi";

class ModalOverview extends Component {

    render() {

        return (

            <div class="modal fade" id="ModalOverview" tabindex="-1" role="dialog" aria-labelledby="ModalOverviewTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title" id="ModalOverviewTitle"><GiFactory />&nbsp;Manufacturing Index Overview</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>
                            The Manufacturing Index (MI) is Nike's way to derive a rating 
                            for manufacturing partners based on their performance in four 
                            specific areas. MI is calculated on how well a factory partner 
                            does in sustainability, quality, delivery and costing.
                        </p>
                        <p>
                            The sustainability metric is based on the outcome of an annual 
                            compliance audit along with a quarterly beyond compliance component 
                            for qualifying partners. A qualified and participating partner in the 
                            beyond compliance programs may bring their sustainability metric to 
                            silver or gold, above the compliant bronze.
                        </p>
                        <p>
                            The delivery metric is based on how well a factory partner 
                            delivers on each of their purchase orders (PO). Goods At 
                            Consolidator (GAC) makes up seventy percent of the delivery 
                            metric, while On Time Performance (OTP) makes up the other 
                            thirty percent.
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
                </div>
            </div>
            
        );
    };
};

export default ModalOverview;