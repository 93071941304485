import ExcelJS from "exceljs/dist/es5/exceljs.browser.js";
import { saveAs } from "file-saver";
import { protect, fill, border, fileName } from "./UtilExcel";

//Save All
export const saveAll = async (brand, productEngine, country, costingList) => {

    const wb = new ExcelJS.Workbook();

    const ws = wb.addWorksheet(fileName("Costing", brand, productEngine, country), {views:[{state: "frozen", xSplit: 7, ySplit:1}]})

    ws.protect("Costing", protect());

    ws.columns = [
        { header: "Id", key: "Id", width: 10, hidden: true },
        { header: "FactoryGroupName", key: "FactoryGroupName", width: 20 },
        { header: "FactoryCRCode", key: "FactoryCRCode", width: 15 },
        { header: "FactoryPOCode", key: "FactoryPOCode", width: 15 },
        { header: "Brand", key: "Brand", width: 10 },
        { header: "ProductEngine", key: "ProductEngine", width: 15 },
        { header: "Country", key: "Country", width: 20 },
        { header: "MiCostingScore", key: "MiCostingScore", width: 25 },
        { header: "FootwearCompRawScore", key: "FootwearCompRawScore", width: 25 },
        { header: "FootwearCompPts", key: "FootwearCompPts", width: 25 },
        { header: "FootwearAccToolRawScore", key: "FootwearAccToolRawScore", width: 25 },
        { header: "FootwearAccToolPts", key: "FootwearAccToolPts", width: 25 },
        { header: "FootwearAccCbdRejectRawScore", key: "FootwearAccCbdRejectRawScore", width: 25 },
        { header: "FootwearAccCbdPts", key: "FootwearAccCbdPts", width: 25 },
        { header: "FootwearTimeQuoteRawScore", key: "FootwearTimeQuoteRawScore", width: 25 },
        { header: "FootwearTimeQuotePts", key: "FootwearTimeQuotePts", width: 25 },
        { header: "FootwearTimeGac35RawScore", key: "FootwearTimeGac35RawScore", width: 25 },
        { header: "FootwearTimeGac35Pts", key: "FootwearTimeGac35Pts", width: 25 },
        { header: "ApparelCompWeight", key: "ApparelCompWeight", width: 25 },
        { header: "ApparelCompPts", key: "ApparelCompPts", width: 25 },
        { header: "ApparelAccMaterialUtRawScore", key: "ApparelAccMaterialUtRawScore", width: 25 },
        { header: "ApparelAccWeight", key: "ApparelAccWeight", width: 25 },
        { header: "ApparelAccPts", key: "ApparelAccPts", width: 25 },
        { header: "ApparelTimeMissFobRawScore", key: "ApparelTimeMissFobRawScore", width: 25 },
        { header: "ApparelTimeWeight", key: "ApparelTimeWeight", width: 25 },
        { header: "ApparelTimePts", key: "ApparelTimePts", width: 25 },
        { header: "ApparelCompCmpMmRawScore", key: "ApparelCompCmpMmRawScore", width: 25 },
        { header: "ApparelCompYarnRawScore", key: "ApparelCompYarnRawScore", width: 25 },
        { header: "ApparelAccStitchCtRawScore", key: "ApparelAccStitchCtRawScore", width: 25 },
        { header: "EquipmentCompCmpRangeRawScore", key: "EquipmentCompCmpRangeRawScore", width: 25 },
        { header: "EquipmentCompWeight", key: "EquipmentCompWeight", width: 25 },
        { header: "EquipmentCompPts", key: "EquipmentCompPts", width: 25 },
        { header: "EquipmentAccMaterialUtRawScore", key: "EquipmentAccMaterialUtRawScore", width: 25 },
        { header: "EquipmentAccPrintRawScore", key: "EquipmentAccPrintRawScore", width: 25 },
        { header: "EquipmentAccWeight", key: "EquipmentAccWeight", width: 25 },
        { header: "EquipmentAccPts", key: "EquipmentAccPts", width: 25 },
        { header: "EquipmentTimeMissFobRawScore", key: "EquipmentTimeMissFobRawScore", width: 25 },
        { header: "EquipmentTimeWeight", key: "EquipmentTimeWeight", width: 25 },
        { header: "EquipmentTimePts", key: "EquipmentTimePts", width: 25 },
        { header: "CostingNonCompliantReason", key: "CostingNonCompliantReason", width: 25 }
      ];

      costingList.map((factory) =>
        ws.addRow([ factory.Id,
                    factory.FactoryGroupName, 
                    factory.FactoryCRCode, 
                    factory.FactoryPOCode, 
                    factory.Brand,
                    factory.ProductEngine,
                    factory.Country,
                    factory.MiCostingScore,
                    factory.FootwearCompRawScore,
                    factory.FootwearCompPts,
                    factory.FootwearAccToolRawScore,
                    factory.FootwearAccToolPts,
                    factory.FootwearAccCbdRejectRawScore,
                    factory.FootwearAccCbdPts,
                    factory.FootwearTimeQuoteRawScore,
                    factory.FootwearTimeQuotePts,
                    factory.FootwearTimeGac35RawScore,
                    factory.FootwearTimeGac35Pts,
                    factory.ApparelCompWeight,
                    factory.ApparelCompPts,
                    factory.ApparelAccMaterialUtRawScore,
                    factory.ApparelAccWeight,
                    factory.ApparelAccPts,
                    factory.ApparelTimeMissFobRawScore,
                    factory.ApparelTimeWeight,
                    factory.ApparelTimePts,
                    factory.ApparelCompCmpMmRawScore,
                    factory.ApparelCompYarnRawScore,
                    factory.ApparelAccStitchCtRawScore,
                    factory.EquipmentCompCmpRangeRawScore,
                    factory.EquipmentCompWeight,
                    factory.EquipmentCompPts,
                    factory.EquipmentAccMaterialUtRawScore,
                    factory.EquipmentAccPrintRawScore,
                    factory.EquipmentAccWeight,
                    factory.EquipmentAccPts,
                    factory.EquipmentTimeMissFobRawScore,
                    factory.EquipmentTimeWeight,
                    factory.EquipmentTimePts,
                    factory.CostingNonCompliantReason

                ]) 
    )
    
    //Format and Lock Header
    const firstRow = ws.getRow(1);
    firstRow.font = { bold: true, size: 12 };

    //Unlock All Cells
    ws.eachRow((row) => {
      row.eachCell({ includeEmpty: true },(cell) => {
          cell.protection = { locked: false };
      });
    });

    //Lock Id Cells
    const idCol = ws.getColumn("Id");
    idCol.eachCell((cell) => {
      cell.protection = { locked: true };
    });
    
    //Format and Lock Factory Group Name Cells
    const factoryNameCol = ws.getColumn("FactoryGroupName");
    factoryNameCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Factory CR Code Cells
    const factoryCRCodeCol = ws.getColumn("FactoryCRCode");
    factoryCRCodeCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Factory PO Code Cells
    const factoryPOCodeCol = ws.getColumn("FactoryPOCode");
    factoryPOCodeCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Brand Cells
    const brandCol = ws.getColumn("Brand");
    brandCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Product Engine Cells
    const productEngineCol = ws.getColumn("ProductEngine");
    productEngineCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Country Cells
    const countryCol = ws.getColumn("Country");
    countryCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    const buf = await wb.xlsx.writeBuffer();

    saveAs(new Blob([buf]), fileName("Costing", brand, productEngine, country) + ".xlsx");
  }

  //Save Apparel
  export const saveApparel = async (brand, productEngine, country, costingList) => {

    const wb = new ExcelJS.Workbook();

    const ws = wb.addWorksheet(fileName("Costing", brand, productEngine, country), {views:[{state: "frozen", xSplit: 7, ySplit:1}]})

    ws.protect("Costing", protect());

    ws.columns = [
      { header: "Id", key: "Id", width: 10, hidden: true },
      { header: "FactoryGroupName", key: "FactoryGroupName", width: 20 },
      { header: "FactoryCRCode", key: "FactoryCRCode", width: 15 },
      { header: "FactoryPOCode", key: "FactoryPOCode", width: 15 },
      { header: "Brand", key: "Brand", width: 10 },
      { header: "ProductEngine", key: "ProductEngine", width: 15 },
      { header: "Country", key: "Country", width: 20 },
      { header: "MiCostingScore", key: "MiCostingScore", width: 25 },
      { header: "FootwearCompRawScore", key: "FootwearCompRawScore", width: 25, hidden: true },
      { header: "FootwearCompPts", key: "FootwearCompPts", width: 25, hidden: true },
      { header: "FootwearAccToolRawScore", key: "FootwearAccToolRawScore", width: 25, hidden: true },
      { header: "FootwearAccToolPts", key: "FootwearAccToolPts", width: 25, hidden: true },
      { header: "FootwearAccCbdRejectRawScore", key: "FootwearAccCbdRejectRawScore", width: 25, hidden: true },
      { header: "FootwearAccCbdPts", key: "FootwearAccCbdPts", width: 25, hidden: true },
      { header: "FootwearTimeQuoteRawScore", key: "FootwearTimeQuoteRawScore", width: 25, hidden: true },
      { header: "FootwearTimeQuotePts", key: "FootwearTimeQuotePts", width: 25, hidden: true },
      { header: "FootwearTimeGac35RawScore", key: "FootwearTimeGac35RawScore", width: 25, hidden: true },
      { header: "FootwearTimeGac35Pts", key: "FootwearTimeGac35Pts", width: 25, hidden: true },
      { header: "ApparelCompWeight", key: "ApparelCompWeight", width: 25 },
      { header: "ApparelCompPts", key: "ApparelCompPts", width: 25 },
      { header: "ApparelAccMaterialUtRawScore", key: "ApparelAccMaterialUtRawScore", width: 25 },
      { header: "ApparelAccWeight", key: "ApparelAccWeight", width: 25 },
      { header: "ApparelAccPts", key: "ApparelAccPts", width: 25 },
      { header: "ApparelTimeMissFobRawScore", key: "ApparelTimeMissFobRawScore", width: 25 },
      { header: "ApparelTimeWeight", key: "ApparelTimeWeight", width: 25 },
      { header: "ApparelTimePts", key: "ApparelTimePts", width: 25 },
      { header: "ApparelCompCmpMmRawScore", key: "ApparelCompCmpMmRawScore", width: 25 },
      { header: "ApparelCompYarnRawScore", key: "ApparelCompYarnRawScore", width: 25 },
      { header: "ApparelAccStitchCtRawScore", key: "ApparelAccStitchCtRawScore", width: 25 },
      { header: "EquipmentCompCmpRangeRawScore", key: "EquipmentCompCmpRangeRawScore", width: 25, hidden: true },
      { header: "EquipmentCompWeight", key: "EquipmentCompWeight", width: 25, hidden: true },
      { header: "EquipmentCompPts", key: "EquipmentCompPts", width: 25, hidden: true },
      { header: "EquipmentAccMaterialUtRawScore", key: "EquipmentAccMaterialUtRawScore", width: 25, hidden: true },
      { header: "EquipmentAccPrintRawScore", key: "EquipmentAccPrintRawScore", width: 25, hidden: true },
      { header: "EquipmentAccWeight", key: "EquipmentAccWeight", width: 25, hidden: true },
      { header: "EquipmentAccPts", key: "EquipmentAccPts", width: 25, hidden: true },
      { header: "EquipmentTimeMissFobRawScore", key: "EquipmentTimeMissFobRawScore", width: 25, hidden: true },
      { header: "EquipmentTimeWeight", key: "EquipmentTimeWeight", width: 25, hidden: true },
      { header: "EquipmentTimePts", key: "EquipmentTimePts", width: 25, hidden: true },
      { header: "CostingNonCompliantReason", key: "CostingNonCompliantReason", width: 25 }
    ];

      costingList.map((factory) =>
          ws.addRow([ factory.Id,
            factory.FactoryGroupName, 
            factory.FactoryCRCode, 
            factory.FactoryPOCode, 
            factory.Brand,
            factory.ProductEngine,
            factory.Country,
            factory.MiCostingScore,
            factory.FootwearCompRawScore,
            factory.FootwearCompPts,
            factory.FootwearAccToolRawScore,
            factory.FootwearAccToolPts,
            factory.FootwearAccCbdRejectRawScore,
            factory.FootwearAccCbdPts,
            factory.FootwearTimeQuoteRawScore,
            factory.FootwearTimeQuotePts,
            factory.FootwearTimeGac35RawScore,
            factory.FootwearTimeGac35Pts,
            factory.ApparelCompWeight,
            factory.ApparelCompPts,
            factory.ApparelAccMaterialUtRawScore,
            factory.ApparelAccWeight,
            factory.ApparelAccPts,
            factory.ApparelTimeMissFobRawScore,
            factory.ApparelTimeWeight,
            factory.ApparelTimePts,
            factory.ApparelCompCmpMmRawScore,
            factory.ApparelCompYarnRawScore,
            factory.ApparelAccStitchCtRawScore,
            factory.EquipmentCompCmpRangeRawScore,
            factory.EquipmentCompWeight,
            factory.EquipmentCompPts,
            factory.EquipmentAccMaterialUtRawScore,
            factory.EquipmentAccPrintRawScore,
            factory.EquipmentAccWeight,
            factory.EquipmentAccPts,
            factory.EquipmentTimeMissFobRawScore,
            factory.EquipmentTimeWeight,
            factory.EquipmentTimePts,
            factory.CostingNonCompliantReason

        ]) 
    )
    
    //Format and Lock Header
    const firstRow = ws.getRow(1);
    firstRow.font = { bold: true, size: 12 };

    //Unlock All Cells
    ws.eachRow((row) => {
      row.eachCell({ includeEmpty: true },(cell) => {
          cell.protection = { locked: false };
      });
    });

    //Lock Id Cells
    const idCol = ws.getColumn("Id");
    idCol.eachCell((cell) => {
      cell.protection = { locked: true };
    });
    
    //Format and Lock Factory Group Name Cells
    const factoryNameCol = ws.getColumn("FactoryGroupName");
    factoryNameCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Factory CR Code Cells
    const factoryCRCodeCol = ws.getColumn("FactoryCRCode");
    factoryCRCodeCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

     //Format and Lock Factory PO Code Cells
     const factoryPOCodeCol = ws.getColumn("FactoryPOCode");
     factoryPOCodeCol.eachCell((cell) => {
       cell.protection = { locked: true };
       cell.fill = fill();
       cell.border = border();
     });

    //Format and Lock Brand Cells
    const brandCol = ws.getColumn("Brand");
    brandCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Product Engine Cells
    const productEngineCol = ws.getColumn("ProductEngine");
    productEngineCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Country Cells
    const countryCol = ws.getColumn("Country");
    countryCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock FootwearCompRawScore Cells
    const footwearCompRawScoreCol = ws.getColumn("FootwearCompRawScore");
    footwearCompRawScoreCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock FootwearCompPts Cells
    const footwearCompPtsCol = ws.getColumn("FootwearCompPts");
    footwearCompPtsCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock FootwearAccToolRawScore Cells
    const footwearAccToolRawScoreCol = ws.getColumn("FootwearAccToolRawScore");
    footwearAccToolRawScoreCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock FootwearAccToolPts Cells
    const footwearAccToolPtsCol = ws.getColumn("FootwearAccToolPts");
    footwearAccToolPtsCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock FootwearAccCbdRejectRawScore Cells
    const footwearAccCbdRejectRawScoreCol = ws.getColumn("FootwearAccCbdRejectRawScore");
    footwearAccCbdRejectRawScoreCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock FootwearAccCbdPts Cells
    const footwearAccCbdPtsCol = ws.getColumn("FootwearAccCbdPts");
    footwearAccCbdPtsCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock FootwearTimeQuoteRawScore Cells
    const footwearTimeQuoteRawScoreCol = ws.getColumn("FootwearTimeQuoteRawScore");
    footwearTimeQuoteRawScoreCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock FootwearTimeQuotePts Cells
    const footwearTimeQuotePtsCol = ws.getColumn("FootwearTimeQuotePts");
    footwearTimeQuotePtsCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock FootwearTimeGac35RawScore Cells
    const footwearTimeGac35RawScoreCol = ws.getColumn("FootwearTimeGac35RawScore");
    footwearTimeGac35RawScoreCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock FootwearTimeGac35Pts Cells
    const footwearTimeGac35PtsCol = ws.getColumn("FootwearTimeGac35Pts");
    footwearTimeGac35PtsCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock EquipmentCompCmpRangeRawScore Cells
    const equipmentCompCmpRangeRawScoreCol = ws.getColumn("EquipmentCompCmpRangeRawScore");
    equipmentCompCmpRangeRawScoreCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock EquipmentCompWeight Cells
    const equipmentCompWeightCol = ws.getColumn("EquipmentCompWeight");
    equipmentCompWeightCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock EquipmentCompPts Cells
    const equipmentCompPtsCol = ws.getColumn("EquipmentCompPts");
    equipmentCompPtsCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock EquipmentAccMaterialUtRawScore Cells
    const equipmentAccMaterialUtRawScoreCol = ws.getColumn("EquipmentAccMaterialUtRawScore");
    equipmentAccMaterialUtRawScoreCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock EquipmentAccPrintRawScore Cells
    const equipmentAccPrintRawScoreCol = ws.getColumn("EquipmentAccPrintRawScore");
    equipmentAccPrintRawScoreCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock EquipmentAccWeight Cells
    const equipmentAccWeightCol = ws.getColumn("EquipmentAccWeight");
    equipmentAccWeightCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock EquipmentAccPts Cells
    const equipmentAccPtsCol = ws.getColumn("EquipmentAccPts");
    equipmentAccPtsCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock EquipmentTimeMissFobRawScore Cells
    const equipmentTimeMissFobRawScoreCol = ws.getColumn("EquipmentTimeMissFobRawScore");
    equipmentTimeMissFobRawScoreCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock EquipmentTimeWeight Cells
    const equipmentTimeWeightCol = ws.getColumn("EquipmentTimeWeight");
    equipmentTimeWeightCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock EquipmentTimePts Cells
    const equipmentTimePtsCol = ws.getColumn("EquipmentTimePts");
    equipmentTimePtsCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    const buf = await wb.xlsx.writeBuffer();

    saveAs(new Blob([buf]), fileName("Costing", brand, productEngine, country) + ".xlsx");
  }


  //Save Footwear
export const saveFootwear = async (brand, productEngine, country, costingList) => {

  const wb = new ExcelJS.Workbook();

  const ws = wb.addWorksheet(fileName("Costing", brand, productEngine, country), {views:[{state: "frozen", xSplit: 7, ySplit:1}]})

  ws.protect("Costing", protect());

  ws.columns = [
        { header: "Id", key: "Id", width: 10, hidden: true },
        { header: "FactoryGroupName", key: "FactoryGroupName", width: 20 },
        { header: "FactoryCRCode", key: "FactoryCRCode", width: 15 },
        { header: "FactoryPOCode", key: "FactoryPOCode", width: 15 },
        { header: "Brand", key: "Brand", width: 10 },
        { header: "ProductEngine", key: "ProductEngine", width: 15 },
        { header: "Country", key: "Country", width: 20 },
        { header: "MiCostingScore", key: "MiCostingScore", width: 25 },
        { header: "FootwearCompRawScore", key: "FootwearCompRawScore", width: 25 },
        { header: "FootwearCompPts", key: "FootwearCompPts", width: 25 },
        { header: "FootwearAccToolRawScore", key: "FootwearAccToolRawScore", width: 25 },
        { header: "FootwearAccToolPts", key: "FootwearAccToolPts", width: 25 },
        { header: "FootwearAccCbdRejectRawScore", key: "FootwearAccCbdRejectRawScore", width: 25 },
        { header: "FootwearAccCbdPts", key: "FootwearAccCbdPts", width: 25 },
        { header: "FootwearTimeQuoteRawScore", key: "FootwearTimeQuoteRawScore", width: 25 },
        { header: "FootwearTimeQuotePts", key: "FootwearTimeQuotePts", width: 25 },
        { header: "FootwearTimeGac35RawScore", key: "FootwearTimeGac35RawScore", width: 25 },
        { header: "FootwearTimeGac35Pts", key: "FootwearTimeGac35Pts", width: 25 },
        { header: "ApparelCompWeight", key: "ApparelCompWeight", width: 25, hidden: true },
        { header: "ApparelCompPts", key: "ApparelCompPts", width: 25, hidden: true },
        { header: "ApparelAccMaterialUtRawScore", key: "ApparelAccMaterialUtRawScore", width: 25, hidden: true },
        { header: "ApparelAccWeight", key: "ApparelAccWeight", width: 25, hidden: true },
        { header: "ApparelAccPts", key: "ApparelAccPts", width: 25, hidden: true },
        { header: "ApparelTimeMissFobRawScore", key: "ApparelTimeMissFobRawScore", width: 25, hidden: true },
        { header: "ApparelTimeWeight", key: "ApparelTimeWeight", width: 2, hidden: true },
        { header: "ApparelTimePts", key: "ApparelTimePts", width: 25, hidden: true },
        { header: "ApparelCompCmpMmRawScore", key: "ApparelCompCmpMmRawScore", width: 25, hidden: true },
        { header: "ApparelCompYarnRawScore", key: "ApparelCompYarnRawScore", width: 25, hidden: true },
        { header: "ApparelAccStitchCtRawScore", key: "ApparelAccStitchCtRawScore", width: 25, hidden: true },
        { header: "EquipmentCompCmpRangeRawScore", key: "EquipmentCompCmpRangeRawScore", width: 25, hidden: true },
        { header: "EquipmentCompWeight", key: "EquipmentCompWeight", width: 25, hidden: true },
        { header: "EquipmentCompPts", key: "EquipmentCompPts", width: 25, hidden: true },
        { header: "EquipmentAccMaterialUtRawScore", key: "EquipmentAccMaterialUtRawScore", width: 25, hidden: true },
        { header: "EquipmentAccPrintRawScore", key: "EquipmentAccPrintRawScore", width: 25, hidden: true },
        { header: "EquipmentAccWeight", key: "EquipmentAccWeight", width: 25, hidden: true },
        { header: "EquipmentAccPts", key: "EquipmentAccPts", width: 25, hidden: true },
        { header: "EquipmentTimeMissFobRawScore", key: "EquipmentTimeMissFobRawScore", width: 25, hidden: true },
        { header: "EquipmentTimeWeight", key: "EquipmentTimeWeight", width: 25, hidden: true },
        { header: "EquipmentTimePts", key: "EquipmentTimePts", width: 25, hidden: true },
        { header: "CostingNonCompliantReason", key: "CostingNonCompliantReason", width: 25 }

    ];

    costingList.map((factory) =>
      ws.addRow([ factory.Id,
                  factory.FactoryGroupName, 
                  factory.FactoryCRCode, 
                  factory.FactoryPOCode, 
                  factory.Brand,
                  factory.ProductEngine,
                  factory.Country,
                  factory.MiCostingScore,
                  factory.FootwearCompRawScore,
                  factory.FootwearCompPts,
                  factory.FootwearAccToolRawScore,
                  factory.FootwearAccToolPts,
                  factory.FootwearAccCbdRejectRawScore,
                  factory.FootwearAccCbdPts,
                  factory.FootwearTimeQuoteRawScore,
                  factory.FootwearTimeQuotePts,
                  factory.FootwearTimeGac35RawScore,
                  factory.FootwearTimeGac35Pts,
                  factory.ApparelCompWeight,
                  factory.ApparelCompPts,
                  factory.ApparelAccMaterialUtRawScore,
                  factory.ApparelAccWeight,
                  factory.ApparelAccPts,
                  factory.ApparelTimeMissFobRawScore,
                  factory.ApparelTimeWeight,
                  factory.ApparelTimePts,
                  factory.ApparelCompCmpMmRawScore,
                  factory.ApparelCompYarnRawScore,
                  factory.ApparelAccStitchCtRawScore,
                  factory.EquipmentCompCmpRangeRawScore,
                  factory.EquipmentCompWeight,
                  factory.EquipmentCompPts,
                  factory.EquipmentAccMaterialUtRawScore,
                  factory.EquipmentAccPrintRawScore,
                  factory.EquipmentAccWeight,
                  factory.EquipmentAccPts,
                  factory.EquipmentTimeMissFobRawScore,
                  factory.EquipmentTimeWeight,
                  factory.EquipmentTimePts,
                  factory.CostingNonCompliantReason
              ]) 
  )
  
  //Format and Lock Header
  const firstRow = ws.getRow(1);
  firstRow.font = { bold: true, size: 12 };

  //Unlock All Cells
  ws.eachRow((row) => {
    row.eachCell({ includeEmpty: true },(cell) => {
        cell.protection = { locked: false };
    });
  });

  //Lock Id Cells
  const idCol = ws.getColumn("Id");
  idCol.eachCell((cell) => {
    cell.protection = { locked: true };
  });
  
  //Format and Lock Factory Group Name Cells
  const factoryNameCol = ws.getColumn("FactoryGroupName");
  factoryNameCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Factory CR Code Cells
  const factoryCRCodeCol = ws.getColumn("FactoryCRCode");
  factoryCRCodeCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

    //Format and Lock Factory PO Code Cells
    const factoryPOCodeCol = ws.getColumn("FactoryPOCode");
    factoryPOCodeCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

  //Format and Lock Brand Cells
  const brandCol = ws.getColumn("Brand");
  brandCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Product Engine Cells
  const productEngineCol = ws.getColumn("ProductEngine");
  productEngineCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Country Cells
  const countryCol = ws.getColumn("Country");
  countryCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock ApparelCompWeight Cells
  const apparelCompWeightCol = ws.getColumn("ApparelCompWeight");
  apparelCompWeightCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelCompPts Cells
  const apparelCompPtsCol = ws.getColumn("ApparelCompPts");
  apparelCompPtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelAccMaterialUtRawScore Cells
  const apparelAccMaterialUtRawScoreCol = ws.getColumn("ApparelAccMaterialUtRawScore");
  apparelAccMaterialUtRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelAccWeight Cells
  const apparelAccWeightCol = ws.getColumn("ApparelAccWeight");
  apparelAccWeightCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelAccPts Cells
  const apparelAccPtsCol = ws.getColumn("ApparelAccPts");
  apparelAccPtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelTimeMissFobRawScore Cells
  const apparelTimeMissFobRawScoreCol = ws.getColumn("ApparelTimeMissFobRawScore");
  apparelTimeMissFobRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelTimeWeight Cells
  const apparelTimeWeightCol = ws.getColumn("ApparelTimeWeight");
  apparelTimeWeightCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelTimePts Cells
  const apparelTimePtsCol = ws.getColumn("ApparelTimePts");
  apparelTimePtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelCompCmpMmRawScore Cells
  const apparelCompCmpMmRawScoreCol = ws.getColumn("ApparelCompCmpMmRawScore");
  apparelCompCmpMmRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelCompYarnRawScore Cells
  const apparelCompYarnRawScoreCol = ws.getColumn("ApparelCompYarnRawScore");
  apparelCompYarnRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelAccStitchCtRawScore Cells
  const apparelAccStitchCtRawScoreCol = ws.getColumn("ApparelAccStitchCtRawScore");
  apparelAccStitchCtRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock EquipmentCompCmpRangeRawScore Cells
  const equipmentCompCmpRangeRawScoreCol = ws.getColumn("EquipmentCompCmpRangeRawScore");
  equipmentCompCmpRangeRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock EquipmentCompWeight Cells
  const equipmentCompWeightCol = ws.getColumn("EquipmentCompWeight");
  equipmentCompWeightCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock EquipmentCompPts Cells
  const equipmentCompPtsCol = ws.getColumn("EquipmentCompPts");
  equipmentCompPtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock EquipmentAccMaterialUtRawScore Cells
  const equipmentAccMaterialUtRawScoreCol = ws.getColumn("EquipmentAccMaterialUtRawScore");
  equipmentAccMaterialUtRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock EquipmentAccPrintRawScore Cells
  const equipmentAccPrintRawScoreCol = ws.getColumn("EquipmentAccPrintRawScore");
  equipmentAccPrintRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock EquipmentAccWeight Cells
  const equipmentAccWeightCol = ws.getColumn("EquipmentAccWeight");
  equipmentAccWeightCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock EquipmentAccPts Cells
  const equipmentAccPtsCol = ws.getColumn("EquipmentAccPts");
  equipmentAccPtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock EquipmentTimeMissFobRawScore Cells
  const equipmentTimeMissFobRawScoreCol = ws.getColumn("EquipmentTimeMissFobRawScore");
  equipmentTimeMissFobRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock EquipmentTimeWeight Cells
  const equipmentTimeWeightCol = ws.getColumn("EquipmentTimeWeight");
  equipmentTimeWeightCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock EquipmentTimePts Cells
  const equipmentTimePtsCol = ws.getColumn("EquipmentTimePts");
  equipmentTimePtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });



  const buf = await wb.xlsx.writeBuffer();

  saveAs(new Blob([buf]), fileName("Costing", brand, productEngine, country) + ".xlsx");
}

//Save Equipment
export const saveEquipment = async (brand, productEngine, country, costingList) => {

  const wb = new ExcelJS.Workbook();

  const ws = wb.addWorksheet(fileName("Costing", brand, productEngine, country), {views:[{state: "frozen", xSplit: 7, ySplit:1}]})

  ws.protect("Costing", protect());

  ws.columns = [
    { header: "Id", key: "Id", width: 10, hidden: true },
    { header: "FactoryGroupName", key: "FactoryGroupName", width: 20 },
    { header: "FactoryCRCode", key: "FactoryCRCode", width: 15 },
    { header: "FactoryPOCode", key: "FactoryPOCode", width: 15 },
    { header: "Brand", key: "Brand", width: 10 },
    { header: "ProductEngine", key: "ProductEngine", width: 15 },
    { header: "Country", key: "Country", width: 20 },
    { header: "MiCostingScore", key: "MiCostingScore", width: 25 },
    { header: "FootwearCompRawScore", key: "FootwearCompRawScore", width: 25, hidden: true },
    { header: "FootwearCompPts", key: "FootwearCompPts", width: 25, hidden: true },
    { header: "FootwearAccToolRawScore", key: "FootwearAccToolRawScore", width: 25, hidden: true },
    { header: "FootwearAccToolPts", key: "FootwearAccToolPts", width: 25, hidden: true },
    { header: "FootwearAccCbdRejectRawScore", key: "FootwearAccCbdRejectRawScore", width: 25, hidden: true },
    { header: "FootwearAccCbdPts", key: "FootwearAccCbdPts", width: 25, hidden: true },
    { header: "FootwearTimeQuoteRawScore", key: "FootwearTimeQuoteRawScore", width: 25, hidden: true },
    { header: "FootwearTimeQuotePts", key: "FootwearTimeQuotePts", width: 25, hidden: true },
    { header: "FootwearTimeGac35RawScore", key: "FootwearTimeGac35RawScore", width: 25, hidden: true },
    { header: "FootwearTimeGac35Pts", key: "FootwearTimeGac35Pts", width: 25, hidden: true },
    { header: "ApparelCompWeight", key: "ApparelCompWeight", width: 25, hidden: true },
    { header: "ApparelCompPts", key: "ApparelCompPts", width: 25, hidden: true },
    { header: "ApparelAccMaterialUtRawScore", key: "ApparelAccMaterialUtRawScore", width: 25, hidden: true },
    { header: "ApparelAccWeight", key: "ApparelAccWeight", width: 25, hidden: true },
    { header: "ApparelAccPts", key: "ApparelAccPts", width: 25, hidden: true },
    { header: "ApparelTimeMissFobRawScore", key: "ApparelTimeMissFobRawScore", width: 25, hidden: true },
    { header: "ApparelTimeWeight", key: "ApparelTimeWeight", width: 25, hidden: true },
    { header: "ApparelTimePts", key: "ApparelTimePts", width: 25, hidden: true },
    { header: "ApparelCompCmpMmRawScore", key: "ApparelCompCmpMmRawScore", width: 25, hidden: true },
    { header: "ApparelCompYarnRawScore", key: "ApparelCompYarnRawScore", width: 25, hidden: true },
    { header: "ApparelAccStitchCtRawScore", key: "ApparelAccStitchCtRawScore", width: 25, hidden: true },
    { header: "EquipmentCompCmpRangeRawScore", key: "EquipmentCompCmpRangeRawScore", width: 25 },
    { header: "EquipmentCompWeight", key: "EquipmentCompWeight", width: 25 },
    { header: "EquipmentCompPts", key: "EquipmentCompPts", width: 25 },
    { header: "EquipmentAccMaterialUtRawScore", key: "EquipmentAccMaterialUtRawScore", width: 25 },
    { header: "EquipmentAccPrintRawScore", key: "EquipmentAccPrintRawScore", width: 25 },
    { header: "EquipmentAccWeight", key: "EquipmentAccWeight", width: 25 },
    { header: "EquipmentAccPts", key: "EquipmentAccPts", width: 25 },
    { header: "EquipmentTimeMissFobRawScore", key: "EquipmentTimeMissFobRawScore", width: 25 },
    { header: "EquipmentTimeWeight", key: "EquipmentTimeWeight", width: 25 },
    { header: "EquipmentTimePts", key: "EquipmentTimePts", width: 25 },
    { header: "CostingNonCompliantReason", key: "CostingNonCompliantReason", width: 25 }

    ];

    costingList.map((factory) =>
      ws.addRow([ factory.Id,
        factory.FactoryGroupName, 
        factory.FactoryCRCode, 
        factory.FactoryPOCode, 
        factory.Brand,
        factory.ProductEngine,
        factory.Country,
        factory.MiCostingScore,
        factory.FootwearCompRawScore,
        factory.FootwearCompPts,
        factory.FootwearAccToolRawScore,
        factory.FootwearAccToolPts,
        factory.FootwearAccCbdRejectRawScore,
        factory.FootwearAccCbdPts,
        factory.FootwearTimeQuoteRawScore,
        factory.FootwearTimeQuotePts,
        factory.FootwearTimeGac35RawScore,
        factory.FootwearTimeGac35Pts,
        factory.ApparelCompWeight,
        factory.ApparelCompPts,
        factory.ApparelAccMaterialUtRawScore,
        factory.ApparelAccWeight,
        factory.ApparelAccPts,
        factory.ApparelTimeMissFobRawScore,
        factory.ApparelTimeWeight,
        factory.ApparelTimePts,
        factory.ApparelCompCmpMmRawScore,
        factory.ApparelCompYarnRawScore,
        factory.ApparelAccStitchCtRawScore,
        factory.EquipmentCompCmpRangeRawScore,
        factory.EquipmentCompWeight,
        factory.EquipmentCompPts,
        factory.EquipmentAccMaterialUtRawScore,
        factory.EquipmentAccPrintRawScore,
        factory.EquipmentAccWeight,
        factory.EquipmentAccPts,
        factory.EquipmentTimeMissFobRawScore,
        factory.EquipmentTimeWeight,
        factory.EquipmentTimePts,
        factory.CostingNonCompliantReason

      ]) 
  )
  
  //Format and Lock Header
  const firstRow = ws.getRow(1);
  firstRow.font = { bold: true, size: 12 };

  //Unlock All Cells
  ws.eachRow((row) => {
    row.eachCell({ includeEmpty: true },(cell) => {
        cell.protection = { locked: false };
    });
  });

  //Lock Id Cells
  const idCol = ws.getColumn("Id");
  idCol.eachCell((cell) => {
    cell.protection = { locked: true };
  });
  
  //Format and Lock Factory Group Name Cells
  const factoryNameCol = ws.getColumn("FactoryGroupName");
  factoryNameCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Factory CR Code Cells
  const factoryCRCodeCol = ws.getColumn("FactoryCRCode");
  factoryCRCodeCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

    //Format and Lock Factory PO Code Cells
    const factoryPOCodeCol = ws.getColumn("FactoryPOCode");
    factoryPOCodeCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

  //Format and Lock Brand Cells
  const brandCol = ws.getColumn("Brand");
  brandCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Product Engine Cells
  const productEngineCol = ws.getColumn("ProductEngine");
  productEngineCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Country Cells
  const countryCol = ws.getColumn("Country");
  countryCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock FootwearCompRawScore Cells
  const footwearCompRawScoreCol = ws.getColumn("FootwearCompRawScore");
  footwearCompRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock FootwearCompPts Cells
  const footwearCompPtsCol = ws.getColumn("FootwearCompPts");
  footwearCompPtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock FootwearAccToolRawScore Cells
  const footwearAccToolRawScoreCol = ws.getColumn("FootwearAccToolRawScore");
  footwearAccToolRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock FootwearAccToolPts Cells
  const footwearAccToolPtsCol = ws.getColumn("FootwearAccToolPts");
  footwearAccToolPtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock FootwearAccCbdRejectRawScore Cells
  const footwearAccCbdRejectRawScoreCol = ws.getColumn("FootwearAccCbdRejectRawScore");
  footwearAccCbdRejectRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock FootwearAccCbdPts Cells
  const footwearAccCbdPtsCol = ws.getColumn("FootwearAccCbdPts");
  footwearAccCbdPtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock FootwearTimeQuoteRawScore Cells
  const footwearTimeQuoteRawScoreCol = ws.getColumn("FootwearTimeQuoteRawScore");
  footwearTimeQuoteRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock FootwearTimeQuotePts Cells
  const footwearTimeQuotePtsCol = ws.getColumn("FootwearTimeQuotePts");
  footwearTimeQuotePtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock FootwearTimeGac35RawScore Cells
  const footwearTimeGac35RawScoreCol = ws.getColumn("FootwearTimeGac35RawScore");
  footwearTimeGac35RawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock FootwearTimeGac35Pts Cells
  const footwearTimeGac35PtsCol = ws.getColumn("FootwearTimeGac35Pts");
  footwearTimeGac35PtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelCompWeight Cells
  const apparelCompWeightCol = ws.getColumn("ApparelCompWeight");
  apparelCompWeightCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelCompPts Cells
  const apparelCompPtsCol = ws.getColumn("ApparelCompPts");
  apparelCompPtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelAccMaterialUtRawScore Cells
  const apparelAccMaterialUtRawScoreCol = ws.getColumn("ApparelAccMaterialUtRawScore");
  apparelAccMaterialUtRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelAccWeight Cells
  const apparelAccWeightCol = ws.getColumn("ApparelAccWeight");
  apparelAccWeightCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelAccPts Cells
  const apparelAccPtsCol = ws.getColumn("ApparelAccPts");
  apparelAccPtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelTimeMissFobRawScore Cells
  const apparelTimeMissFobRawScoreCol = ws.getColumn("ApparelTimeMissFobRawScore");
  apparelTimeMissFobRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelTimeWeight Cells
  const apparelTimeWeightCol = ws.getColumn("ApparelTimeWeight");
  apparelTimeWeightCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelTimePts Cells
  const apparelTimePtsCol = ws.getColumn("ApparelTimePts");
  apparelTimePtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelCompCmpMmRawScore Cells
  const apparelCompCmpMmRawScoreCol = ws.getColumn("ApparelCompCmpMmRawScore");
  apparelCompCmpMmRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelCompYarnRawScore Cells
  const apparelCompYarnRawScoreCol = ws.getColumn("ApparelCompYarnRawScore");
  apparelCompYarnRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelAccStitchCtRawScore Cells
  const apparelAccStitchCtRawScoreCol = ws.getColumn("ApparelAccStitchCtRawScore");
  apparelAccStitchCtRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  const buf = await wb.xlsx.writeBuffer();

  saveAs(new Blob([buf]), fileName("Costing", brand, productEngine, country) + ".xlsx");
}