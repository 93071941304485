import React, { Component } from "react";
import { FaUpload } from "react-icons/fa";
import ModalTemplateUpload from "../components/ModalTemplateUpload";

class CardTemplateUpload extends Component {

    render() {

        return (

            <div class="card border-white">
              <div class="card-body">
                <h6 class="card-title">
                    <FaUpload />&nbsp;Manufacturing Index Template Upload
                </h6>
                <div class="border-top my-2"></div>
                <p class="card-text">
                Upload the manufacturing index template file. The same file 
                can be uploaded multiple times but the previously uploaded data 
                will not be overwritten.
                </p>
                 <button class="btn  btn-outline-primary btn-sm" role="button" data-toggle="modal" data-target="#upload">
                    Upload
                </button>
                <br />
              </div>

              <ModalTemplateUpload template={this.props.template} />
              
            </div>
            
        );
    };
};

export default CardTemplateUpload;