import ExcelJS from "exceljs/dist/es5/exceljs.browser.js";
import { saveAs } from "file-saver";
import { protect, fill, border, fileName } from "./UtilExcel";

//Save All
export const saveAll = async (brand, productEngine, country, qualityList) => {

    const wb = new ExcelJS.Workbook();

    const ws = wb.addWorksheet(fileName("Quality", brand, productEngine, country), {views:[{state: "frozen", xSplit: 7, ySplit:1}]})

    ws.protect("Quality", protect());

    ws.columns = [
        { header: "Id", key: "Id", width: 10, hidden: true },
        { header: "FactoryGroupName", key: "FactoryGroupName", width: 20 },
        { header: "FactoryCRCode", key: "FactoryCRCode", width: 15 },
        { header: "FactoryPOCode", key: "FactoryPOCode", width: 15 },
        { header: "Brand", key: "Brand", width: 10 },
        { header: "ProductEngine", key: "ProductEngine", width: 15 },
        { header: "Country", key: "Country", width: 20 },
        { header: "MiQualityScore", key: "MiQualityScore", width: 25 },
        { header: "ProductSafetyYes", key: "ProductSafetyYes", width: 25 },
        { header: "ProductSafetyReason", key: "ProductSafetyReason", width: 25 },
        { header: "DefectiveRateRawScore", key: "DefectiveRateRawScore", width: 25 },
        { header: "DefectiveRatePts", key: "DefectiveRatePts", width: 25 },
        { header: "MiQualityScoreAdjust", key: "MiQualityScoreAdjust", width: 25 },
        { header: "FootwearCopqRawScore", key: "FootwearCopqRawScore", width: 25 },
        { header: "FootwearCopqPts", key: "FootwearCopqPts", width: 25 },
        { header: "FootwearBondTestRawScore", key: "FootwearBondTestRawScore", width: 25 },
        { header: "FootwearBondTestPts", key: "FootwearBondTestPts", width: 25 },
        { header: "FootwearConverseFlexTestRawScore", key: "FootwearConverseFlexTestRawScore", width: 25 },
        { header: "FootwearConverseFlexTestPts", key: "FootwearConverseFlexTestPts", width: 25 },
        { header: "FootwearConverseMqaaRawScore", key: "FootwearConverseMqaaRawScore", width: 25 },
        { header: "FootwearConverseMqaaPts", key: "FootwearConverseMqaaPts", width: 25 },
        { header: "ApparelIssuesNbrRawScore", key: "ApparelIssuesNbrRawScore", width: 25 },
        { header: "ApparelIssuesNbrPts", key: "ApparelIssuesNbrPts", width: 25 },
        { header: "ApparelIssuesValueRawScore", key: "ApparelIssuesValueRawScore", width: 25 },
        { header: "ApparelIssuesValuePts", key: "ApparelIssuesValuePts", width: 25 },
        { header: "EquipmentIssuesNbrRawScore", key: "EquipmentIssuesNbrRawScore", width: 25 },
        { header: "EquipmentIssuesNbrPts", key: "EquipmentIssuesNbrPts", width: 25 },
        { header: "EquipmentIssuesValueRawScore", key: "EquipmentIssuesValueRawScore", width: 25 },
        { header: "EquipmentIssuesValuePts", key: "EquipmentIssuesValuePts", width: 25 },
        { header: "EquipmentBondTestPts", key: "EquipmentBondTestPts", width: 25 },
        { header: "QualityNonCompliantReason", key: "QualityNonCompliantReason", width: 25 }
      ];

      qualityList.map((factory) =>
        ws.addRow([ factory.Id,
                    factory.FactoryGroupName, 
                    factory.FactoryCRCode,
                    factory.FactoryPOCode,  
                    factory.Brand,
                    factory.ProductEngine,
                    factory.Country,
                    factory.MiQualityScore,
                    factory.ProductSafetyYes,
                    factory.ProductSafetyReason,
                    factory.DefectiveRateRawScore,
                    factory.DefectiveRatePts,
                    factory.MiQualityScoreAdjust,
                    factory.FootwearCopqRawScore,
                    factory.FootwearCopqPts,
                    factory.FootwearBondTestRawScore,
                    factory.FootwearBondTestPts,
                    factory.FootwearConverseFlexTestRawScore,
                    factory.FootwearConverseFlexTestPts,
                    factory.FootwearConverseMqaaRawScore,
                    factory.FootwearConverseMqaaPts,
                    factory.ApparelIssuesNbrRawScore,
                    factory.ApparelIssuesNbrPts,
                    factory.ApparelIssuesValueRawScore,
                    factory.ApparelIssuesValuePts,
                    factory.EquipmentIssuesNbrRawScore,
                    factory.EquipmentIssuesNbrPts,
                    factory.EquipmentIssuesValueRawScore,
                    factory.EquipmentIssuesValuePts,
                    factory.EquipmentBondTestPts,
                    factory.QualityNonCompliantReason

                ]) 
    )
    
    //Format and Lock Header
    const firstRow = ws.getRow(1);
    firstRow.font = { bold: true, size: 12 };

    //Unlock All Cells
    ws.eachRow((row) => {
      row.eachCell({ includeEmpty: true },(cell) => {
          cell.protection = { locked: false };
      });
    });

    //Lock Id Cells
    const idCol = ws.getColumn("Id");
    idCol.eachCell((cell) => {
      cell.protection = { locked: true };
    });
    
    //Format and Lock Factory Group Name Cells
    const factoryNameCol = ws.getColumn("FactoryGroupName");
    factoryNameCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Factory CR Code Cells
    const factoryCRCodeCol = ws.getColumn("FactoryCRCode");
    factoryCRCodeCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

     //Format and Lock Factory PO Code Cells
     const factoryPOCodeCol = ws.getColumn("FactoryPOCode");
     factoryPOCodeCol.eachCell((cell) => {
       cell.protection = { locked: true };
       cell.fill = fill();
       cell.border = border();
     });

    //Format and Lock Brand Cells
    const brandCol = ws.getColumn("Brand");
    brandCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Product Engine Cells
    const productEngineCol = ws.getColumn("ProductEngine");
    productEngineCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Country Cells
    const countryCol = ws.getColumn("Country");
    countryCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    const buf = await wb.xlsx.writeBuffer();

    saveAs(new Blob([buf]), fileName("Quality", brand, productEngine, country) + ".xlsx");
  }

  //Save Apparel
  export const saveApparel = async (brand, productEngine, country, qualityList) => {

    const wb = new ExcelJS.Workbook();

    const ws = wb.addWorksheet(fileName("Quality", brand, productEngine, country), {views:[{state: "frozen", xSplit: 7, ySplit:1}]})

    ws.protect("Quality", protect());

    ws.columns = [
      { header: "Id", key: "Id", width: 10, hidden: true },
      { header: "FactoryGroupName", key: "FactoryGroupName", width: 20 },
      { header: "FactoryCRCode", key: "FactoryCRCode", width: 15 },
      { header: "FactoryPOCode", key: "FactoryPOCode", width: 15 },
      { header: "Brand", key: "Brand", width: 10 },
      { header: "ProductEngine", key: "ProductEngine", width: 15 },
      { header: "Country", key: "Country", width: 20 },
      { header: "MiQualityScore", key: "MiQualityScore", width: 25 },
      { header: "ProductSafetyYes", key: "ProductSafetyYes", width: 25 },
      { header: "ProductSafetyReason", key: "ProductSafetyReason", width: 25 },
      { header: "DefectiveRateRawScore", key: "DefectiveRateRawScore", width: 25 },
      { header: "DefectiveRatePts", key: "DefectiveRatePts", width: 25 },
      { header: "MiQualityScoreAdjust", key: "MiQualityScoreAdjust", width: 25 },
      { header: "FootwearCopqRawScore", key: "FootwearCopqRawScore", width: 25, hidden: true },
      { header: "FootwearCopqPts", key: "FootwearCopqPts", width: 25, hidden: true },
      { header: "FootwearBondTestRawScore", key: "FootwearBondTestRawScore", width: 25, hidden: true },
      { header: "FootwearBondTestPts", key: "FootwearBondTestPts", width: 25, hidden: true },
      { header: "FootwearConverseFlexTestRawScore", key: "FootwearConverseFlexTestRawScore", width: 25, hidden: true },
      { header: "FootwearConverseFlexTestPts", key: "FootwearConverseFlexTestPts", width: 25, hidden: true },
      { header: "FootwearConverseMqaaRawScore", key: "FootwearConverseMqaaRawScore", width: 25, hidden: true },
      { header: "FootwearConverseMqaaPts", key: "FootwearConverseMqaaPts", width: 25, hidden: true },
      { header: "ApparelIssuesNbrRawScore", key: "ApparelIssuesNbrRawScore", width: 25 },
      { header: "ApparelIssuesNbrPts", key: "ApparelIssuesNbrPts", width: 25 },
      { header: "ApparelIssuesValueRawScore", key: "ApparelIssuesValueRawScore", width: 25 },
      { header: "ApparelIssuesValuePts", key: "ApparelIssuesValuePts", width: 25 },
      { header: "EquipmentIssuesNbrRawScore", key: "EquipmentIssuesNbrRawScore", width: 25, hidden: true },
      { header: "EquipmentIssuesNbrPts", key: "EquipmentIssuesNbrPts", width: 25, hidden: true },
      { header: "EquipmentIssuesValueRawScore", key: "EquipmentIssuesValueRawScore", width: 25, hidden: true },
      { header: "EquipmentIssuesValuePts", key: "EquipmentIssuesValuePts", width: 25, hidden: true },
      { header: "EquipmentBondTestPts", key: "EquipmentBondTestPts", width: 25, hidden: true },
      { header: "QualityNonCompliantReason", key: "QualityNonCompliantReason", width: 25 }
    ];

    qualityList.map((factory) =>
      ws.addRow([ factory.Id,
                  factory.FactoryGroupName, 
                  factory.FactoryCRCode,
                  factory.FactoryPOCode, 
                  factory.Brand,
                  factory.ProductEngine,
                  factory.Country,
                  factory.MiQualityScore,
                  factory.ProductSafetyYes,
                  factory.ProductSafetyReason,
                  factory.DefectiveRateRawScore,
                  factory.DefectiveRatePts,
                  factory.MiQualityScoreAdjust,
                  factory.FootwearCopqRawScore,
                  factory.FootwearCopqPts,
                  factory.FootwearBondTestRawScore,
                  factory.FootwearBondTestPts,
                  factory.FootwearConverseFlexTestRawScore,
                  factory.FootwearConverseFlexTestPts,
                  factory.FootwearConverseMqaaRawScore,
                  factory.FootwearConverseMqaaPts,
                  factory.ApparelIssuesNbrRawScore,
                  factory.ApparelIssuesNbrPts,
                  factory.ApparelIssuesValueRawScore,
                  factory.ApparelIssuesValuePts,
                  factory.EquipmentIssuesNbrRawScore,
                  factory.EquipmentIssuesNbrPts,
                  factory.EquipmentIssuesValueRawScore,
                  factory.EquipmentIssuesValuePts,
                  factory.EquipmentBondTestPts,
                  factory.QualityNonCompliantReason

              ]) 
    )
    
    //Format and Lock Header
    const firstRow = ws.getRow(1);
    firstRow.font = { bold: true, size: 12 };

    //Unlock All Cells
    ws.eachRow((row) => {
      row.eachCell({ includeEmpty: true },(cell) => {
          cell.protection = { locked: false };
      });
    });

    //Lock Id Cells
    const idCol = ws.getColumn("Id");
    idCol.eachCell((cell) => {
      cell.protection = { locked: true };
    });
    
    //Format and Lock Factory Group Name Cells
    const factoryNameCol = ws.getColumn("FactoryGroupName");
    factoryNameCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Factory CR Code Cells
    const factoryCRCodeCol = ws.getColumn("FactoryCRCode");
    factoryCRCodeCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Factory PO Code Cells
    const factoryPOCodeCol = ws.getColumn("FactoryPOCode");
    factoryPOCodeCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Brand Cells
    const brandCol = ws.getColumn("Brand");
    brandCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Product Engine Cells
    const productEngineCol = ws.getColumn("ProductEngine");
    productEngineCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Country Cells
    const countryCol = ws.getColumn("Country");
    countryCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock FootwearCopqRawScore Cells
    const footwearCopqRawScoreCol = ws.getColumn("FootwearCopqRawScore");
    footwearCopqRawScoreCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock FootwearCopqPts Cells
    const footwearCopqPtsCol = ws.getColumn("FootwearCopqPts");
    footwearCopqPtsCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock FootwearBondTestRawScore Cells
    const footwearBondTestRawScoreCol = ws.getColumn("FootwearBondTestRawScore");
    footwearBondTestRawScoreCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock FootwearBondTestPts Cells
    const footwearBondTestPtsCol = ws.getColumn("FootwearBondTestPts");
    footwearBondTestPtsCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock FootwearConverseFlexTestRawScore Cells
    const footwearConverseFlexTestRawScoreCol = ws.getColumn("FootwearConverseFlexTestRawScore");
    footwearConverseFlexTestRawScoreCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock FootwearConverseFlexTestPts Cells
    const footwearConverseFlexTestPtsCol = ws.getColumn("FootwearConverseFlexTestPts");
    footwearConverseFlexTestPtsCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock FootwearConverseMqaaRawScore Cells
    const footwearConverseMqaaRawScoreCol = ws.getColumn("FootwearConverseMqaaRawScore");
    footwearConverseMqaaRawScoreCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock FootwearConverseMqaaPts Cells
    const footwearConverseMqaaPtsCol = ws.getColumn("FootwearConverseMqaaPts");
    footwearConverseMqaaPtsCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock EquipmentIssuesNbrRawScore Cells
    const equipmentIssuesNbrRawScoreCol = ws.getColumn("EquipmentIssuesNbrRawScore");
    equipmentIssuesNbrRawScoreCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock EquipmentIssuesNbrPts Cells
    const equipmentIssuesNbrPtsCol = ws.getColumn("EquipmentIssuesNbrPts");
    equipmentIssuesNbrPtsCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock EquipmentIssuesValueRawScore Cells
    const equipmentIssuesValueRawScoreCol = ws.getColumn("EquipmentIssuesValueRawScore");
    equipmentIssuesValueRawScoreCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock EquipmentIssuesValuePts Cells
    const equipmentIssuesValuePtsCol = ws.getColumn("EquipmentIssuesValuePts");
    equipmentIssuesValuePtsCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    //Format and Lock EquipmentBondTestPts Cells
    const equipmentBondTestPtsCol = ws.getColumn("EquipmentBondTestPts");
    equipmentBondTestPtsCol.eachCell((cell) => {
        cell.protection = { locked: true };
        cell.fill = fill();
        cell.border = border();
    });

    const buf = await wb.xlsx.writeBuffer();

    saveAs(new Blob([buf]), fileName("Quality", brand, productEngine, country) + ".xlsx");
  }


  //Save Footwear
export const saveFootwear = async (brand, productEngine, country, qualityList) => {

  const wb = new ExcelJS.Workbook();

  const ws = wb.addWorksheet(fileName("Quality", brand, productEngine, country), {views:[{state: "frozen", xSplit: 7, ySplit:1}]})

  ws.protect("Quality", protect());

  ws.columns = [
    { header: "Id", key: "Id", width: 10, hidden: true },
    { header: "FactoryGroupName", key: "FactoryGroupName", width: 20 },
    { header: "FactoryCRCode", key: "FactoryCRCode", width: 15 },
    { header: "FactoryPOCode", key: "FactoryPOCode", width: 15 },
    { header: "Brand", key: "Brand", width: 10 },
    { header: "ProductEngine", key: "ProductEngine", width: 15 },
    { header: "Country", key: "Country", width: 20 },
    { header: "MiQualityScore", key: "MiQualityScore", width: 25 },
    { header: "ProductSafetyYes", key: "ProductSafetyYes", width: 25 },
    { header: "ProductSafetyReason", key: "ProductSafetyReason", width: 25 },
    { header: "DefectiveRateRawScore", key: "DefectiveRateRawScore", width: 25 },
    { header: "DefectiveRatePts", key: "DefectiveRatePts", width: 25 },
    { header: "MiQualityScoreAdjust", key: "MiQualityScoreAdjust", width: 25 },
    { header: "FootwearCopqRawScore", key: "FootwearCopqRawScore", width: 25 },
    { header: "FootwearCopqPts", key: "FootwearCopqPts", width: 25 },
    { header: "FootwearBondTestRawScore", key: "FootwearBondTestRawScore", width: 25 },
    { header: "FootwearBondTestPts", key: "FootwearBondTestPts", width: 25 },
    { header: "FootwearConverseFlexTestRawScore", key: "FootwearConverseFlexTestRawScore", width: 25 },
    { header: "FootwearConverseFlexTestPts", key: "FootwearConverseFlexTestPts", width: 25 },
    { header: "FootwearConverseMqaaRawScore", key: "FootwearConverseMqaaRawScore", width: 25 },
    { header: "FootwearConverseMqaaPts", key: "FootwearConverseMqaaPts", width: 25 },
    { header: "ApparelIssuesNbrRawScore", key: "ApparelIssuesNbrRawScore", width: 25, hidden: true },
    { header: "ApparelIssuesNbrPts", key: "ApparelIssuesNbrPts", width: 25, hidden: true },
    { header: "ApparelIssuesValueRawScore", key: "ApparelIssuesValueRawScore", width: 25, hidden: true },
    { header: "ApparelIssuesValuePts", key: "ApparelIssuesValuePts", width: 25, hidden: true },
    { header: "EquipmentIssuesNbrRawScore", key: "EquipmentIssuesNbrRawScore", width: 25, hidden: true },
    { header: "EquipmentIssuesNbrPts", key: "EquipmentIssuesNbrPts", width: 25, hidden: true },
    { header: "EquipmentIssuesValueRawScore", key: "EquipmentIssuesValueRawScore", width: 25, hidden: true },
    { header: "EquipmentIssuesValuePts", key: "EquipmentIssuesValuePts", width: 25, hidden: true },
    { header: "EquipmentBondTestPts", key: "EquipmentBondTestPts", width: 25, hidden: true },
    { header: "QualityNonCompliantReason", key: "QualityNonCompliantReason", width: 25 }
  ];

  qualityList.map((factory) =>
    ws.addRow([ factory.Id,
                factory.FactoryGroupName, 
                factory.FactoryCRCode, 
                factory.FactoryPOCode, 
                factory.Brand,
                factory.ProductEngine,
                factory.Country,
                factory.MiQualityScore,
                factory.ProductSafetyYes,
                factory.ProductSafetyReason,
                factory.DefectiveRateRawScore,
                factory.DefectiveRatePts,
                factory.MiQualityScoreAdjust,
                factory.FootwearCopqRawScore,
                factory.FootwearCopqPts,
                factory.FootwearBondTestRawScore,
                factory.FootwearBondTestPts,
                factory.FootwearConverseFlexTestRawScore,
                factory.FootwearConverseFlexTestPts,
                factory.FootwearConverseMqaaRawScore,
                factory.FootwearConverseMqaaPts,
                factory.ApparelIssuesNbrRawScore,
                factory.ApparelIssuesNbrPts,
                factory.ApparelIssuesValueRawScore,
                factory.ApparelIssuesValuePts,
                factory.EquipmentIssuesNbrRawScore,
                factory.EquipmentIssuesNbrPts,
                factory.EquipmentIssuesValueRawScore,
                factory.EquipmentIssuesValuePts,
                factory.EquipmentBondTestPts,
                factory.QualityNonCompliantReason

            ]) 
  )
  
  //Format and Lock Header
  const firstRow = ws.getRow(1);
  firstRow.font = { bold: true, size: 12 };

  //Unlock All Cells
  ws.eachRow((row) => {
    row.eachCell({ includeEmpty: true },(cell) => {
        cell.protection = { locked: false };
    });
  });

  //Lock Id Cells
  const idCol = ws.getColumn("Id");
  idCol.eachCell((cell) => {
    cell.protection = { locked: true };
  });
  
  //Format and Lock Factory Group Name Cells
  const factoryNameCol = ws.getColumn("FactoryGroupName");
  factoryNameCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Factory CR Code Cells
  const factoryCRCodeCol = ws.getColumn("FactoryCRCode");
  factoryCRCodeCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Factory PO Code Cells
  const factoryPOCodeCol = ws.getColumn("FactoryPOCode");
  factoryPOCodeCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Brand Cells
  const brandCol = ws.getColumn("Brand");
  brandCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Product Engine Cells
  const productEngineCol = ws.getColumn("ProductEngine");
  productEngineCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Country Cells
  const countryCol = ws.getColumn("Country");
  countryCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock ApparelIssuesNbrRawScore Cells
  const apparelIssuesNbrRawScoreCol = ws.getColumn("ApparelIssuesNbrRawScore");
  apparelIssuesNbrRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelIssuesNbrPts Cells
  const apparelIssuesNbrPtsCol = ws.getColumn("ApparelIssuesNbrPts");
  apparelIssuesNbrPtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelIssuesValueRawScore Cells
  const apparelIssuesValueRawScoreCol = ws.getColumn("ApparelIssuesValueRawScore");
  apparelIssuesValueRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelIssuesValuePts Cells
  const apparelIssuesValuePtsCol = ws.getColumn("ApparelIssuesValuePts");
  apparelIssuesValuePtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock EquipmentIssuesNbrRawScore Cells
  const equipmentIssuesNbrRawScoreCol = ws.getColumn("EquipmentIssuesNbrRawScore");
  equipmentIssuesNbrRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock EquipmentIssuesNbrPts Cells
  const equipmentIssuesNbrPtsCol = ws.getColumn("EquipmentIssuesNbrPts");
  equipmentIssuesNbrPtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock EquipmentIssuesValueRawScore Cells
  const equipmentIssuesValueRawScoreCol = ws.getColumn("EquipmentIssuesValueRawScore");
  equipmentIssuesValueRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock EquipmentIssuesValuePts Cells
  const equipmentIssuesValuePtsCol = ws.getColumn("EquipmentIssuesValuePts");
  equipmentIssuesValuePtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock EquipmentBondTestPts Cells
  const equipmentBondTestPtsCol = ws.getColumn("EquipmentBondTestPts");
  equipmentBondTestPtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  const buf = await wb.xlsx.writeBuffer();

  saveAs(new Blob([buf]), fileName("Quality", brand, productEngine, country) + ".xlsx");
}

//Save Equipment
export const saveEquipment = async (brand, productEngine, country, qualityList) => {

  const wb = new ExcelJS.Workbook();

  const ws = wb.addWorksheet(fileName("Quality", brand, productEngine, country), {views:[{state: "frozen", xSplit: 7, ySplit:1}]})

  ws.protect("Quality", protect());

  ws.columns = [
    { header: "Id", key: "Id", width: 10, hidden: true },
    { header: "FactoryGroupName", key: "FactoryGroupName", width: 20 },
    { header: "FactoryCRCode", key: "FactoryCRCode", width: 15 },
    { header: "FactoryPOCode", key: "FactoryPOCode", width: 15 },
    { header: "Brand", key: "Brand", width: 10 },
    { header: "ProductEngine", key: "ProductEngine", width: 15 },
    { header: "Country", key: "Country", width: 20 },
    { header: "MiQualityScore", key: "MiQualityScore", width: 25 },
    { header: "ProductSafetyYes", key: "ProductSafetyYes", width: 25 },
    { header: "ProductSafetyReason", key: "ProductSafetyReason", width: 25 },
    { header: "DefectiveRateRawScore", key: "DefectiveRateRawScore", width: 25 },
    { header: "DefectiveRatePts", key: "DefectiveRatePts", width: 25 },
    { header: "MiQualityScoreAdjust", key: "MiQualityScoreAdjust", width: 25 },
    { header: "FootwearCopqRawScore", key: "FootwearCopqRawScore", width: 25, hidden: true },
    { header: "FootwearCopqPts", key: "FootwearCopqPts", width: 25, hidden: true },
    { header: "FootwearBondTestRawScore", key: "FootwearBondTestRawScore", width: 25, hidden: true },
    { header: "FootwearBondTestPts", key: "FootwearBondTestPts", width: 25, hidden: true },
    { header: "FootwearConverseFlexTestRawScore", key: "FootwearConverseFlexTestRawScore", width: 25, hidden: true },
    { header: "FootwearConverseFlexTestPts", key: "FootwearConverseFlexTestPts", width: 25, hidden: true },
    { header: "FootwearConverseMqaaRawScore", key: "FootwearConverseMqaaRawScore", width: 25, hidden: true },
    { header: "FootwearConverseMqaaPts", key: "FootwearConverseMqaaPts", width: 25, hidden: true },
    { header: "ApparelIssuesNbrRawScore", key: "ApparelIssuesNbrRawScore", width: 25, hidden: true },
    { header: "ApparelIssuesNbrPts", key: "ApparelIssuesNbrPts", width: 25, hidden: true },
    { header: "ApparelIssuesValueRawScore", key: "ApparelIssuesValueRawScore", width: 25, hidden: true },
    { header: "ApparelIssuesValuePts", key: "ApparelIssuesValuePts", width: 25, hidden: true },
    { header: "EquipmentIssuesNbrRawScore", key: "EquipmentIssuesNbrRawScore", width: 25 },
    { header: "EquipmentIssuesNbrPts", key: "EquipmentIssuesNbrPts", width: 25 },
    { header: "EquipmentIssuesValueRawScore", key: "EquipmentIssuesValueRawScore", width: 25 },
    { header: "EquipmentIssuesValuePts", key: "EquipmentIssuesValuePts", width: 25 },
    { header: "EquipmentBondTestPts", key: "EquipmentBondTestPts", width: 25 },
    { header: "QualityNonCompliantReason", key: "QualityNonCompliantReason", width: 25 }
  ];

  qualityList.map((factory) =>
    ws.addRow([ factory.Id,
                factory.FactoryGroupName, 
                factory.FactoryCRCode, 
                factory.FactoryPOCode, 
                factory.Brand,
                factory.ProductEngine,
                factory.Country,
                factory.MiQualityScore,
                factory.ProductSafetyYes,
                factory.ProductSafetyReason,
                factory.DefectiveRateRawScore,
                factory.DefectiveRatePts,
                factory.MiQualityScoreAdjust,
                factory.FootwearCopqRawScore,
                factory.FootwearCopqPts,
                factory.FootwearBondTestRawScore,
                factory.FootwearBondTestPts,
                factory.FootwearConverseFlexTestRawScore,
                factory.FootwearConverseFlexTestPts,
                factory.FootwearConverseMqaaRawScore,
                factory.FootwearConverseMqaaPts,
                factory.ApparelIssuesNbrRawScore,
                factory.ApparelIssuesNbrPts,
                factory.ApparelIssuesValueRawScore,
                factory.ApparelIssuesValuePts,
                factory.EquipmentIssuesNbrRawScore,
                factory.EquipmentIssuesNbrPts,
                factory.EquipmentIssuesValueRawScore,
                factory.EquipmentIssuesValuePts,
                factory.EquipmentBondTestPts,
                factory.QualityNonCompliantReason

            ]) 
  )
  
  //Format and Lock Header
  const firstRow = ws.getRow(1);
  firstRow.font = { bold: true, size: 12 };

  //Unlock All Cells
  ws.eachRow((row) => {
    row.eachCell({ includeEmpty: true },(cell) => {
        cell.protection = { locked: false };
    });
  });

  //Lock Id Cells
  const idCol = ws.getColumn("Id");
  idCol.eachCell((cell) => {
    cell.protection = { locked: true };
  });
  
  //Format and Lock Factory Group Name Cells
  const factoryNameCol = ws.getColumn("FactoryGroupName");
  factoryNameCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Factory CR Code Cells
  const factoryCRCodeCol = ws.getColumn("FactoryCRCode");
  factoryCRCodeCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Factory PO Code Cells
  const factoryPOCodeCol = ws.getColumn("FactoryPOCode");
  factoryPOCodeCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Brand Cells
  const brandCol = ws.getColumn("Brand");
  brandCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Product Engine Cells
  const productEngineCol = ws.getColumn("ProductEngine");
  productEngineCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Country Cells
  const countryCol = ws.getColumn("Country");
  countryCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock FootwearCopqRawScore Cells
  const footwearCopqRawScoreCol = ws.getColumn("FootwearCopqRawScore");
  footwearCopqRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock FootwearCopqPts Cells
  const footwearCopqPtsCol = ws.getColumn("FootwearCopqPts");
  footwearCopqPtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock FootwearBondTestRawScore Cells
  const footwearBondTestRawScoreCol = ws.getColumn("FootwearBondTestRawScore");
  footwearBondTestRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock FootwearBondTestPts Cells
  const footwearBondTestPtsCol = ws.getColumn("FootwearBondTestPts");
  footwearBondTestPtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock FootwearConverseFlexTestRawScore Cells
  const footwearConverseFlexTestRawScoreCol = ws.getColumn("FootwearConverseFlexTestRawScore");
  footwearConverseFlexTestRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock FootwearConverseFlexTestPts Cells
  const footwearConverseFlexTestPtsCol = ws.getColumn("FootwearConverseFlexTestPts");
  footwearConverseFlexTestPtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock FootwearConverseMqaaRawScore Cells
  const footwearConverseMqaaRawScoreCol = ws.getColumn("FootwearConverseMqaaRawScore");
  footwearConverseMqaaRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock FootwearConverseMqaaPts Cells
  const footwearConverseMqaaPtsCol = ws.getColumn("FootwearConverseMqaaPts");
  footwearConverseMqaaPtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelIssuesNbrRawScore Cells
  const apparelIssuesNbrRawScoreCol = ws.getColumn("ApparelIssuesNbrRawScore");
  apparelIssuesNbrRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelIssuesNbrPts Cells
  const apparelIssuesNbrPtsCol = ws.getColumn("ApparelIssuesNbrPts");
  apparelIssuesNbrPtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelIssuesValueRawScore Cells
  const apparelIssuesValueRawScoreCol = ws.getColumn("ApparelIssuesValueRawScore");
  apparelIssuesValueRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ApparelIssuesValuePts Cells
  const apparelIssuesValuePtsCol = ws.getColumn("ApparelIssuesValuePts");
  apparelIssuesValuePtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  const buf = await wb.xlsx.writeBuffer();

  saveAs(new Blob([buf]), fileName("Quality", brand, productEngine, country) + ".xlsx");
}