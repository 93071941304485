import React, { Component } from "react";
import Config from "../Config";
import { TiInfoOutline } from "react-icons/ti";

class ModalDeliveryForm extends Component {

    state = {
        deliveryList : [],
        postStatus : null,
        getStatus : null,
        MiDeliveryScore : "",
        MiOtpRawScore : "",
        MiOtpPts : "",
        Mi30gacRawScore : "",
        Mi30gacPts : "",
        ConverseMiMltRawScore : "",
        ConverseMiMltPts : "",
        DeliveryNonCompliantReason : "",
        MiDeliveryScoreValid : true,
        MiOtpRawScoreValid : true,
        MiOtpPtsValid : true,
        Mi30gacRawScoreValid : true,
        Mi30gacPtsValid : true,
        ConverseMiMltRawScoreValid : true,
        ConverseMiMltPtsValid : true
    };

    readDelivery(){
        fetch(Config.DeliveryReadUrl + "?factoryid=" + this.props.selectedFactories[0].id).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.bind(data);
          this.setState({ getStatus: "Success" });
        })
        .catch((error) => {
          this.setState( {getStatus: "Failure"} )
    })};

    bind(data) {
        this.setState({ MiDeliveryScore: data[0].MiDeliveryScore});
        this.setState({ MiOtpRawScore: data[0].MiOtpRawScore});
        this.setState({ MiOtpPts: data[0].MiOtpPts});
        this.setState({ Mi30gacRawScore: data[0].Mi30gacRawScore});
        this.setState({ Mi30gacPts: data[0].Mi30gacPts});
        this.setState({ ConverseMiMltRawScore: data[0].ConverseMiMltRawScore});
        this.setState({ ConverseMiMltPts: data[0].ConverseMiMltPts});
        this.setState({ DeliveryNonCompliantReason: data[0].DeliveryNonCompliantReason});
        
    }

    unbind() {
        this.setState({ MiDeliveryScore: ""});
        this.setState({ MiOtpRawScore: ""});
        this.setState({ MiOtpPts: ""});
        this.setState({ Mi30gacRawScore: ""});
        this.setState({ Mi30gacPts: ""});
        this.setState({ ConverseMiMltRawScore: ""});
        this.setState({ ConverseMiMltPts: ""});
        this.setState({ DeliveryNonCompliantReason: ""});        
    }

    componentDidUpdate(prevProps) {
        if(this.props.action === "Data Review") {
            if (this.props.selectedFactories !== prevProps.selectedFactories) {
                this.unbind();
                this.readDelivery();
            }
        }
    };

    setValue = (field, event) => {
        var object = {};
        object[field] = event.target.value;

        if(field === "MiDeliveryScore" || field === "MiOtpRawScore" 
            || field === "MiOtpPts" || field === "Mi30gacRawScore"
            || field === "Mi30gacPts" || field === "ConverseMiMltRawScore"
            || field === "ConverseMiMltPts") {
                this.setState(object, this.validateField(field, event.target.value));
        }
        else {
            this.setState(object);
        }
    }

    validateField = (field, value) => {
        if (value.match(/^\d*((\d\.)|(\.\d))?\d{0,2}$/) === null) {
          switch(field) {
            case "MiDeliveryScore":
                this.setState({ MiDeliveryScoreValid: false } );
              break;
            case "MiOtpRawScore":
                this.setState({ MiOtpRawScoreValid: false } );
              break;
            case "MiOtpPts":
                this.setState({ MiOtpPtsValid: false } );
              break;
            case "Mi30gacRawScore":
                this.setState({ Mi30gacRawScoreValid: false } );
              break;
            case "Mi30gacPts":
                this.setState({ Mi30gacPtsValid: false } );
              break;
            case "ConverseMiMltRawScore":
                this.setState({ ConverseMiMltRawScoreValid: false } );
              break;
            case "ConverseMiMltPts":
                this.setState({ ConverseMiMltPtsValid: false } );
              break;
            default:
              // code block
          }
        }
        else {
            switch(field) {
                case "MiDeliveryScore":
                this.setState({ MiDeliveryScoreValid: true } );
              break;
            case "MiOtpRawScore":
                this.setState({ MiOtpRawScoreValid: true } );
              break;
            case "MiOtpPts":
                this.setState({ MiOtpPtsValid: true } );
              break;
            case "Mi30gacRawScore":
                this.setState({ Mi30gacRawScoreValid: true } );
              break;
            case "Mi30gacPts":
                this.setState({ Mi30gacPtsValid: true } );
              break;
            case "ConverseMiMltRawScore":
                this.setState({ ConverseMiMltRawScoreValid: true } );
              break;
            case "ConverseMiMltPts":
                this.setState({ ConverseMiMltPtsValid: true } );
              break;
            default:
                  // code block
              }
        }

    }

    validateForm = () => {
        if(this.state.MiDeliveryScoreValid === true &&
            this.state.MiOtpRawScoreValid === true && 
            this.state.MiOtpPtsValid === true &&
            this.state.Mi30gacRawScoreValid === true &&
            this.state.Mi30gacPtsValid === true &&
            this.state.ConverseMiMltRawScoreValid === true &&
            this.state.ConverseMiMltPtsValid === true) 
            return true;
        else 
            return false;
    }

    onSave = () => {
    
        this.setState({ deliveryList: [] });
        this.props.selectedFactories.forEach((factory) => {
            this.state.deliveryList.push(
                {
                    "FactoryId": factory.id,
                    "MiDeliveryScore": this.state.MiDeliveryScore,
                    "MiOtpRawScore": this.state.MiOtpRawScore,
                    "MiOtpPts": this.state.MiOtpPts,
                    "Mi30gacRawScore": this.state.Mi30gacRawScore,
                    "Mi30gacPts": this.state.Mi30gacPts,
                    "ConverseMiMltRawScore": this.state.ConverseMiMltRawScore,
                    "ConverseMiMltPts": this.state.ConverseMiMltPts,
                    "DeliveryNonCompliantReason": this.state.DeliveryNonCompliantReason
                    
                }
            ) 
        });

        this.setState({ postStatus: null });
        if(this.state.deliveryList !== null) {
            this.setState( {postStatus: "Posting"});
            this.saveDelivery();
        };

    };

    saveDelivery(){
        fetch(Config.DeliverySaveUrl,  {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "delivery": this.state.deliveryList })
          }).then((response) => {
          if (response.ok) {
            return response.json();
          } 
          else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ postStatus: "Success" });
          window.location.reload(false);
        })
        .catch((error) => {
          this.setState( {postStatus: "Failure"} )
    })};


    render() {

        return (

        <div class="modal fade" id="Delivery" tabindex="-1" role="dialog" aria-labelledby="DeliveryModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered  modal-dialog-scrollable modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        {
                            this.props.selectedFactories.length === 1 ?
                            <h6 class="modal-title" id="DeliveryModalTitle">Enter Manufacturing Index Data - {this.props.selectedFactories[0].label.split("-")[1]}</h6>
                            :
                            <h6 class="modal-title" id="DeliveryModalTitle">Enter Manufacturing Index Data - {this.props.selectedFactories.length} Selected Factories</h6>
                        }
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p><small><TiInfoOutline />&nbsp;Use the TAB key to move from field to field.</small></p>
                        <form>
                            <div class="form-row">
                                <div class="form-group col-sm-6">
                                    <label for="MiDeliveryScore">MiDeliveryScore&nbsp;<span class="text-danger">{ this.state.MiDeliveryScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                    <input type="text" class="form-control" id="MiDeliveryScore" value={this.state.MiDeliveryScore} onChange={this.setValue.bind(this, "MiDeliveryScore")} />
                                </div>
                                <div class="form-group col-sm-6">
                                    <label for="MiOtpRawScore">MiOtpRawScore&nbsp;<span class="text-danger">{ this.state.MiOtpRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                    <input type="text" class="form-control" id="MiOtpRawScore" value={this.state.MiOtpRawScore} onChange={this.setValue.bind(this, "MiOtpRawScore")} />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-sm-6">
                                    <label for="MiOtpPts">MiOtpPts&nbsp;<span class="text-danger">{ this.state.MiOtpPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                    <input type="text" class="form-control" id="MiOtpPts" value={this.state.MiOtpPts} onChange={this.setValue.bind(this, "MiOtpPts")} />
                                </div>
                                <div class="form-group col-sm-6">
                                    <label for="Mi30gacRawScore">Mi30gacRawScore&nbsp;<span class="text-danger">{ this.state.Mi30gacRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                    <input type="text" class="form-control" id="Mi30gacRawScore" value={this.state.Mi30gacRawScore} onChange={this.setValue.bind(this, "Mi30gacRawScore")} />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-sm-6">
                                    <label for="Mi30gacPts">Mi30gacPts&nbsp;<span class="text-danger">{ this.state.Mi30gacPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                    <input type="text" class="form-control" id="Mi30gacPts" value={this.state.Mi30gacPts} onChange={this.setValue.bind(this, "Mi30gacPts")} />
                                </div>
                                <div class="form-group col-sm-6">
                                    <label for="ConverseMiMltRawScore">ConverseMiMltRawScore&nbsp;<span class="text-danger">{ this.state.ConverseMiMltRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                    <input type="text" class="form-control" id="ConverseMiMltRawScore" value={this.state.ConverseMiMltRawScore} onChange={this.setValue.bind(this, "ConverseMiMltRawScore")} />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-sm-6">
                                    <label for="ConverseMiMltPts">ConverseMiMltPts&nbsp;<span class="text-danger">{ this.state.ConverseMiMltPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                    <input type="text" class="form-control" id="ConverseMiMltPts" value={this.state.ConverseMiMltPts} onChange={this.setValue.bind(this, "ConverseMiMltPts")} />
                                </div>
                                <div class="form-group col-sm-6">
                                   &nbsp;
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-sm-12">
                                    <label for="DeliveryNonCompliantReason">DeliveryNonCompliantReason</label>
                                    <textarea class="form-control" id="DeliveryNonCompliantReason" rows="3" value={this.state.DeliveryNonCompliantReason} onChange={this.setValue.bind(this, "DeliveryNonCompliantReason")}></textarea>
                                </div>
                            </div>
                        </form>

                        {
                                this.state.postStatus === "Posting" ?

                                <div class="alert alert-info" role="alert">
                                    Saving delivery data...please wait
                                </div>

                                : this.state.postStatus === "Success" ?

                                <div class="alert alert-success" role="alert">
                                    Completed saving delivery data.
                                </div>

                                : this.state.postStatus === "Failure" ?
                                
                                <div class="alert alert-danger" role="alert">
                                    Something went wrong while saving the delivery data! Please try again later.
                                </div>

                                : null
                        }

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-sm btn-primary" onClick={this.onSave} disabled={!this.validateForm()}>Save</button>
                    </div>
                </div>
            </div>
        </div>

        );
    };
};

export default ModalDeliveryForm;