import React, { Component } from "react";
import Card from "../components/Card";
import CardReportingStatus from "../components/CardReportingStatus";
import CardFactoryStatus from "../components/CardFactoryStatus";
import CardFactoryStatistics from "../components/CardFactoryStatistics";
import Config from "../Config";

class ProgramManagerHome extends Component {

    state = {
        AuditList : null,
        Status : "Loading"
      };
    
      getAuditList(){
        fetch(Config.AuditListUrl).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ AuditList: data });
          this.setState({ Status: "Success" });
        })
        .catch((error) => {
          this.setState( {Status: "Failure"} )
      })};
    
      componentDidMount() {
        this.getAuditList();
      };

    render() {
  
      return (

      <div class="container-fluid">

        <div class="row bg-light">
            
            <div class="col-8">

                <div class="card-deck">

                    <Card title="Set Active Reporting Period"
                        body="Set a reporting period to active." 
                        action="Set"
                        date={ 
                          this.state.Status === "Loading" ? 
                            "Loading..."
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Set Active Reporting Period")[0] === null ? 
                            "Last set on - N/A"
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Set Active Reporting Period")[0] != null ? 
                            "Last set on - " + this.state.AuditList.filter((item) => item.Action === "Set Active Reporting Period")[0].Date
                          : "Last set on - N/A" } />
                        

                    <Card title="Import Factory Data"
                        body="Import factory data from the Aravo system." 
                        action="Import"
                        date={ 
                          this.state.Status === "Loading" ? 
                            "Loading..."
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Import Factory Data")[0] === null ? 
                            "Last imported on - N/A"
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Import Factory Data")[0] != null ? 
                            "Last imported on - " + this.state.AuditList.filter((item) => item.Action === "Import Factory Data")[0].Date
                          : "Last imported on - N/A" } />

                </div>

                <br />

                <div class="card-deck">
                    
                  <Card title="Exclude Factory"
                        body="Exclude factory from manufacturing index reporting." 
                        action="Exclude"
                        date={ 
                          this.state.Status === "Loading" ? 
                            "Loading..."
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Exclude Factory")[0] === null ? 
                            "Last excluded on - N/A"
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Exclude Factory")[0] != null ? 
                            "Last excluded on - " + this.state.AuditList.filter((item) => item.Action === "Exclude Factory")[0].Date
                          : "Last excluded on - N/A" } />

                  <Card title="Include Factory"
                        body="Include factory into manufacturing index reporting." 
                        action="Include"
                        date={ 
                          this.state.Status === "Loading" ? 
                            "Loading..."
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Include Factory")[0] === null ? 
                            "Last included on - N/A"
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Include Factory")[0] != null ? 
                            "Last included on - " + this.state.AuditList.filter((item) => item.Action === "Include Factory")[0].Date
                          : "Last included on - N/A" } /> 

                   

                </div>

                <br />

                <div class="card-deck">

                <Card title="Import Delivery Data"
                        body="Import manufacturing index data from the Delivery system." 
                        action="Import"
                        date={ 
                          this.state.Status === "Loading" ? 
                            "Loading..."
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Import Delivery Data")[0] === null ? 
                            "Last imported on - N/A"
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Import Delivery Data")[0] != null ? 
                            "Last imported on - " + this.state.AuditList.filter((item) => item.Action === "Import Delivery Data")[0].Date
                          : "Last imported on - N/A" } />

                  <Card title="Import Sustainability Data"
                        body="Import manufacturing index data from the Sustainability system." 
                        action="Import"
                        date={ 
                          this.state.Status === "Loading" ? 
                            "Loading..."
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Import Sustainability Data")[0] === null ? 
                            "Last imported on - N/A"
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Import Sustainability Data")[0] != null ? 
                            "Last imported on - " + this.state.AuditList.filter((item) => item.Action === "Import Sustainability Data")[0].Date
                          : "Last imported on - N/A" } />                 

                </div>

                <br />

                <div class="card-deck">

                <Card title="Review Manufacturing Index Data"
                        body="Review manufacturing index data before exporting to S3 bucket." 
                        action="Review"
                        link="datareview"
                        date={ 
                          this.state.Status === "Loading" ? 
                            "Loading..."
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Review Manufacturing Index Data")[0] === null ? 
                            "Last reviewed on - N/A"
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Review Manufacturing Index Data")[0] != null ? 
                            "Last reviewed on - " + this.state.AuditList.filter((item) => item.Action === "Review Manufacturing Index Data")[0].Date
                          : "Last reviewed on - N/A" } />  

                <Card title="Export Manufacturing Index Data"
                        body="Export manufacturing index data to S3 bucket for generating reports." 
                        action="Export"
                        date={ 
                          this.state.Status === "Loading" ? 
                            "Loading..."
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Export Manufacturing Index Data")[0] === null ? 
                            "Last exported on - N/A"
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Export Manufacturing Index Data")[0] != null ? 
                            "Last exported on - " + this.state.AuditList.filter((item) => item.Action === "Export Manufacturing Index Data")[0].Date
                          : "Last exported on - N/A" } />

                </div>

                <br />

                <div class="card-deck">

                <Card title="View Historical Manufacturing Index Data"
                        body="View historical manufacturing index data of a factory." 
                        action="View"
                        link="viewhistoricaldata"
                        date={ 
                          this.state.Status === "Loading" ? 
                            "Loading..."
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "View Historical Manufacturing Index Data")[0] === null ? 
                            "Last viewed on - N/A"
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "View Historical Manufacturing Index Data")[0] != null ? 
                            "Last viewed on - " + this.state.AuditList.filter((item) => item.Action === "View Historical Manufacturing Index Data")[0].Date
                          : "Last viewed on - N/A" } />

                <Card title="Report"
                        body="Generate report with various filter conditions on the manufacturing index data." 
                        action="Report"
                        link="report"
                        date="Last accessed on - N/A" />

                </div>

                <br />

                <div class="card-deck">

                    <Card title="Lock Reporting Period"
                        body="Lock reporting period to freeze the data collection." 
                        action="Lock"
                        date={ 
                          this.state.Status === "Loading" ? 
                            "Loading..."
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Lock Reporting Period")[0] === null ? 
                            "Last locked on - N/A"
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Lock Reporting Period")[0] != null ? 
                            "Last locked on - " + this.state.AuditList.filter((item) => item.Action === "Lock Reporting Period")[0].Date
                          : "Last locked on - N/A" } />

                    <Card title="Unlock Reporting Period"
                        body="Unlock reporting period to enable the data collection." 
                        action="Unlock"
                        date={ 
                          this.state.Status === "Loading" ? 
                            "Loading..."
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Unlock Reporting Period")[0] === null ? 
                            "Last unlocked on - N/A"
                          :  this.state.Status === "Success" && this.state.AuditList.filter((item) => item.Action === "Unlock Reporting Period")[0] != null ? 
                            "Last unlocked on - " + this.state.AuditList.filter((item) => item.Action === "Unlock Reporting Period")[0].Date
                          : "Last unlocked on - N/A" } />

                </div>

            </div>
            <div class="col-4">
               
                <div class="card-deck">
                    <CardReportingStatus /> 
                </div>

                <br />

                <div class="card-deck">
                    <CardFactoryStatus /> 
                </div>

                <br />

                <div class="card-deck">
                    <CardFactoryStatistics /> 
                </div>

            </div>

            <span>&nbsp;</span>

           
    
        </div>

      </div>   

      );
  
    };
  
  };
  
  export default ProgramManagerHome;