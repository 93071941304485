import React, { Component } from "react";
import CostingRouter from "./containers/CostingRouter";
import DeliveryRouter from "./containers/DeliveryRouter";
import QualityRouter from "./containers/QualityRouter";
import SustainabilityRouter from "./containers/SustainabilityRouter";
import AdminRouter from "./containers/AdminRouter";
import Unauthorized from "./containers/Unauthorized";


class App extends Component {
  
  render() {
    return (
      <div>
        {
          this.props.role === "App.MI-UI.ProgramMangerRole" ? 
          <AdminRouter user={this.props.user} />
          : this.props.role === "App.MI-UI.CostingRole" ? 
          <CostingRouter user={this.props.user} />
          : this.props.role === "App.MI-UI.DeliveryRole" ? 
          <DeliveryRouter user={this.props.user} />
          : this.props.role === "App.MI-UI.QualityRole" ? 
          <QualityRouter user={this.props.user} />
          : this.props.role === "App.MI-UI.SustainabilityRole" ? 
          <SustainabilityRouter user={this.props.user} />
          :<Unauthorized />
        }
      </div>
    );
  };
};

export default App;