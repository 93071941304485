import React, { Component } from "react";
import Config from "../Config";

class ModalSustainabilityForm extends Component {

    state = {
        sustainabilityList : [],
        postStatus : null,
        getStatus : null,
        MiSmsiScore : "",
        MiSmsiScoreAdjust : "", 
        LaborPts : "",
        HsePts : "",
        EsatPts : "",
        EcatPts : "",
        LatPts : "",
        HsatPts : "",
        HrmatPts : "",
        UnauthSubconYes : "",
        ZeroToleranceYes : "",
        ZeroToleranceReason : "",
        SmsiNonCompliantReason : "",
        MiSmsiScoreValid : true,
        MiSmsiScoreAdjustValid : true,
        LaborPtsValid : true,
        HsePtsValid : true,
        EsatPtsValid : true,
        EcatPtsValid : true,
        LatPtsValid : true,
        HsatPtsValid : true,
        HrmatPtsValid : true
    };

    readSustainability(){
        fetch(Config.SustainabilityReadUrl + "?factoryid=" + this.props.selectedFactories[0].id).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.bind(data);
          this.setState({ Status: "Success" });
        })
        .catch((error) => {
          this.setState( {Status: "Failure"} )
    })};

    bind(data) {
        this.setState({ MiSmsiScore: data[0].MiSmsiScore});
        this.setState({ MiSmsiScoreAdjust: data[0].MiSmsiScoreAdjust});
        this.setState({ LaborPts: data[0].LaborPts});
        this.setState({ HsePts: data[0].HsePts});
        this.setState({ EsatPts: data[0].EsatPts});
        this.setState({ EcatPts: data[0].EcatPts});
        this.setState({ LatPts: data[0].LatPts});
        this.setState({ HsatPts: data[0].HsatPts});
        this.setState({ HrmatPts: data[0].HrmatPts});
        this.setState({ UnauthSubconYes: data[0].UnauthSubconYes});
        this.setState({ ZeroToleranceYes: data[0].ZeroToleranceYes});
        this.setState({ ZeroToleranceReason: data[0].ZeroToleranceReason});
        this.setState({ SmsiNonCompliantReason: data[0].SmsiNonCompliantReason});

    }

    unbind() {
        this.setState({ MiSmsiScore: ""});
        this.setState({ MiSmsiScoreAdjust: ""});
        this.setState({ LaborPts: ""});
        this.setState({ HsePts: ""});
        this.setState({ EsatPts: ""});
        this.setState({ EcatPts: ""});
        this.setState({ LatPts: ""});
        this.setState({ HsatPts: ""});
        this.setState({ HrmatPts: ""});
        this.setState({ UnauthSubconYes: ""});
        this.setState({ ZeroToleranceYes: ""});
        this.setState({ ZeroToleranceReason: ""});
        this.setState({ SmsiNonCompliantReason: ""});

    }

    componentDidUpdate(prevProps) {
        if(this.props.action === "Data Review") {
            if (this.props.selectedFactories !== prevProps.selectedFactories) {
                this.unbind();
                this.readSustainability();
            }
        }
    };

    setValue = (field, event) => {

        var object = {};
        object[field] = event.target.value;

        if(field === "MiSmsiScore" || field === "MiSmsiScoreAdjust" 
            || field === "LaborPts" || field === "HsePts"
            || field === "EsatPts" || field === "EcatPts"
            || field === "LatPts" || field === "HsatPts" 
            || field === "HrmatPts") {
                this.setState(object, this.validateField(field, event.target.value));
        }
        else {
            this.setState(object);
        }
    }

    validateField = (field, value) => {
        if (value.match(/^\d*((\d\.)|(\.\d))?\d{0,2}$/) === null) {
          switch(field) {
            case "MiSmsiScore":
                this.setState({ MiSmsiScoreValid: false } );
              break;
            case "MiSmsiScoreAdjust":
                this.setState({ MiSmsiScoreAdjustValid: false } );
              break;
            case "LaborPts":
                this.setState({ LaborPtsValid: false } );
              break;
            case "HsePts":
                this.setState({ HsePtsValid: false } );
              break;
            case "EsatPts":
                this.setState({ EsatPtsValid: false } );
              break;
            case "EcatPts":
                this.setState({ EcatPtsValid: false } );
              break;
            case "LatPts":
                this.setState({ LatPtsValid: false } );
              break;
            case "HsatPts":
                this.setState({ HsatPtsValid: false } );
                break;
            case "HrmatPts":
                this.setState({ HrmatPtsValid: false } );
                break;
            default:
              // code block
          }
        }
        else {
            switch(field) {
                case "MiSmsiScore":
                    this.setState({ MiSmsiScoreValid: true } );
                  break;
                case "MiSmsiScoreAdjust":
                    this.setState({ MiSmsiScoreAdjustValid: true } );
                  break;
                case "LaborPts":
                    this.setState({ LaborPtsValid: true } );
                  break;
                case "HsePts":
                    this.setState({ HsePtsValid: true } );
                  break;
                case "EsatPts":
                    this.setState({ EsatPtsValid: true } );
                  break;
                case "EcatPts":
                    this.setState({ EcatPtsValid: true } );
                  break;
                case "LatPts":
                    this.setState({ LatPtsValid: true } );
                  break;
                case "HsatPts":
                    this.setState({ HsatPtsValid: true } );
                    break;
                case "HrmatPts":
                    this.setState({ HrmatPtsValid: true } );
                    break;
                default:
                  // code block
              }
        }

       

    }

    validateForm = () => {
        if(this.state.MiSmsiScoreValid === true &&
            this.state.MiSmsiScoreAdjustValid === true && 
            this.state.LaborPtsValid === true &&
            this.state.HsePtsValid === true &&
            this.state.EsatPtsValid === true &&
            this.state.EcatPtsValid === true &&
            this.state.LatPtsValid === true &&
            this.state.HsatPtsValid === true &&
            this.state.HrmatPtsValid === true) 
            return true;
        else 
            return false;
    }

    onSave = () => {
    
        this.setState({ sustainabilityList: [] });
        this.props.selectedFactories.forEach((factory) => {
            this.state.sustainabilityList.push(
                {
                    "FactoryId": factory.id,
                    "MiSmsiScore": this.state.MiSmsiScore,
                    "MiSmsiScoreAdjust": this.state.MiSmsiScoreAdjust,
                    "LaborPts": this.state.LaborPts,
                    "HsePts": this.state.HsePts,
                    "EsatPts": this.state.EsatPts,
                    "EcatPts": this.state.EcatPts,
                    "LatPts": this.state.LatPts,
                    "HsatPts": this.state.HsatPts,
                    "HrmatPts": this.state.HrmatPts,
                    "UnauthSubconYes": this.state.UnauthSubconYes,
                    "ZeroToleranceYes": this.state.ZeroToleranceYes,
                    "ZeroToleranceReason": this.state.ZeroToleranceReason,
                    "SmsiNonCompliantReason": this.state.SmsiNonCompliantReason
                }
            ) 
        });

        this.setState({ postStatus: null });
        if(this.state.sustainabilityList !== null) {
            this.setState( {postStatus: "Posting"});
            this.saveSustainability();
        };

    };

    saveSustainability(){
        fetch(Config.SustainabilitySaveUrl,  {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "sustainability": this.state.sustainabilityList })
          }).then((response) => {
          if (response.ok) {
            return response.json();
          } 
          else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ postStatus: "Success" });
          window.location.reload(false);
        })
        .catch((error) => {
          this.setState( {postStatus: "Failure"} )
    })};


    render() {

        return (

        <div class="modal fade" id="Sustainability" tabindex="-1" role="dialog" aria-labelledby="sustainabilityModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered  modal-dialog-scrollable modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        {
                            this.props.selectedFactories.length === 1 ?
                            <h6 class="modal-title" id="sustainabilityModalTitle">Enter Manufacturing Index Data - {this.props.selectedFactories[0].label.split("-")[1]}</h6>
                            :
                            <h6 class="modal-title" id="sustainabilityModalTitle">Enter Manufacturing Index Data - {this.props.selectedFactories.length} Selected Factories</h6>
                        }
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="form-row">
                                <div class="form-group col-sm-6">
                                    <label for="MiSmsiScore">MiSmsiScore&nbsp;<span class="text-danger">{ this.state.MiSmsiScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                    <input type="text" class="form-control" id="MiSmsiScore" value={this.state.MiSmsiScore} onChange={this.setValue.bind(this, "MiSmsiScore")} />
                                </div>
                                <div class="form-group col-sm-6">
                                    <label for="MiSmsiScoreAdjust">MiSmsiScoreAdjust&nbsp;<span class="text-danger">{ this.state.MiSmsiScoreAdjustValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                    <input type="text" class="form-control" id="MiSmsiScoreAdjust" value={this.state.MiSmsiScoreAdjust} onChange={this.setValue.bind(this, "MiSmsiScoreAdjust")} />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-sm-6">
                                    <label for="LaborPts">LaborPts&nbsp;<span class="text-danger">{ this.state.LaborPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                    <input type="text" class="form-control" id="LaborPts" value={this.state.LaborPts} onChange={this.setValue.bind(this, "LaborPts")} />
                                </div>
                                <div class="form-group col-sm-6">
                                    <label for="HsePts">HsePts&nbsp;<span class="text-danger">{ this.state.HsePtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                    <input type="text" class="form-control" id="HsePts" value={this.state.HsePts} onChange={this.setValue.bind(this, "HsePts")} />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-sm-6">
                                    <label for="EsatPts">EsatPts&nbsp;<span class="text-danger">{ this.state.EsatPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                    <input type="text" class="form-control" id="EsatPts" value={this.state.EsatPts} onChange={this.setValue.bind(this, "EsatPts")} />
                                </div>
                                <div class="form-group col-sm-6">
                                    <label for="EcatPts">EcatPts&nbsp;<span class="text-danger">{ this.state.EcatPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                    <input type="text" class="form-control" id="EcatPts" value={this.state.EcatPts} onChange={this.setValue.bind(this, "EcatPts")} />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-sm-6">
                                    <label for="LatPts">LatPts&nbsp;<span class="text-danger">{ this.state.LatPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                    <input type="text" class="form-control" id="LatPts" value={this.state.LatPts} onChange={this.setValue.bind(this, "LatPts")} />
                                </div>
                                <div class="form-group col-sm-6">
                                    <label for="HsatPts">HsatPts&nbsp;<span class="text-danger">{ this.state.HsatPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                    <input type="text" class="form-control" id="HsatPts" value={this.state.HsatPts} onChange={this.setValue.bind(this, "HsatPts")} />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-sm-6">
                                    <label for="HrmatPts">HrmatPts&nbsp;<span class="text-danger">{ this.state.HrmatPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                    <input type="text" class="form-control" id="HrmatPts" value={this.state.HrmatPts} onChange={this.setValue.bind(this, "HrmatPts")} />
                                </div>
                                <div class="form-group col-sm-6">
                                    <label for="UnauthSubconYes">UnauthSubconYes</label>
                                    <select class="form-control" id="UnauthSubconYes" value={this.state.UnauthSubconYes} onChange={this.setValue.bind(this, "UnauthSubconYes")}>
                                        <option></option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-sm-6">
                                    <label for="ZeroToleranceYes">ZeroToleranceYes</label>
                                    <select class="form-control" id="ZeroToleranceYes" value={this.state.ZeroToleranceYes} onChange={this.setValue.bind(this, "ZeroToleranceYes")}>
                                        <option></option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </select>
                                </div>
                                <div class="form-group col-sm-6">
                                    <label for="ZeroToleranceReason">ZeroToleranceReason</label>
                                    <input type="text" class="form-control" id="ZeroToleranceReason" value={this.state.ZeroToleranceReason} onChange={this.setValue.bind(this, "ZeroToleranceReason")} />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-sm-12">
                                    <label for="SmsiNonCompliantReason">SmsiNonCompliantReason</label>
                                    <textarea class="form-control" id="SmsiNonCompliantReason" rows="3" value={this.state.SmsiNonCompliantReason} onChange={this.setValue.bind(this, "SmsiNonCompliantReason")}></textarea>
                                </div>
                            </div>
                        </form>

                        {
                                this.state.postStatus === "Posting" ?

                                <div class="alert alert-info" role="alert">
                                    Saving sustainability data...please wait
                                </div>

                                : this.state.postStatus === "Success" ?

                                <div class="alert alert-success" role="alert">
                                    Completed saving sustainability data.
                                </div>

                                : this.state.postStatus === "Failure" ?
                                
                                <div class="alert alert-danger" role="alert">
                                    Something went wrong while saving the sustainability data! Please try again later.
                                </div>

                                : null
                        }

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-sm btn-primary" onClick={this.onSave} disabled={!this.validateForm()}>Save</button>
                    </div>
                </div>
            </div>
        </div>

        );
    };
};

export default ModalSustainabilityForm;