import ExcelJS from "exceljs/dist/es5/exceljs.browser.js";
import { saveAs } from "file-saver";
import { protect, fill, border, fileName } from "./UtilExcel";

export const saveIncludeConverse = async (brand, productEngine, country, deliveryList) => {

    const wb = new ExcelJS.Workbook();

    const ws = wb.addWorksheet(fileName("Delivery", brand, productEngine, country), {views:[{state: "frozen", xSplit: 7, ySplit:1}]})

    ws.protect("Delivery", protect());

    ws.columns = [
        { header: "Id", key: "Id", width: 10, hidden: true },
        { header: "FactoryGroupName", key: "FactoryGroupName", width: 20 },
        { header: "FactoryCRCode", key: "FactoryCRCode", width: 15 },
        { header: "FactoryPOCode", key: "FactoryPOCode", width: 15 },
        { header: "Brand", key: "Brand", width: 10 },
        { header: "ProductEngine", key: "ProductEngine", width: 15 },
        { header: "Country", key: "Country", width: 20 },
        { header: "MiDeliveryScore", key: "MiDeliveryScore", width: 25 },
        { header: "MiOtpRawScore", key: "MiOtpRawScore", width: 25 },
        { header: "MiOtpPts", key: "MiOtpPts", width: 25 },
        { header: "Mi30gacRawScore", key: "Mi30gacRawScore", width: 25 },
        { header: "Mi30gacPts", key: "Mi30gacPts", width: 25 },
        { header: "ConverseMiMltRawScore", key: "ConverseMiMltRawScore", width: 25 },
        { header: "ConverseMiMltPts", key: "ConverseMiMltPts", width: 25 },
        { header: "DeliveryNonCompliantReason", key: "DeliveryNonCompliantReason", width: 25 }
      ];

      deliveryList.map((factory) =>
        ws.addRow([ factory.Id,
                    factory.FactoryGroupName, 
                    factory.FactoryCRCode, 
                    factory.FactoryPOCode, 
                    factory.Brand,
                    factory.ProductEngine,
                    factory.Country,
                    factory.MiDeliveryScore,
                    factory.MiOtpRawScore,
                    factory.MiOtpPts,
                    factory.Mi30gacRawScore,
                    factory.Mi30gacPts,
                    factory.ConverseMiMltRawScore,
                    factory.ConverseMiMltPts,
                    factory.DeliveryNonCompliantReason
                ]) 
    )
    
    //Format and Lock Header
    const firstRow = ws.getRow(1);
    firstRow.font = { bold: true, size: 12 };

    //Unlock All Cells
    ws.eachRow((row) => {
      row.eachCell({ includeEmpty: true },(cell) => {
          cell.protection = { locked: false };
      });
    });

    //Lock Id Cells
    const idCol = ws.getColumn("Id");
    idCol.eachCell((cell) => {
      cell.protection = { locked: true };
    });
    
    //Format and Lock Factory Group Name Cells
    const factoryNameCol = ws.getColumn("FactoryGroupName");
    factoryNameCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Factory CR Code Cells
    const factoryCRCodeCol = ws.getColumn("FactoryCRCode");
    factoryCRCodeCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Factory PO Code Cells
    const factoryPOCodeCol = ws.getColumn("FactoryPOCode");
    factoryPOCodeCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Brand Cells
    const brandCol = ws.getColumn("Brand");
    brandCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Product Engine Cells
    const productEngineCol = ws.getColumn("ProductEngine");
    productEngineCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    //Format and Lock Country Cells
    const countryCol = ws.getColumn("Country");
    countryCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
    });

    const buf = await wb.xlsx.writeBuffer();

    saveAs(new Blob([buf]), fileName("Delivery", brand, productEngine, country) + ".xlsx");
}

export const saveExcludeConverse = async (brand, productEngine, country, deliveryList) => {

  const wb = new ExcelJS.Workbook();

  const ws = wb.addWorksheet(fileName("Delivery", brand, productEngine, country), {views:[{state: "frozen", xSplit: 7, ySplit:1}]})

  ws.protect("Delivery", protect());

  ws.columns = [
    { header: "Id", key: "Id", width: 10, hidden: true },
    { header: "FactoryGroupName", key: "FactoryGroupName", width: 20 },
    { header: "FactoryCRCode", key: "FactoryCRCode", width: 15 },
    { header: "FactoryPOCode", key: "FactoryPOCode", width: 15 },
    { header: "Brand", key: "Brand", width: 10 },
    { header: "ProductEngine", key: "ProductEngine", width: 15 },
    { header: "Country", key: "Country", width: 20 },
    { header: "MiDeliveryScore", key: "MiDeliveryScore", width: 25 },
    { header: "MiOtpRawScore", key: "MiOtpRawScore", width: 25 },
    { header: "MiOtpPts", key: "MiOtpPts", width: 25 },
    { header: "Mi30gacRawScore", key: "Mi30gacRawScore", width: 25 },
    { header: "Mi30gacPts", key: "Mi30gacPts", width: 25 },
    { header: "ConverseMiMltRawScore", key: "ConverseMiMltRawScore", width: 25, hidden: true },
    { header: "ConverseMiMltPts", key: "ConverseMiMltPts", width: 25, hidden: true },
    { header: "DeliveryNonCompliantReason", key: "DeliveryNonCompliantReason", width: 25 }
  ];

    deliveryList.map((factory) =>
      ws.addRow([ factory.Id,
                  factory.FactoryGroupName, 
                  factory.FactoryCRCode, 
                  factory.FactoryPOCode, 
                  factory.Brand,
                  factory.ProductEngine,
                  factory.Country,
                  factory.MiDeliveryScore,
                  factory.MiOtpRawScore,
                  factory.MiOtpPts,
                  factory.Mi30gacRawScore,
                  factory.Mi30gacPts,
                  factory.ConverseMiMltRawScore,
                  factory.ConverseMiMltPts,
                  factory.DeliveryNonCompliantReason
              ]) 
  )
  
  //Format and Lock Header
  const firstRow = ws.getRow(1);
  firstRow.font = { bold: true, size: 12 };

  //Unlock All Cells
  ws.eachRow((row) => {
    row.eachCell({ includeEmpty: true },(cell) => {
        cell.protection = { locked: false };
    });
  });

  //Lock Id Cells
  const idCol = ws.getColumn("Id");
  idCol.eachCell((cell) => {
    cell.protection = { locked: true };
  });
  
  //Format and Lock Factory Group Name Cells
  const factoryNameCol = ws.getColumn("FactoryGroupName");
  factoryNameCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Factory CR Code Cells
  const factoryCRCodeCol = ws.getColumn("FactoryCRCode");
  factoryCRCodeCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Factory PO Code Cells
  const factoryPOCodeCol = ws.getColumn("FactoryPOCode");
  factoryPOCodeCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Brand Cells
  const brandCol = ws.getColumn("Brand");
  brandCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Product Engine Cells
  const productEngineCol = ws.getColumn("ProductEngine");
  productEngineCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock Country Cells
  const countryCol = ws.getColumn("Country");
  countryCol.eachCell((cell) => {
    cell.protection = { locked: true };
    cell.fill = fill();
    cell.border = border();
  });

  //Format and Lock ConverseMiMltRawScore Cells
  const ConverseMiMltRawScoreCol = ws.getColumn("ConverseMiMltRawScore");
  ConverseMiMltRawScoreCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });

  //Format and Lock ConverseMiMltPts Cells
  const ConverseMiMltPtsCol = ws.getColumn("ConverseMiMltPts");
  ConverseMiMltPtsCol.eachCell((cell) => {
      cell.protection = { locked: true };
      cell.fill = fill();
      cell.border = border();
  });



  const buf = await wb.xlsx.writeBuffer();

  saveAs(new Blob([buf]), fileName("Delivery", brand, productEngine, country) + ".xlsx");
}