import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AiOutlineDollar } from "react-icons/ai";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FiCheckCircle } from "react-icons/fi";
import { GiThreeLeaves } from "react-icons/gi";
import { AiOutlineUser } from "react-icons/ai";

class AdminHome extends Component {
    
    render() {
        return (
        
            <div class="container-fluid">

               <span>&nbsp;</span>
               
               <div class="card-deck">

                    <div class="card border-white text-center">
                        <div class="card-body bg-light">
                            <h6 class="card-title">COSTING</h6>
                            <hr class="my-2" />
                            <span>
                                <AiOutlineDollar />
                            </span>
                            <br /> <br />
                            <button class="btn  btn-outline-primary btn-sm btn-block" role="button">
                                <Link target="_blank" to="costing" style={{ textDecoration: "none"}}>Go to Costing</Link>
                            </button>
                        </div>
                    </div>

                    <div class="card border-white text-center">
                        <div class="card-body bg-light">
                            <h6 class="card-title">DELIVERY</h6>
                            <hr class="my-2" />
                            <span>
                                <AiOutlineShoppingCart />
                            </span>
                            <br /> <br />
                            <button class="btn  btn-outline-primary btn-sm btn-block" role="button">
                                <Link target="_blank" to="delivery" style={{ textDecoration: "none"}}>Go to Delivery</Link>
                            </button>
                        </div>
                    </div>

                    <div class="card border-white text-center">
                        <div class="card-body bg-light">
                            <h6 class="card-title">QUALITY</h6>
                            <hr class="my-2" />
                            <span>
                                <FiCheckCircle />
                            </span>
                            <br /> <br />
                            <button class="btn  btn-outline-primary btn-sm btn-block" role="button">
                                <Link target="_blank" to="quality" style={{ textDecoration: "none"}}>Go to Quality</Link>
                            </button>
                        </div>
                    </div>

                    <div class="card border-white text-center">
                        <div class="card-body bg-light">
                            <h6 class="card-title">SUSTAINABILITY</h6>
                            <hr class="my-2" />
                            <span>
                                <GiThreeLeaves />
                            </span>
                            <br /> <br />
                            <button class="btn  btn-outline-primary btn-sm btn-block" role="button">
                                <Link target="_blank" to="sustainability" style={{ textDecoration: "none"}}>Go to Sustainability</Link>
                            </button>
                        </div>
                    </div>

                    <div class="card border-white text-center">
                        <div class="card-body bg-light">
                            <h6 class="card-title">PROGRAM MANAGER</h6>
                            <hr class="my-2" />
                            <span>
                                <AiOutlineUser />
                            </span>
                            <br /> <br />
                            <button class="btn  btn-outline-primary btn-sm btn-block" role="button">
                                <Link target="_blank" to="programmanager" style={{ textDecoration: "none"}}>Go to Program Manager</Link>
                            </button>
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

export default AdminHome;