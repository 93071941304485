import React, { Component } from "react";
import { FaBars } from "react-icons/fa";
import Config from "../Config";

class CardReportingStatus extends Component {

    state = {
        reportingStatusList : [],
        status: "Loading",
      };

    getReportingStatusList(){
        fetch(Config.ReportingStatusListUrl).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ reportingStatusList: data });
          this.setState({ status: "Success" });
        })
        .catch((error) => {
          this.setState( {status: "Failure"} )
      })};

      componentDidMount() {
        this.getReportingStatusList();
      };

    render() {

        return (

            <div class="card border-white">
              <div class="card-body">
                <h6 class="card-title">
                  <FaBars />&nbsp;Reporting Status
                </h6>
                <div class="border-top my-2"></div>
                <ul class="list-group reportingStatusList-group-flush">  
                {
                    this.state.status === "Loading" ?
                        <div class="alert alert-info" role="alert">
                            Loading...
                        </div>
                    : this.state.status === "Success" ?
                        this.state.reportingStatusList.map(item => (
                        <li class="list-group-item border-white d-flex justify-content-between align-items-center" key={item.Id}>
                            {item.Key}
                            {
                              item.Value === "Not Started" ?
                              <span class="badge" style={{backgroundColor: "#CC4E01", color: "#FFFFFF"}}><h6><small>&nbsp;{item.Value}&nbsp;</small></h6></span>
                              : item.Value === "Data Collection" ?
                              <span class="badge" style={{backgroundColor: "#CC8A00", color: "#FFFFFF"}}><h6><small>&nbsp;{item.Value}&nbsp;</small></h6></span>
                              : item.Value === "Data Review" ?
                              <span class="badge" style={{backgroundColor: "#71A002", color: "#FFFFFF"}}><h6><small>&nbsp;{item.Value}&nbsp;</small></h6></span>
                              :<span clasclasssName="badge" style={{backgroundColor: "#009452", color: "#FFFFFF"}}><h6><small>&nbsp;{item.Value}&nbsp;</small></h6></span>
                            }
                        </li>
                        ))
                    : this.state.status === "Failure" ?
                        <div class="alert alert-danger" role="alert">
                            Something went wrong while loading the reporting status! Please try refreshing the page.
                        </div>
                    : null

                }

                </ul>
              </div>
            </div>
            
        );
    };
};

export default CardReportingStatus;