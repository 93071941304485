import React, { Component } from "react";
import Config from "../Config";
import { FaFileExcel } from "react-icons/fa";
import { saveAll, saveApparel, saveEquipment, saveFootwear } from "./UtilTemplateCostingDownload";

class ModalTemplateCostingDownload extends Component {

    state = {
        costingList : null,
        status : null,
        brand : "All",
        productEngine : "All",
        country : "All"
    };

    onBrandChange = (evt) => {
      this.setState({ brand: evt.target.value });
    };

    onProductEngineChange = (evt) => {
      this.setState({ productEngine: evt.target.value });
    };

    onCountryChange = (evt) => {
      this.setState({ country: evt.target.value });
    };

    getCostingList(){
      fetch(Config.CostingTemplateUrl + "?brand=" + this.state.brand + "&productEngine=" + this.state.productEngine + "&country=" + this.state.country).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(response.status);
        }
      })
      .then((data) => {
        this.setState({ costingList: data }, () => {
          if(this.state.productEngine === "Apparel")
            saveApparel(this.state.brand, this.state.productEngine, this.state.country, this.state.costingList);
          else if(this.state.productEngine === "Equipment")
            saveEquipment(this.state.brand, this.state.productEngine, this.state.country, this.state.costingList);
          else if(this.state.productEngine === "Footwear")
            saveFootwear(this.state.brand, this.state.productEngine, this.state.country, this.state.costingList);
          else
            saveAll(this.state.brand, this.state.productEngine, this.state.country, this.state.costingList);
        }); 
        this.setState({ status: "Success" });
      })
      .catch((error) => {
        this.setState( {status: "Failure"} )
  })};

  

    onDownload = () => {
      this.setState( {status: "Downloading"} );
      this.getCostingList();
    }

    render() {

        return (

        <div class="modal fade" id="costing" tabindex="-1" role="dialog" aria-labelledby="costingModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered  modal-dialog-scrollable modal-l" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                            <h6 class="modal-title" id="costingModalTitle"><FaFileExcel />&nbsp;Template Download</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                       <form>
                            <div class="form-group">
                                <label for="brand">Brand</label>
                                <select class="form-control" id="brand"
                                 value={this.state.brand} onChange={this.onBrandChange}>
                                  <option value="All">All</option>
                                  <option value="Converse">Converse</option>
                                  <option value="Nike">Nike</option>
                                </select>
                              </div>
                              <div class="form-group">
                                <label for="productEngine">Product Engine</label>
                                <select class="form-control" id="productEngine"
                                value={this.state.productEngine} onChange={this.onProductEngineChange}>
                                  <option value="All">All</option>
                                  <option value="Apparel">Apparel</option>
                                  <option value="Equipment">Equipment</option>
                                  <option value="Footwear">Footwear</option>
                                </select>
                              </div>
                              <div class="form-group">
                                <label for="country">Country</label>
                                <select class="form-control" id="country"
                                value={this.state.country} onChange={this.onCountryChange}>
                                  <option value="All">All</option>
                                  <option value="All Except Argentina and Brazil">All Except Argentina and Brazil</option>
                                  <option value="Argentina">Argentina</option>
                                  <option value="Brazil">Brazil</option>
                                </select>
                              </div>
                        </form>

                        {
                                this.state.status === "Downloading" ?

                                <div class="alert alert-info" role="alert">
                                    Downloading template...please wait
                                </div>

                                : this.state.status === "Success" ?

                                <div class="alert alert-success" role="alert">
                                    Finished downloading.
                                </div>

                                : this.state.status === "Failure" ?
                                
                                <div class="alert alert-danger" role="alert">
                                    Something went wrong while downloading the template! Please try again later.
                                </div>

                                : null
                        }

                    </div>
                      
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-sm btn-primary" onClick={this.onDownload}>Download</button>
                    </div>
                </div>
            </div>
        </div>

        );
    };
};

export default ModalTemplateCostingDownload;