//Protect
export const protect = () => {
return {
      formatCells: true,
      formatColumns: true,
      formatRows: true,
      insertRows: false,
      insertColumns: false,
      insertHyperlinks: false,
      deleteRows: false,
      deleteColumns: false,
      sort: true,
      autoFilter: true
  }
}

//fill
export const fill = () => {
  return {
    type: "pattern",
    pattern:"solid",
    fgColor:{ argb:"DCDCDC" }
  }
}

//border
export const border = () => {
  return {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" }
  }
}
  
//file name
export const fileName = (group, brand, productEngine, country) => {
  
  let brandShort = "";
  if(brand === "Converse")
    brandShort = "CON";
  else if (brand === "Nike")
    brandShort = "NKE";
  else
    brandShort = "ALL";

  let prodEngineShort = "";
  if(productEngine === "Apparel")
    prodEngineShort = "APRL";
  else if(productEngine === "Footwear")
    prodEngineShort = "FTWR";
  else if(productEngine === "Equipment")
    prodEngineShort = "EQUP";
  else
    prodEngineShort = "ALL";

  let countryShort = "";
  if (country === "All Except Argentina and Brazil")
    countryShort = "N0_ARG_BRA";
  else
    countryShort = country.toUpperCase().substring(0, 3);


  let name = brandShort + "_" + prodEngineShort + "_" + countryShort;

  if(group === "Costing")
    return "COST_" + name;
  else if(group === "Delivery")
    return "DELI_" + name;
  else if(group === "Quality")
    return "QUAL_" + name;
  else
    return "SUST_" + name;
  
}