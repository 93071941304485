import React, { Component } from "react";
import Config from "../Config";
import FactoryList from "../components/FactoryList";
import { MdBlock } from "react-icons/md";

class ModalExclude extends Component {

    state = {
        selectedFactories: [],
        excludeFactories: [],
        status: null
    };
 
    setSelectedFactories = (val) => {
        this.setState({ selectedFactories: val });
    }

    excludeFactory(){
        fetch(Config.ExcludeFactoryUrl,  {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "factories": this.state.excludeFactories })
          }).then((response) => {
          if (response.ok) {
            return response.json();
          } 
          else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ status: "Success" });
          window.location.reload(false);
        })
        .catch((error) => {
          this.setState( {status: "Failure"} )
      })};

    onExclude = () => {
    
        this.setState({ excludeFactories: [] });
        this.state.selectedFactories.forEach((factory) => {
            this.state.excludeFactories.push(
                {
                    "Id": factory.id
                }
            ) 
        });

        this.setState({ status: null });
        if(this.state.excludeFactories !== null) {
            this.setState( {status: "Excluding"});
            this.excludeFactory();
        };

      };

    

    render() {

        return (

        <div class="modal fade" id="exclude" tabindex="-1" role="dialog" aria-labelledby="excludeModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered  modal-dialog-scrollable modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                       
                            <h6 class="modal-title" id="excludeModalTitle"><MdBlock />&nbsp;Exclude Factory</h6>
                            
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                     
                        <FactoryList 
                            sendSelectedFactories = {this.setSelectedFactories} 
                            section = "ExcludeFactory" />

                            <br />

                            {
                                this.state.status === "Excluding" ?

                                <div class="alert alert-info" role="alert">
                                    Excluding factories...please wait
                                </div>

                                : this.state.status === "Success" ?

                                <div class="alert alert-success" role="alert">
                                    Completed excluding factories.
                                </div>

                                : this.state.status === "Failure" ?
                                
                                <div class="alert alert-danger" role="alert">
                                    Something went wrong while excluding the factory! Please try again later.
                                </div>

                                : null
                            }

                    </div>
                    <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
                        {
                            this.state.selectedFactories.length === 0 ?
                            <button class="btn  btn-primary btn-sm" role="button"  disabled>
                                Exclude
                            </button>
                            :
                            <button class="btn  btn-primary btn-sm" role="button" onClick={this.onExclude}>
                                Exclude
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>

        );
    };
};

export default ModalExclude;