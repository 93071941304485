import React, { Component } from "react";
import BreadCrumb from "../components/BreadCrumb";
import FactoryList from "../components/FactoryList";
import ModalCostingForm from "../components/ModalCostingForm";


class CostingDataEntry extends Component {

    state = {
        selectedFactories: []
    };
 
    setSelectedFactories = (val) => {
        this.setState({ selectedFactories: val });
    }

    render() {
        
        return (
        
            <div class="container-fluid">

                <span>&nbsp;</span>

                <BreadCrumb 
                    home="costing"
                    current="Costing Data Entry" />

                <div class="row">

                    <div class="col-12">

                        <FactoryList 
                            sendSelectedFactories = {this.setSelectedFactories} 
                            section = "Costing" />

                    </div>

                </div>

                <span>&nbsp;</span>

                <div class="row">
                    <div class="col-auto mr-auto">&nbsp;</div>
                    <div class="col-auto">
                        {
                            this.state.selectedFactories.length === 0 ?
                            <button class="btn  btn-outline-primary btn-sm" role="button" data-toggle="modal" data-target="#Costing" disabled>
                                Enter Manufacturing Index Data
                            </button>
                            :
                            <button class="btn  btn-outline-primary btn-sm" role="button" data-toggle="modal" data-target="#Costing">
                                Enter Manufacturing Index Data
                            </button>
                        }
                    </div>
                </div>

                <ModalCostingForm selectedFactories = {this.state.selectedFactories}/>

                <span>&nbsp;</span>

            </div>
        );
    }
}

export default CostingDataEntry;