import React, { Component } from "react";
import { MdContactMail } from "react-icons/md";
import Config from "../Config";

class ModalContact extends Component {

    state = {
        contactList : null,
        status : "Loading"
      };
    
      getContactList(){
        fetch(Config.ContactListUrl).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ contactList: data });
          this.setState({ status: "Success" });
        })
        .catch((error) => {
          this.setState( {status: "Failure"} )
      })};
    
      componentDidMount() {
        this.getContactList();
    };
    
    render() {

        return (

            <div class="modal fade" id="ModalContact" tabindex="-1" role="dialog" aria-labelledby="ModalContactTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title" id="ModalContactTitle"><MdContactMail />&nbsp;Contact</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                    {
                        this.state.status === "Success" ? 
                        this.state.contactList.map((item) => 
                        (
                            <li class="contactList-group-item border-white d-flex justify-content-between align-items-center" key={item.Id}>
                            {item.Group}
                            <span><h6><small>
                                <a class="text-decoration-none" href={"mailto:" + item.Email.replace("&","%26") + "?subject= Manufacturing Index Web Site"}>{item.Email}</a>
                            </small></h6></span>
                            </li>
                        ))
                        : null
                    }
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
                </div>
            </div>
            
        );
    };
};

export default ModalContact;