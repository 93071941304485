import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Header from "./Header";
import QualityHome from "./QualityHome";
import QualityDataEntry from "./QualityDataEntry";
import QualityDataReview from "./QualityDataReview";

const QualityRouter = (props) => {
  return (
      <div>
        <Header user={props.user} />
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={QualityHome} />
            <Route path="/quality" component={QualityHome} />
            <Route path="/qualitydataentry" component={QualityDataEntry} />
            <Route path="/qualitydatareview" component={QualityDataReview} />
          </Switch>
        </BrowserRouter>
      </div>
  );
};

export default QualityRouter;
