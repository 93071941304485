import React, { Component } from "react";
import { FaRegQuestionCircle } from "react-icons/fa";
import Config from "../Config";

class ModalConfirm extends Component {

    state = {
        status : "Loading"
      };

      importDeliveryData(){
        fetch(Config.ImportDeliveryDataUrl,  {
            method: "POST"
          }).then((response) => {
          if (response.ok) {
            return response.json();
          } 
          else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ status: "Success" });
          window.location.reload(false);
        })
        .catch((error) => {
          this.setState( {status: "Failure"} )
      })};
    
      importFactoryData(){
        fetch(Config.ImportFactoryDataUrl,  {
            method: "POST"
          }).then((response) => {
          if (response.ok) {
            return response.json();
          } 
          else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ status: "Success" });
          window.location.reload(false);
        })
        .catch((error) => {
          this.setState( {status: "Failure"} )
      })};

      importSustainabilityData(){
        fetch(Config.ImportSustainabilityDataUrl,  {
            method: "POST"
          }).then((response) => {
          if (response.ok) {
            return response.json();
          } 
          else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ status: "Success" });
          window.location.reload(false);
        })
        .catch((error) => {
          this.setState( {status: "Failure"} )
      })};

      lockReportingPeriod(){
        fetch(Config.LockReportingPeriodUrl,  {
            method: "POST"
          }).then((response) => {
          if (response.ok) {
            return response.json();
          } 
          else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ status: "Success" });
          window.location.reload(false);
        })
        .catch((error) => {
          this.setState( {status: "Failure"} )
      })};

      unlockReportingPeriod(){
        fetch(Config.UnlockReportingPeriodUrl,  {
            method: "POST"
          }).then((response) => {
          if (response.ok) {
            return response.json();
          } 
          else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ status: "Success" });
          window.location.reload(false);
        })
        .catch((error) => {
          this.setState( {status: "Failure"} )
      })};

      updateDataReviewStatus(){
        fetch(Config.DataReviewStatusUrl + "?section=" + this.props.section,  {
            method: "POST"
          }).then((response) => {
          if (response.ok) {
            return response.json();
          } 
          else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ status: "Success" });
          window.location.reload(false);
        })
        .catch((error) => {
          this.setState( {status: "Failure"} )
      })};

      onYes = () => {
          
          if(this.props.modalId === "factory")
          {
            this.setState( {status: "Please wait..."});
            this.importFactoryData();
          }

          if(this.props.modalId === "delivery")
          {
            this.setState( {status: "Please wait..."});
            this.importDeliveryData();
          }
            
          if(this.props.modalId === "sustainability")
          {
            this.setState( {status: "Please wait..."});
            this.importSustainabilityData();
          }
          
          if(this.props.modalId === "lock")
          {
            this.setState( {status: "Please wait..."});
            this.lockReportingPeriod();
          }

          if(this.props.modalId === "unlock")
          {
            this.setState( {status: "Please wait..."});
            this.unlockReportingPeriod();
          }

          if(this.props.modalId === "review")
          {
            this.setState( {status: "Please wait..."});
            this.updateDataReviewStatus();
          }
      };

    render() {

        return (

            <div class="modal fade" id={this.props.modalId} tabindex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h6 class="modal-title" id="modalTitle"><FaRegQuestionCircle />&nbsp;Confirm</h6>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>
                                {this.props.message}
                            </p>

                            {
                                this.state.status === "Please wait..." ?

                                <div class="alert alert-info" role="alert">
                                    {this.state.status}
                                </div>

                                : this.state.status === "Success" ?

                                <div class="alert alert-success" role="alert">
                                    Completed {this.props.action}.
                                </div>

                                : this.state.status === "Failure" ?
                                
                                <div class="alert alert-danger" role="alert">
                                    Something went wrong while {this.props.action}! Please try again later.
                                </div>

                                : null
                            }

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">No</button>
                            <button type="button" class="btn btn-sm btn-primary" onClick={this.onYes}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>
            
        );
    };
};

export default ModalConfirm;