import React, { Component } from "react";
import BreadCrumb from "../components/BreadCrumb";
import DataReviewList from "../components/DataReviewList";

class DeliveryDataReview extends Component {
 
    render() {
        
        return (
        
            <div class="container-fluid">

                <span>&nbsp;</span>

                <BreadCrumb 
                    home="delivery"
                    current="Delivery Data Review" />

              
                <DataReviewList
                    section="Delivery" />

                <span>&nbsp;</span>

            </div>
        );
    }
}

export default DeliveryDataReview;