import React, { Component } from "react";
import Config from "../Config";
import { FaFileExcel } from "react-icons/fa";
import axios from "axios";

class ModalTemplateUpload extends Component {

    constructor () {
        super();
        this.state = {
          file: null,
          status: ""
        };
      }
    
      submitFile = (event) => {
        this.setState({ status: "Uploading" });
        event.preventDefault();
        const formData = new FormData();
        formData.append("file", this.state.file[0]);
        axios.post(Config.TemplateUploadUrl + "?template=" + this.props.template, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then(response => {
            this.setState({ status: "Success" });
            window.location.reload(false);
        }).catch(error => {
            this.setState({ status: "Failure" });
        });
      }

    onChange = (event) => {
        this.setState({status: ""});
        this.setState({file: event.target.files});
    }

    render() {

        return (

        <div class="modal fade" id="upload" tabindex="-1" role="dialog" aria-labelledby="uploadModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered  modal-dialog-scrollable modal-l" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                            <h6 class="modal-title" id="uploadModalTitle"><FaFileExcel />&nbsp;Template Upload</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                    <form>
                        <input label="upload file" type="file" accept=".xlsx" onChange={this.onChange} />
                    </form>
                    <br />

                        {
                                this.state.status === "Uploading" ?

                                <div class="alert alert-info" role="alert">
                                    Uploading template...please wait
                                </div>

                                : this.state.status === "Success" ?

                                <div class="alert alert-success" role="alert">
                                    Finished uploading.
                                </div>

                                : this.state.status === "Failure" ?
                                
                                <div class="alert alert-danger" role="alert">
                                    Something went wrong while uploading the template! Please try again later.
                                </div>

                                : null
                        }

                    </div>
                      
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
                        {
                            this.state.file !== null ? 
                            <button type="button" class="btn btn-sm btn-primary" onClick={this.submitFile}>Upload</button>
                            : <button type="button" class="btn btn-sm btn-primary" disabled>Upload</button>
                        }
                    </div>
                </div>
            </div>
        </div>

        );
    };
};

export default ModalTemplateUpload;