import React, { Component } from "react";
import BreadCrumb from "../components/BreadCrumb";
import FactoryList from "../components/FactoryList";
import ModalDeliveryForm from "../components/ModalDeliveryForm";

class DeliveryDataEntry extends Component {

    state = {
        selectedFactories: []
    };
 
    setSelectedFactories = (val) => {
        this.setState({ selectedFactories: val });
    }

    render() {
        
        return (
        
            <div class="container-fluid">

                <span>&nbsp;</span>

                <BreadCrumb 
                    home="delivery"
                    current="Delivery Data Entry" />


                <div class="row">

                    <div class="col-12">

                        <FactoryList 
                            sendSelectedFactories = {this.setSelectedFactories} 
                            section = "Delivery" />

                    </div>

                </div>

                <span>&nbsp;</span>

                <div class="row">
                    <div class="col-auto mr-auto">
                        &nbsp;
                    </div>
                    <div class="col-auto">
                        {
                            this.state.selectedFactories.length === 0 ?
                            <button class="btn  btn-outline-primary btn-sm" role="button" data-toggle="modal" data-target="#Delivery" disabled>
                                Enter Manufacturing Index Data
                            </button>
                            :
                            <button class="btn  btn-outline-primary btn-sm" role="button" data-toggle="modal" data-target="#Delivery">
                                Enter Manufacturing Index Data
                            </button>
                        }
                    </div>
                </div>

                <ModalDeliveryForm selectedFactories = {this.state.selectedFactories}/>

                <span>&nbsp;</span>

            </div>
        );
    }
}

export default DeliveryDataEntry;