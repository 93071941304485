import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Header from "./Header";
import AdminHome from "./AdminHome";
import CostingHome from "./CostingHome";
import CostingDataEntry from "./CostingDataEntry";
import CostingDataReview from "./CostingDataReview";
import DeliveryHome from "./DeliveryHome";
import DeliveryDataEntry from "./DeliveryDataEntry";
import DeliveryDataReview from "./DeliveryDataReview";
import QualityHome from "./QualityHome";
import QualityDataEntry from "./QualityDataEntry";
import QualityDataReview from "./QualityDataReview";
import SustainabilityHome from "./SustainabilityHome";
import SustainabilityDataEntry from "./SustainabilityDataEntry";
import SustainabilityDataReview from "./SustainabilityDataReview";
import ProgramManagerHome from "./ProgramManagerHome";
import ProgramManagerDataReview from "./ProgramManagerDataReview";
import ProgramManagerViewHistoricalData from "./ProgramManagerViewHistoricalData";
import ProgramManagerReport from "./ProgramManagerReport";

const AdminRouter = (props) => {
  return (
      <div>
        <Header user={props.user} />
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={AdminHome} />
            <Route path="/costing" component={CostingHome} />
            <Route path="/costingdataentry" component={CostingDataEntry} />
            <Route path="/costingdatareview" component={CostingDataReview} />
            <Route path="/delivery" component={DeliveryHome} />
            <Route path="/deliverydataentry" component={DeliveryDataEntry} />
            <Route path="/deliverydatareview" component={DeliveryDataReview} />
            <Route path="/quality" component={QualityHome} />
            <Route path="/qualitydataentry" component={QualityDataEntry} />
            <Route path="/qualitydatareview" component={QualityDataReview} />
            <Route path="/sustainability" component={SustainabilityHome} />
            <Route path="/sustainabilitydataentry" component={SustainabilityDataEntry} />
            <Route path="/sustainabilitydatareview" component={SustainabilityDataReview} />
            <Route path="/programmanager" component={ProgramManagerHome} />
            <Route path="/datareview" component={ProgramManagerDataReview} />
            <Route path="/viewhistoricaldata" component={ProgramManagerViewHistoricalData} />
            <Route path="/report" component={ProgramManagerReport} />
          </Switch>
        </BrowserRouter>
      </div>
  );
};

export default AdminRouter;
