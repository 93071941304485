import React, { Component } from "react";
import BreadCrumb from "../components/BreadCrumb";
import Config from "../Config";

class ProgramManagerViewHistoricalData extends Component {

    state = {
        historicalDataList : [],
        status : null,
        searchFor : "",
        brand : "Nike"
    }

    getHistoricalDataList(){
        fetch(Config.HistoricalDataListUrl + "?searchFor=" + this.state.searchFor + "&brand=" + this.state.brand).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ historicalDataList: data });
          this.setState({ status: "Success" });
        })
        .catch((error) => {
          this.setState( {status: "Failure"} )
    })};

    onSearchForChange = (evt) => {
        this.setState({ searchFor: evt.target.value });
    };

    onBrandChange = (evt) => {
        this.setState({ brand: evt.target.value });
    };

    onSearch = () => {
        this.setState({ status: null });
        if(this.state.searchFor !== "") {
          this.setState( {status: "Searching"});
          this.getHistoricalDataList();
        }
        else
        {
            this.setState( {status: "SearchForMissing"});
        }
    };

    render() {
        
        return (
        
            <div class="container-fluid">

                <span>&nbsp;</span>

                <BreadCrumb 
                    home="programmanager"
                    current="View Historical Manufacturing Index Data" />

                <form>
                    <div class="form-row">
                        <div class="form-group col-sm-8">
                            <label for="searchFor">Factory Name or CR Code <small>(Exact match)</small></label>
                            <input type="text" class="form-control" id="searchFor" onChange={this.onSearchForChange} value={this.state.searchFor}/>
                        </div>
                        <div class="form-group col-sm-4">
                            <label for="group">Brand</label>
                            <select class="form-control" id="group" onChange={this.onBrandChange} value={this.state.brand}>
                                <option value="Nike">Nike</option>
                                <option value="Converse">Converse</option>
                            </select>
                        </div>
                    </div>
                    <button id="search" type="button" class="btn btn-sm btn-primary" onClick={this.onSearch}>Search</button>
                </form>
                <span>&nbsp;</span>
                {
                    this.state.status === "Failure" ? 
                        <div class="alert alert-danger" role="alert">
                            Something went wrong while searching for historical manufacturing index data! Please try again later.
                        </div>
                    : this.state.status === "SearchForMissing" ? 
                        <div class="alert alert-warning" role="alert">
                            Please enter Factory Name or CR Code.
                        </div>
                    : this.state.status === "Searching" ? 
                        <div class="alert alert-info" role="alert">
                            Searching...please wait
                        </div>
                    : this.state.historicalDataList.length === 0 && this.state.status === "Success" ?
                        <div class="alert alert-info" role="alert">
                            No matching factory found or the factory is not producing the selected brand!
                        </div>
                    : this.state.historicalDataList.length > 0 && this.state.status === "Success" ?
                        <div class="accordion" id="viewHistoricalData">
                            {this.state.historicalDataList.map((item) => 
                            (
                            <div class="card border-white">
                                <div class="card-header" id={"heading" + item.Id} >
                                    <h6 class="mb-0">
                                        <button class={this.state.historicalDataList[0].Id === item.Id ? "btn btn-link" : "btn btn-link collapsed"} style={{ textDecoration: "none"}} type="button" data-toggle="collapse" data-target={"#collapse" + item.Id} aria-expanded="true" aria-controls={"collapse" + item.Id}>
                                            {item.FiscalQuarter} 
                                        </button>
                                    </h6>
                                </div>
                        
                                <div id={"collapse" + item.Id} class={this.state.historicalDataList[0].Id === item.Id ? "collapse show" : "collapse"} aria-labelledby={"heading" + item.Id} data-parent="#viewHistoricalData">
                                    <div class="card-body bg-light">
                                        <div class="card-deck">

                                            <div class="card bg-white border-white">
                                                <div class="card-body">
                                                    <h6 class="card-title">
                                                        <li class="list-group-item d-flex justify-content-between align-items-center border-white">
                                                            Costing
                                                            <span class="badge badge-success badge-pill">{item.MiCostingScore}</span>
                                                        </li>
                                                    </h6>
                                                    <hr />
                                                    <p class="card-text">{item.CostingNonCompliantReason}</p>
                                                </div>
                                            </div>

                                            <div class="card bg-white border-white">
                                                <div class="card-body">
                                                    <h6 class="card-title">
                                                        <li class="list-group-item d-flex justify-content-between align-items-center border-white">
                                                            Delivery
                                                            <span class="badge badge-success badge-pill">{item.MiDeliveryScore}</span>
                                                        </li>
                                                    </h6>
                                                    <hr />
                                                    <p class="card-text">{item.DeliveryNonCompliantReason}</p>
                                                </div>
                                            </div>

                                            <div class="card bg-white border-white">
                                                <div class="card-body">
                                                    <h6 class="card-title">
                                                        <li class="list-group-item d-flex justify-content-between align-items-center border-white">
                                                            Quality
                                                            <span class="badge badge-success badge-pill">{item.MiQualityScore}</span>
                                                        </li>
                                                    </h6>
                                                    <hr />
                                                    <p class="card-text">{item.QualityNonCompliantReason}</p>
                                                </div>
                                            </div>

                                            <div class="card bg-white border-white">
                                                <div class="card-body">
                                                    <h6 class="card-title">
                                                        <li class="list-group-item d-flex justify-content-between align-items-center border-white">
                                                            Sustainability
                                                            <span class="badge badge-success badge-pill">{item.MiSmsiScore}</span>
                                                        </li>
                                                    </h6>
                                                    <hr />
                                                    <p class="card-text">{item.SmsiNonCompliantReason}</p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ))}
                        </div>
                    : null
                }
                <span>&nbsp;</span>
            </div>
        );
    }
}

export default ProgramManagerViewHistoricalData;