import React, { Component } from "react";
import BreadCrumb from "../components/BreadCrumb";

class ProgramManagerReport extends Component {
 
    render() {
        
        return (
        
            <div class="container-fluid">

                <span>&nbsp;</span>

                <BreadCrumb 
                    home="programmanager"
                    current="Report" />

                <span>&nbsp;</span>

                <div class="alert alert-info" role="alert">
                    This feature is not available at this time! Please check back later.
                </div>

            </div>
        );
    }
}

export default ProgramManagerReport;