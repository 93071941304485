import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Header from "./Header";
import CostingHome from "./CostingHome";
import CostingDataEntry from "./CostingDataEntry";
import CostingDataReview from "./CostingDataReview";

const CostingRouter = (props) => {
  return (
      <div>
        <Header user={props.user} />
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={CostingHome} />
            <Route path="/costing" component={CostingHome} />
            <Route path="/costingdataentry" component={CostingDataEntry} />
            <Route path="/costingdatareview" component={CostingDataReview} />
          </Switch>
        </BrowserRouter>
      </div>
  );
};

export default CostingRouter;
