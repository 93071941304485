import React, { Component } from "react";
import { IoMdCheckboxOutline } from "react-icons/io";
import ModalConfirm from "./ModalConfirm";

class CardDataStatus extends Component {

    render() {

        return (

            <div class="card border-white">
              <div class="card-body">
                <h6 class="card-title">
                    <IoMdCheckboxOutline />&nbsp;Manufacturing Index Data Status
                </h6>
                <div class="border-top my-2"></div>
                <p class="card-text">
                    Confirm the collected manufacturing index data is reviewed 
                    and the data can be published for reporting.
                </p>
                <button class="btn  btn-outline-primary btn-sm" role="button" data-toggle="modal" data-target="#review">
                    Confirm
                </button>
                <br />
                    <ModalConfirm modalId="review" 
                    action="confirming data status"
                    section={this.props.section}
                    message="Do you want to confirm the collected manufacturing index data is reviewed and the data can be published for reporting?" />
                </div>
              
            </div>
            
        );
    };
};

export default CardDataStatus;