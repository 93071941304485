import React, { Component } from "react";
import Config from "../Config";
import { TiInfoOutline } from "react-icons/ti";

class ModalCostingForm extends Component {

    state = {
        costingList : [],
        postStatus : null,
        getStatus : null,
        MiCostingScore : "",
        FootwearCompRawScore : "",
        FootwearCompPts : "",
        FootwearAccToolRawScore : "",
        FootwearAccToolPts : "",
        FootwearAccCbdRejectRawScore : "",
        FootwearAccCbdPts : "",
        FootwearTimeQuoteRawScore : "",
        FootwearTimeQuotePts : "",
        FootwearTimeGac35RawScore : "",
        FootwearTimeGac35Pts : "",
        ApparelCompWeight : "",
        ApparelCompPts : "",
        ApparelAccMaterialUtRawScore : "",
        ApparelAccWeight : "",
        ApparelAccPts : "",
        ApparelTimeMissFobRawScore : "",
        ApparelTimeWeight : "",
        ApparelTimePts : "",
        ApparelCompCmpMmRawScore : "",
        ApparelCompYarnRawScore : "",
        ApparelAccStitchCtRawScore : "",
        EquipmentCompCmpRangeRawScore : "",
        EquipmentCompWeight : "",
        EquipmentCompPts : "",
        EquipmentAccMaterialUtRawScore : "",
        EquipmentAccPrintRawScore : "",
        EquipmentAccWeight : "",
        EquipmentAccPts : "",
        EquipmentTimeMissFobRawScore : "",
        EquipmentTimeWeight : "",
        EquipmentTimePts : "",
        CostingNonCompliantReason : "",        
        MiCostingScoreValid : true,
        FootwearCompRawScoreValid : true,
        FootwearCompPtsValid : true,
        FootwearAccToolRawScoreValid : true,
        FootwearAccToolPtsValid : true,
        FootwearAccCbdRejectRawScoreValid : true,
        FootwearAccCbdPtsValid : true,
        FootwearTimeQuoteRawScoreValid : true,
        FootwearTimeQuotePtsValid : true,
        FootwearTimeGac35RawScoreValid : true,
        FootwearTimeGac35PtsValid : true,
        ApparelCompWeightValid : true,
        ApparelCompPtsValid : true,
        ApparelAccMaterialUtRawScoreValid : true,
        ApparelAccWeightValid : true,
        ApparelAccPtsValid : true,
        ApparelTimeMissFobRawScoreValid : true,
        ApparelTimeWeightValid : true,
        ApparelTimePtsValid : true,
        ApparelCompCmpMmRawScoreValid : true,
        ApparelCompYarnRawScoreValid : true,
        ApparelAccStitchCtRawScoreValid : true,
        EquipmentCompCmpRangeRawScoreValid : true,
        EquipmentCompWeightValid : true,
        EquipmentCompPtsValid : true,
        EquipmentAccMaterialUtRawScoreValid : true,
        EquipmentAccPrintRawScoreValid : true,
        EquipmentAccWeightValid : true,
        EquipmentAccPtsValid : true,
        EquipmentTimeMissFobRawScoreValid : true,
        EquipmentTimeWeightValid : true,
        EquipmentTimePtsValid : true
    };

    readCosting(){
        fetch(Config.CostingReadUrl + "?factoryid=" + this.props.selectedFactories[0].id).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.bind(data);
          this.setState({ getStatus: "Success" });
        })
        .catch((error) => {
          this.setState( {getStatus: "Failure"} )
    })};

    bind(data) {

        this.setState({ MiCostingScore: data[0].MiCostingScore});
        this.setState({ FootwearCompRawScore: data[0].FootwearCompRawScore});
        this.setState({ FootwearCompPts: data[0].FootwearCompPts});
        this.setState({ FootwearAccToolRawScore: data[0].FootwearAccToolRawScore});
        this.setState({ FootwearAccToolPts: data[0].FootwearAccToolPts});
        this.setState({ FootwearAccCbdRejectRawScore: data[0].FootwearAccCbdRejectRawScore});
        this.setState({ FootwearAccCbdPts: data[0].FootwearAccCbdPts});
        this.setState({ FootwearTimeQuoteRawScore: data[0].FootwearTimeQuoteRawScore});
        this.setState({ FootwearTimeQuotePts: data[0].FootwearTimeQuotePts});
        this.setState({ FootwearTimeGac35RawScore: data[0].FootwearTimeGac35RawScore});
        this.setState({ FootwearTimeGac35Pts: data[0].FootwearTimeGac35Pts});
        this.setState({ ApparelCompWeight: data[0].ApparelCompWeight});
        this.setState({ ApparelCompPts: data[0].ApparelCompPts});
        this.setState({ ApparelAccMaterialUtRawScore: data[0].ApparelAccMaterialUtRawScore});
        this.setState({ ApparelAccWeight: data[0].ApparelAccWeight});
        this.setState({ ApparelAccPts: data[0].ApparelAccPts});
        this.setState({ ApparelTimeMissFobRawScore: data[0].ApparelTimeMissFobRawScore});
        this.setState({ ApparelTimeWeight: data[0].ApparelTimeWeight});
        this.setState({ ApparelTimePts: data[0].ApparelTimePts});
        this.setState({ ApparelCompCmpMmRawScore: data[0].ApparelCompCmpMmRawScore});
        this.setState({ ApparelCompYarnRawScore: data[0].ApparelCompYarnRawScore});
        this.setState({ ApparelAccStitchCtRawScore: data[0].ApparelAccStitchCtRawScore});
        this.setState({ EquipmentCompCmpRangeRawScore: data[0].EquipmentCompCmpRangeRawScore});
        this.setState({ EquipmentCompWeight: data[0].EquipmentCompWeight});
        this.setState({ EquipmentCompPts: data[0].EquipmentCompPts});
        this.setState({ EquipmentAccMaterialUtRawScore: data[0].EquipmentAccMaterialUtRawScore});
        this.setState({ EquipmentAccPrintRawScore: data[0].EquipmentAccPrintRawScore});
        this.setState({ EquipmentAccWeight: data[0].EquipmentAccWeight});
        this.setState({ EquipmentAccPts: data[0].EquipmentAccPts});
        this.setState({ EquipmentTimeMissFobRawScore: data[0].EquipmentTimeMissFobRawScore});
        this.setState({ EquipmentTimeWeight: data[0].EquipmentTimeWeight});
        this.setState({ EquipmentTimePts: data[0].EquipmentTimePts});
        this.setState({ CostingNonCompliantReason: data[0].CostingNonCompliantReason});
        
    }

    unbind() {

        this.setState({ MiCostingScore: ""});
        this.setState({ FootwearCompRawScore: ""});
        this.setState({ FootwearCompPts: ""});
        this.setState({ FootwearAccToolRawScore: ""});
        this.setState({ FootwearAccToolPts: ""});
        this.setState({ FootwearAccCbdRejectRawScore: ""});
        this.setState({ FootwearAccCbdPts: ""});
        this.setState({ FootwearTimeQuoteRawScore: ""});
        this.setState({ FootwearTimeQuotePts: ""});
        this.setState({ FootwearTimeGac35RawScore: ""});
        this.setState({ FootwearTimeGac35Pts: ""});
        this.setState({ ApparelCompWeight: ""});
        this.setState({ ApparelCompPts: ""});
        this.setState({ ApparelAccMaterialUtRawScore: ""});
        this.setState({ ApparelAccWeight: ""});
        this.setState({ ApparelAccPts: ""});
        this.setState({ ApparelTimeMissFobRawScore: ""});
        this.setState({ ApparelTimeWeight: ""});
        this.setState({ ApparelTimePts: ""});
        this.setState({ ApparelCompCmpMmRawScore: ""});
        this.setState({ ApparelCompYarnRawScore: ""});
        this.setState({ ApparelAccStitchCtRawScore: ""});
        this.setState({ EquipmentCompCmpRangeRawScore: ""});
        this.setState({ EquipmentCompWeight: ""});
        this.setState({ EquipmentCompPts: ""});
        this.setState({ EquipmentAccMaterialUtRawScore: ""});
        this.setState({ EquipmentAccPrintRawScore: ""});
        this.setState({ EquipmentAccWeight: ""});
        this.setState({ EquipmentAccPts: ""});
        this.setState({ EquipmentTimeMissFobRawScore: ""});
        this.setState({ EquipmentTimeWeight: ""});
        this.setState({ EquipmentTimePts: ""});
        this.setState({ CostingNonCompliantReason: ""});
        
    }

    componentDidUpdate(prevProps) {
        if(this.props.action === "Data Review") {
            if (this.props.selectedFactories !== prevProps.selectedFactories) {
                this.unbind();
                this.readCosting();
            }
        }
    };

    setValue = (field, event) => {
        var object = {};
        object[field] = event.target.value;

        if(field === "CostingNonCompliantReason") {
            this.setState(object);
        }
        else {
            this.setState(object, this.validateField(field, event.target.value));
        }
    }

    validateField = (field, value) => {
        if (value.match(/^\d*((\d\.)|(\.\d))?\d{0,2}$/) === null) {
          switch(field) {
            case "MiCostingScore":
            this.setState({ MiCostingScoreValid: false });
            break;
            case "FootwearCompRawScore":
            this.setState({ FootwearCompRawScoreValid: false });
            break;
            case "FootwearCompPts":
            this.setState({ FootwearCompPtsValid: false });
            break;
            case "FootwearAccToolRawScore":
            this.setState({ FootwearAccToolRawScoreValid: false });
            break;
            case "FootwearAccToolPts":
            this.setState({ FootwearAccToolPtsValid: false });
            break;
            case "FootwearAccCbdRejectRawScore":
            this.setState({ FootwearAccCbdRejectRawScoreValid: false });
            break;
            case "FootwearAccCbdPts":
            this.setState({ FootwearAccCbdPtsValid: false });
            break;
            case "FootwearTimeQuoteRawScore":
            this.setState({ FootwearTimeQuoteRawScoreValid: false });
            break;
            case "FootwearTimeQuotePts":
            this.setState({ FootwearTimeQuotePtsValid: false });
            break;
            case "FootwearTimeGac35RawScore":
            this.setState({ FootwearTimeGac35RawScoreValid: false });
            break;
            case "FootwearTimeGac35Pts":
            this.setState({ FootwearTimeGac35PtsValid: false });
            break;
            case "ApparelCompWeight":
            this.setState({ ApparelCompWeightValid: false });
            break;
            case "ApparelCompPts":
            this.setState({ ApparelCompPtsValid: false });
            break;
            case "ApparelAccMaterialUtRawScore":
            this.setState({ ApparelAccMaterialUtRawScoreValid: false });
            break;
            case "ApparelAccWeight":
            this.setState({ ApparelAccWeightValid: false });
            break;
            case "ApparelAccPts":
            this.setState({ ApparelAccPtsValid: false });
            break;
            case "ApparelTimeMissFobRawScore":
            this.setState({ ApparelTimeMissFobRawScoreValid: false });
            break;
            case "ApparelTimeWeight":
            this.setState({ ApparelTimeWeightValid: false });
            break;
            case "ApparelTimePts":
            this.setState({ ApparelTimePtsValid: false });
            break;
            case "ApparelCompCmpMmRawScore":
            this.setState({ ApparelCompCmpMmRawScoreValid: false });
            break;
            case "ApparelCompYarnRawScore":
            this.setState({ ApparelCompYarnRawScoreValid: false });
            break;
            case "ApparelAccStitchCtRawScore":
            this.setState({ ApparelAccStitchCtRawScoreValid: false });
            break;
            case "EquipmentCompCmpRangeRawScore":
            this.setState({ EquipmentCompCmpRangeRawScoreValid: false });
            break;
            case "EquipmentCompWeight":
            this.setState({ EquipmentCompWeightValid: false });
            break;
            case "EquipmentCompPts":
            this.setState({ EquipmentCompPtsValid: false });
            break;
            case "EquipmentAccMaterialUtRawScore":
            this.setState({ EquipmentAccMaterialUtRawScoreValid: false });
            break;
            case "EquipmentAccPrintRawScore":
            this.setState({ EquipmentAccPrintRawScoreValid: false });
            break;
            case "EquipmentAccWeight":
            this.setState({ EquipmentAccWeightValid: false });
            break;
            case "EquipmentAccPts":
            this.setState({ EquipmentAccPtsValid: false });
            break;
            case "EquipmentTimeMissFobRawScore":
            this.setState({ EquipmentTimeMissFobRawScoreValid: false });
            break;
            case "EquipmentTimeWeight":
            this.setState({ EquipmentTimeWeightValid: false });
            break;
            case "EquipmentTimePts":
            this.setState({ EquipmentTimePtsValid: false });
            break;
            default:
              // code block
          }
        }
        else {
            switch(field) {
            case "MiCostingScore":
            this.setState({ MiCostingScoreValid: true });
            break;
            case "FootwearCompRawScore":
            this.setState({ FootwearCompRawScoreValid: true });
            break;
            case "FootwearCompPts":
            this.setState({ FootwearCompPtsValid: true });
            break;
            case "FootwearAccToolRawScore":
            this.setState({ FootwearAccToolRawScoreValid: true });
            break;
            case "FootwearAccToolPts":
            this.setState({ FootwearAccToolPtsValid: true });
            break;
            case "FootwearAccCbdRejectRawScore":
            this.setState({ FootwearAccCbdRejectRawScoreValid: true });
            break;
            case "FootwearAccCbdPts":
            this.setState({ FootwearAccCbdPtsValid: true });
            break;
            case "FootwearTimeQuoteRawScore":
            this.setState({ FootwearTimeQuoteRawScoreValid: true });
            break;
            case "FootwearTimeQuotePts":
            this.setState({ FootwearTimeQuotePtsValid: true });
            break;
            case "FootwearTimeGac35RawScore":
            this.setState({ FootwearTimeGac35RawScoreValid: true });
            break;
            case "FootwearTimeGac35Pts":
            this.setState({ FootwearTimeGac35PtsValid: true });
            break;
            case "ApparelCompWeight":
            this.setState({ ApparelCompWeightValid: true });
            break;
            case "ApparelCompPts":
            this.setState({ ApparelCompPtsValid: true });
            break;
            case "ApparelAccMaterialUtRawScore":
            this.setState({ ApparelAccMaterialUtRawScoreValid: true });
            break;
            case "ApparelAccWeight":
            this.setState({ ApparelAccWeightValid: true });
            break;
            case "ApparelAccPts":
            this.setState({ ApparelAccPtsValid: true });
            break;
            case "ApparelTimeMissFobRawScore":
            this.setState({ ApparelTimeMissFobRawScoreValid: true });
            break;
            case "ApparelTimeWeight":
            this.setState({ ApparelTimeWeightValid: true });
            break;
            case "ApparelTimePts":
            this.setState({ ApparelTimePtsValid: true });
            break;
            case "ApparelCompCmpMmRawScore":
            this.setState({ ApparelCompCmpMmRawScoreValid: true });
            break;
            case "ApparelCompYarnRawScore":
            this.setState({ ApparelCompYarnRawScoreValid: true });
            break;
            case "ApparelAccStitchCtRawScore":
            this.setState({ ApparelAccStitchCtRawScoreValid: true });
            break;
            case "EquipmentCompCmpRangeRawScore":
            this.setState({ EquipmentCompCmpRangeRawScoreValid: true });
            break;
            case "EquipmentCompWeight":
            this.setState({ EquipmentCompWeightValid: true });
            break;
            case "EquipmentCompPts":
            this.setState({ EquipmentCompPtsValid: true });
            break;
            case "EquipmentAccMaterialUtRawScore":
            this.setState({ EquipmentAccMaterialUtRawScoreValid: true });
            break;
            case "EquipmentAccPrintRawScore":
            this.setState({ EquipmentAccPrintRawScoreValid: true });
            break;
            case "EquipmentAccWeight":
            this.setState({ EquipmentAccWeightValid: true });
            break;
            case "EquipmentAccPts":
            this.setState({ EquipmentAccPtsValid: true });
            break;
            case "EquipmentTimeMissFobRawScore":
            this.setState({ EquipmentTimeMissFobRawScoreValid: true });
            break;
            case "EquipmentTimeWeight":
            this.setState({ EquipmentTimeWeightValid: true });
            break;
            case "EquipmentTimePts":
            this.setState({ EquipmentTimePtsValid: true });
            break;
            default:
                  // code block
              }
        }

    }

    validateForm = () => {
        if(this.state.MiCostingScoreValid === true &&
            this.state.FootwearCompRawScoreValid === true &&
            this.state.FootwearCompPtsValid === true &&
            this.state.FootwearAccToolRawScoreValid === true &&
            this.state.FootwearAccToolPtsValid === true &&
            this.state.FootwearAccCbdRejectRawScoreValid === true &&
            this.state.FootwearAccCbdPtsValid === true &&
            this.state.FootwearTimeQuoteRawScoreValid === true &&
            this.state.FootwearTimeQuotePtsValid === true &&
            this.state.FootwearTimeGac35RawScoreValid === true &&
            this.state.FootwearTimeGac35PtsValid === true &&
            this.state.ApparelCompWeightValid === true &&
            this.state.ApparelCompPtsValid === true &&
            this.state.ApparelAccMaterialUtRawScoreValid === true &&
            this.state.ApparelAccWeightValid === true &&
            this.state.ApparelAccPtsValid === true &&
            this.state.ApparelTimeMissFobRawScoreValid === true &&
            this.state.ApparelTimeWeightValid === true &&
            this.state.ApparelTimePtsValid === true &&
            this.state.ApparelCompCmpMmRawScoreValid === true &&
            this.state.ApparelCompYarnRawScoreValid === true &&
            this.state.ApparelAccStitchCtRawScoreValid === true &&
            this.state.EquipmentCompCmpRangeRawScoreValid === true &&
            this.state.EquipmentCompWeightValid === true &&
            this.state.EquipmentCompPtsValid === true &&
            this.state.EquipmentAccMaterialUtRawScoreValid === true &&
            this.state.EquipmentAccPrintRawScoreValid === true &&
            this.state.EquipmentAccWeightValid === true &&
            this.state.EquipmentAccPtsValid === true &&
            this.state.EquipmentTimeMissFobRawScoreValid === true &&
            this.state.EquipmentTimeWeightValid === true &&
            this.state.EquipmentTimePtsValid === true) 
            return true;
        else 
            return false;
    }

    onSave = () => {

        this.setState({ costingList: [] });
        this.props.selectedFactories.forEach((factory) => {
            this.state.costingList.push(
                {
                    "FactoryId": factory.id,
                    "MiCostingScore": this.state.MiCostingScore,
                    "FootwearCompRawScore": this.state.FootwearCompRawScore,
                    "FootwearCompPts": this.state.FootwearCompPts,
                    "FootwearAccToolRawScore": this.state.FootwearAccToolRawScore,
                    "FootwearAccToolPts": this.state.FootwearAccToolPts,
                    "FootwearAccCbdRejectRawScore": this.state.FootwearAccCbdRejectRawScore,
                    "FootwearAccCbdPts": this.state.FootwearAccCbdPts,
                    "FootwearTimeQuoteRawScore": this.state.FootwearTimeQuoteRawScore,
                    "FootwearTimeQuotePts": this.state.FootwearTimeQuotePts,
                    "FootwearTimeGac35RawScore": this.state.FootwearTimeGac35RawScore,
                    "FootwearTimeGac35Pts": this.state.FootwearTimeGac35Pts,
                    "ApparelCompWeight": this.state.ApparelCompWeight,
                    "ApparelCompPts": this.state.ApparelCompPts,
                    "ApparelAccMaterialUtRawScore": this.state.ApparelAccMaterialUtRawScore,
                    "ApparelAccWeight": this.state.ApparelAccWeight,
                    "ApparelAccPts": this.state.ApparelAccPts,
                    "ApparelTimeMissFobRawScore": this.state.ApparelTimeMissFobRawScore,
                    "ApparelTimeWeight": this.state.ApparelTimeWeight,
                    "ApparelTimePts": this.state.ApparelTimePts,
                    "ApparelCompCmpMmRawScore": this.state.ApparelCompCmpMmRawScore,
                    "ApparelCompYarnRawScore": this.state.ApparelCompYarnRawScore,
                    "ApparelAccStitchCtRawScore": this.state.ApparelAccStitchCtRawScore,
                    "EquipmentCompCmpRangeRawScore": this.state.EquipmentCompCmpRangeRawScore,
                    "EquipmentCompWeight": this.state.EquipmentCompWeight,
                    "EquipmentCompPts": this.state.EquipmentCompPts,
                    "EquipmentAccMaterialUtRawScore": this.state.EquipmentAccMaterialUtRawScore,
                    "EquipmentAccPrintRawScore": this.state.EquipmentAccPrintRawScore,
                    "EquipmentAccWeight": this.state.EquipmentAccWeight,
                    "EquipmentAccPts": this.state.EquipmentAccPts,
                    "EquipmentTimeMissFobRawScore": this.state.EquipmentTimeMissFobRawScore,
                    "EquipmentTimeWeight": this.state.EquipmentTimeWeight,
                    "EquipmentTimePts": this.state.EquipmentTimePts,
                    "CostingNonCompliantReason": this.state.CostingNonCompliantReason                    
                    
                }
            ) 
        });

        this.setState({ postStatus: null });
        if(this.state.costingList !== null) {
            this.setState( {postStatus: "Posting"});
            this.saveCosting();
        };

    };

    saveCosting(){
        fetch(Config.CostingSaveUrl,  {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "costing": this.state.costingList })
          }).then((response) => {
          if (response.ok) {
            return response.json();
          } 
          else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ postStatus: "Success" });
          window.location.reload(false);
        })
        .catch((error) => {
          this.setState( {postStatus: "Failure"} )
    })};

    render() {

        return (

        <div class="modal fade" id="Costing" tabindex="-1" role="dialog" aria-labelledby="CostingModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered  modal-dialog-scrollable modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        {
                            this.props.selectedFactories.length === 1 ?
                            <h6 class="modal-title" id="CostingModalTitle">Enter Manufacturing Index Data - {this.props.selectedFactories[0].label.split("-")[1]}</h6>
                            :
                            <h6 class="modal-title" id="CostingModalTitle">Enter Manufacturing Index Data - {this.props.selectedFactories.length} Selected Factories</h6>
                        }
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p><small><TiInfoOutline />&nbsp;Use the TAB key to move from field to field.</small></p>
                        <form>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="MiCostingScore">MiCostingScore&nbsp;<span class="text-danger">{ this.state.MiCostingScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="MiCostingScore" value ={this.state.MiCostingScore} onChange={this.setValue.bind(this, "MiCostingScore")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="FootwearCompRawScore">FootwearCompRawScore&nbsp;<span class="text-danger">{ this.state.FootwearCompRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="FootwearCompRawScore"value ={this.state.FootwearCompRawScore} onChange={this.setValue.bind(this, "FootwearCompRawScore")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="FootwearCompPts">FootwearCompPts&nbsp;<span class="text-danger">{ this.state.FootwearCompPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="FootwearCompPts" value ={this.state.FootwearCompPts} onChange={this.setValue.bind(this, "FootwearCompPts")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="FootwearAccToolRawScore">FootwearAccToolRawScore&nbsp;<span class="text-danger">{ this.state.FootwearAccToolRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="FootwearAccToolRawScore"value ={this.state.FootwearAccToolRawScore} onChange={this.setValue.bind(this, "FootwearAccToolRawScore")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="FootwearAccToolPts">FootwearAccToolPts&nbsp;<span class="text-danger">{ this.state.FootwearAccToolPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="FootwearAccToolPts" value ={this.state.FootwearAccToolPts} onChange={this.setValue.bind(this, "FootwearAccToolPts")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="FootwearAccCbdRejectRawScore">FootwearAccCbdRejectRawScore&nbsp;<span class="text-danger">{ this.state.FootwearAccCbdRejectRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="FootwearAccCbdRejectRawScore"value ={this.state.FootwearAccCbdRejectRawScore} onChange={this.setValue.bind(this, "FootwearAccCbdRejectRawScore")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="FootwearAccCbdPts">FootwearAccCbdPts&nbsp;<span class="text-danger">{ this.state.FootwearAccCbdPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="FootwearAccCbdPts" value ={this.state.FootwearAccCbdPts} onChange={this.setValue.bind(this, "FootwearAccCbdPts")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="FootwearTimeQuoteRawScore">FootwearTimeQuoteRawScore&nbsp;<span class="text-danger">{ this.state.FootwearTimeQuoteRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="FootwearTimeQuoteRawScore"value ={this.state.FootwearTimeQuoteRawScore} onChange={this.setValue.bind(this, "FootwearTimeQuoteRawScore")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="FootwearTimeQuotePts">FootwearTimeQuotePts&nbsp;<span class="text-danger">{ this.state.FootwearTimeQuotePtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="FootwearTimeQuotePts" value ={this.state.FootwearTimeQuotePts} onChange={this.setValue.bind(this, "FootwearTimeQuotePts")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="FootwearTimeGac35RawScore">FootwearTimeGac35RawScore&nbsp;<span class="text-danger">{ this.state.FootwearTimeGac35RawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="FootwearTimeGac35RawScore"value ={this.state.FootwearTimeGac35RawScore} onChange={this.setValue.bind(this, "FootwearTimeGac35RawScore")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="FootwearTimeGac35Pts">FootwearTimeGac35Pts&nbsp;<span class="text-danger">{ this.state.FootwearTimeGac35PtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="FootwearTimeGac35Pts" value ={this.state.FootwearTimeGac35Pts} onChange={this.setValue.bind(this, "FootwearTimeGac35Pts")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="ApparelCompWeight">ApparelCompWeight&nbsp;<span class="text-danger">{ this.state.ApparelCompWeightValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="ApparelCompWeight"value ={this.state.ApparelCompWeight} onChange={this.setValue.bind(this, "ApparelCompWeight")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="ApparelCompPts">ApparelCompPts&nbsp;<span class="text-danger">{ this.state.ApparelCompPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="ApparelCompPts" value ={this.state.ApparelCompPts} onChange={this.setValue.bind(this, "ApparelCompPts")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="ApparelAccMaterialUtRawScore">ApparelAccMaterialUtRawScore&nbsp;<span class="text-danger">{ this.state.ApparelAccMaterialUtRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="ApparelAccMaterialUtRawScore"value ={this.state.ApparelAccMaterialUtRawScore} onChange={this.setValue.bind(this, "ApparelAccMaterialUtRawScore")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="ApparelAccWeight">ApparelAccWeight&nbsp;<span class="text-danger">{ this.state.ApparelAccWeightValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="ApparelAccWeight" value ={this.state.ApparelAccWeight} onChange={this.setValue.bind(this, "ApparelAccWeight")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="ApparelAccPts">ApparelAccPts&nbsp;<span class="text-danger">{ this.state.ApparelAccPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="ApparelAccPts"value ={this.state.ApparelAccPts} onChange={this.setValue.bind(this, "ApparelAccPts")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="ApparelTimeMissFobRawScore">ApparelTimeMissFobRawScore&nbsp;<span class="text-danger">{ this.state.ApparelTimeMissFobRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="ApparelTimeMissFobRawScore" value ={this.state.ApparelTimeMissFobRawScore} onChange={this.setValue.bind(this, "ApparelTimeMissFobRawScore")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="ApparelTimeWeight">ApparelTimeWeight&nbsp;<span class="text-danger">{ this.state.ApparelTimeWeightValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="ApparelTimeWeight"value ={this.state.ApparelTimeWeight} onChange={this.setValue.bind(this, "ApparelTimeWeight")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="ApparelTimePts">ApparelTimePts&nbsp;<span class="text-danger">{ this.state.ApparelTimePtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="ApparelTimePts" value ={this.state.ApparelTimePts} onChange={this.setValue.bind(this, "ApparelTimePts")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="ApparelCompCmpMmRawScore">ApparelCompCmpMmRawScore&nbsp;<span class="text-danger">{ this.state.ApparelCompCmpMmRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="ApparelCompCmpMmRawScore"value ={this.state.ApparelCompCmpMmRawScore} onChange={this.setValue.bind(this, "ApparelCompCmpMmRawScore")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="ApparelCompYarnRawScore">ApparelCompYarnRawScore&nbsp;<span class="text-danger">{ this.state.ApparelCompYarnRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="ApparelCompYarnRawScore" value ={this.state.ApparelCompYarnRawScore} onChange={this.setValue.bind(this, "ApparelCompYarnRawScore")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="ApparelAccStitchCtRawScore">ApparelAccStitchCtRawScore&nbsp;<span class="text-danger">{ this.state.ApparelAccStitchCtRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="ApparelAccStitchCtRawScore"value ={this.state.ApparelAccStitchCtRawScore} onChange={this.setValue.bind(this, "ApparelAccStitchCtRawScore")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="EquipmentCompCmpRangeRawScore">EquipmentCompCmpRangeRawScore&nbsp;<span class="text-danger">{ this.state.EquipmentCompCmpRangeRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="EquipmentCompCmpRangeRawScore" value ={this.state.EquipmentCompCmpRangeRawScore} onChange={this.setValue.bind(this, "EquipmentCompCmpRangeRawScore")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="EquipmentCompWeight">EquipmentCompWeight&nbsp;<span class="text-danger">{ this.state.EquipmentCompWeightValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="EquipmentCompWeight"value ={this.state.EquipmentCompWeight} onChange={this.setValue.bind(this, "EquipmentCompWeight")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="EquipmentCompPts">EquipmentCompPts&nbsp;<span class="text-danger">{ this.state.EquipmentCompPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="EquipmentCompPts" value ={this.state.EquipmentCompPts} onChange={this.setValue.bind(this, "EquipmentCompPts")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="EquipmentAccMaterialUtRawScore">EquipmentAccMaterialUtRawScore&nbsp;<span class="text-danger">{ this.state.EquipmentAccMaterialUtRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="EquipmentAccMaterialUtRawScore"value ={this.state.EquipmentAccMaterialUtRawScore} onChange={this.setValue.bind(this, "EquipmentAccMaterialUtRawScore")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="EquipmentAccPrintRawScore">EquipmentAccPrintRawScore&nbsp;<span class="text-danger">{ this.state.EquipmentAccPrintRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="EquipmentAccPrintRawScore" value ={this.state.EquipmentAccPrintRawScore} onChange={this.setValue.bind(this, "EquipmentAccPrintRawScore")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="EquipmentAccWeight">EquipmentAccWeight&nbsp;<span class="text-danger">{ this.state.EquipmentAccWeightValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="EquipmentAccWeight"value ={this.state.EquipmentAccWeight} onChange={this.setValue.bind(this, "EquipmentAccWeight")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="EquipmentAccPts">EquipmentAccPts&nbsp;<span class="text-danger">{ this.state.EquipmentAccPtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="EquipmentAccPts" value ={this.state.EquipmentAccPts} onChange={this.setValue.bind(this, "EquipmentAccPts")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="EquipmentTimeMissFobRawScore">EquipmentTimeMissFobRawScore&nbsp;<span class="text-danger">{ this.state.EquipmentTimeMissFobRawScoreValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="EquipmentTimeMissFobRawScore"value ={this.state.EquipmentTimeMissFobRawScore} onChange={this.setValue.bind(this, "EquipmentTimeMissFobRawScore")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-6">
                                <label for="EquipmentTimeWeight">EquipmentTimeWeight&nbsp;<span class="text-danger">{ this.state.EquipmentTimeWeightValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="EquipmentTimeWeight" value ={this.state.EquipmentTimeWeight} onChange={this.setValue.bind(this, "EquipmentTimeWeight")} />
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="EquipmentTimePts">EquipmentTimePts&nbsp;<span class="text-danger">{ this.state.EquipmentTimePtsValid !== true  ? "Invalid! Enter a number." : null }</span></label>
                                <input type = "text" class="form-control" id ="EquipmentTimePts"value ={this.state.EquipmentTimePts} onChange={this.setValue.bind(this, "EquipmentTimePts")} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-12">
                                <label for="CostingNonCompliantReason">CostingNonCompliantReason</label>
                                <textarea class="form-control" id="CostingNonCompliantReason" rows="3" value={this.state.CostingNonCompliantReason} onChange={this.setValue.bind(this, "CostingNonCompliantReason")}></textarea>
                            </div>
                        </div>
                        </form>

                        {
                                this.state.postStatus === "Posting" ?

                                <div class="alert alert-info" role="alert">
                                    Saving costing data...please wait
                                </div>

                                : this.state.postStatus === "Success" ?

                                <div class="alert alert-success" role="alert">
                                    Completed saving costing data.
                                </div>

                                : this.state.postStatus === "Failure" ?
                                
                                <div class="alert alert-danger" role="alert">
                                    Something went wrong while saving the costing data! Please try again later.
                                </div>

                                : null
                        }

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-sm btn-primary" onClick={this.onSave} disabled={!this.validateForm()}>Save</button>
                    </div>
                </div>
            </div>
        </div>

        );
    };
};

export default ModalCostingForm;