import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Header from "./Header";
import SustainabilityHome from "./SustainabilityHome";
import SustainabilityDataEntry from "./SustainabilityDataEntry";
import SustainabilityDataReview from "./SustainabilityDataReview";


const SustainabilityRouter = (props) => {
  return (
      <div>
        <Header user={props.user} />
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={SustainabilityHome} />
            <Route path="/sustainability" component={SustainabilityHome} />
            <Route path="/sustainabilitydataentry" component={SustainabilityDataEntry} />
            <Route path="/sustainabilitydatareview" component={SustainabilityDataReview} />
          </Switch>
        </BrowserRouter>
      </div>
  );
};

export default SustainabilityRouter;
