import React, { Component } from "react";
import BreadCrumb from "../components/BreadCrumb";
import DataReviewList from "../components/DataReviewList";

class SustainabilityDataReview extends Component {
 
    render() {
        
        return (
        
            <div class="container-fluid">

                <span>&nbsp;</span>

                <BreadCrumb 
                    home="sustainability"
                    current="Sustainability Data Review" />

              
                <DataReviewList
                    section="Sustainability" />

                <span>&nbsp;</span>

            </div>
        );
    }
}

export default SustainabilityDataReview;