import React, { Component } from "react";
import { Link } from "react-router-dom";
import { GoChecklist } from "react-icons/go";

class CardDataReview extends Component {

    render() {

        return (

            <div class="card border-white">
              <div class="card-body">
                <h6 class="card-title">
                    <GoChecklist />&nbsp;Manufacturing Index Data Review
                </h6>
                <div class="border-top my-2"></div>
                <p class="card-text">
                Review the collected manufacturing index data and correct the data if needed.
                </p>
                <button class="btn  btn-outline-primary btn-sm" role="button">
                  <Link to={this.props.link} style={{ textDecoration: "none"}}>{this.props.action}</Link>
                </button>
                <br />
              </div>
              
            </div>
            
        );
    };
};

export default CardDataReview;